import { useLocation } from "react-router-dom";
import { INCOMING_MOBILITY_FINISHED_DATES, INCOMING_MOBILITY_HISTORY_DATES, INCOMING_MOBILITY_STUDENTS, OUTGOING_MOBILITY_FINISHED_DATES, OUTGOING_MOBILITY_HISTORY_DATES, OUTGOING_MOBILITY_STUDENT_COURSES } from "../../../assets/js/shared";
import CustomLink from "../../../components/CustomLink";

const AdminSidebar = (props) => {

	const location = useLocation();

	return (
		<div className="card-body">
			<ul className="card-list list text-gray-700 mb-6">
				<li className="list-item">
					<h6 className="fw-bold text-uppercase mb-3">
						<CustomLink className="list-link fs-6 text-gray-700" to="">
							Home
						</CustomLink>
					</h6>
				</li>
			</ul>

			<h6 className="fw-bold text-uppercase mb-3">Person</h6>
			<ul className="card-list list text-gray-700 mb-0">
				<li className="list-item">
					<CustomLink className="list-link text-reset" to="personregistration">
						Pending registrations {props.counters && <span className="accent mx-2">({props.counters.registrationCount})</span>}
					</CustomLink>
				</li>
				<li className="list-item">
					<CustomLink className="list-link text-reset" to="usermanagement">
						User management
					</CustomLink>
				</li>
				{/* <li className="list-item">
					<CustomLink className="list-link text-reset" to="teachermanagement">
						Teacher management
					</CustomLink>
				</li> */}
			</ul>

			<h6 className="fw-bold text-uppercase mt-6">Students</h6>
			<ul className="card-list list text-gray-700 mb-6">
				<li className="list-item">
					<CustomLink className="list-link text-reset" to="studentcard">
						Cards
					</CustomLink>
				</li>
				<li className="list-item">
					<CustomLink className="list-link text-reset" to="outgoingmobilitypending" onClick={() => sessionStorage.removeItem(OUTGOING_MOBILITY_STUDENT_COURSES)}>
						Outgoing pending enrolment {props.counters && <span className="accent mx-2">({props.counters.outPendingCount})</span>}
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/outgoingmobilitypending/studentcourses" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="outgoingmobilitypending/studentcourses">
							Outgoing pending enrolment courses
						</CustomLink>
					</li>
				}
				<li className="list-item" onClick={() => sessionStorage.removeItem(OUTGOING_MOBILITY_STUDENT_COURSES)}>
					<CustomLink className="list-link text-reset" to="outgoingmobilityactive">
						Outgoing active
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/outgoingmobilityactive/studentcourses" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="outgoingmobilityactive/studentcourses">
							Outgoing active courses
						</CustomLink>
					</li>
				}
				<li className="list-item" onClick={() => { sessionStorage.removeItem(OUTGOING_MOBILITY_FINISHED_DATES); sessionStorage.removeItem(OUTGOING_MOBILITY_STUDENT_COURSES) }}>
					<CustomLink className="list-link text-reset" to="outgoingmobilityfinished">
						Outgoing finished
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/outgoingmobilityfinished/studentcourses" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="outgoingmobilityfinished/studentcourses">
							Outgoing finished courses
						</CustomLink>
					</li>
				}
				<li className="list-item" onClick={() => { sessionStorage.removeItem(OUTGOING_MOBILITY_HISTORY_DATES); sessionStorage.removeItem(OUTGOING_MOBILITY_STUDENT_COURSES) }}>
					<CustomLink className="list-link text-reset" to="outgoingmobilityhistory">
						Outgoing history
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/outgoingmobilityhistory/studentcourses" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="outgoingmobilityhistory/studentcourses">
							Outgoing history courses
						</CustomLink>
					</li>
				}
			</ul>

			<h6 className="fw-bold text-uppercase">Courses</h6>
			<ul className="card-list list text-gray-700 mb-6">
				<li className="list-item">
					<CustomLink className="list-link text-reset" to="courses">
						Course management
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/courses/courseInstances" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="courses/courseInstances">
							Course instance management
						</CustomLink>
					</li>
				}
				<li className="list-item" onClick={() => sessionStorage.removeItem(INCOMING_MOBILITY_STUDENTS)}>
					<CustomLink className="list-link text-reset" to="incomingmobilitypending">
						Incoming pending enrolment {props.counters && <span className="accent mx-2">({props.counters.inPendingCount})</span>}
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/incomingmobilitypending/coursestudents" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="incomingmobilitypending/coursestudents">
							Incoming pending enrolment students
						</CustomLink>
					</li>
				}
				<li className="list-item" onClick={() => sessionStorage.removeItem(INCOMING_MOBILITY_STUDENTS)}>
					<CustomLink className="list-link text-reset" to="incomingmobilityactive">
						Incoming active {props.counters && <span className="accent mx-2">({props.counters.inActiveCount})</span>}
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/incomingmobilityactive/coursestudents" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="incomingmobilityactive/coursestudents">
							Incoming active students
						</CustomLink>
					</li>
				}
				<li className="list-item" onClick={() => { sessionStorage.removeItem(INCOMING_MOBILITY_FINISHED_DATES); sessionStorage.removeItem(INCOMING_MOBILITY_STUDENTS) }}>
					<CustomLink className="list-link text-reset" to="incomingmobilityfinished">
						Incoming finished
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/incomingmobilityfinished/coursestudents" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="incomingmobilityfinished/coursestudents">
							Incoming finished students
						</CustomLink>
					</li>
				}
				<li className="list-item" onClick={() => { sessionStorage.removeItem(INCOMING_MOBILITY_HISTORY_DATES); sessionStorage.removeItem(INCOMING_MOBILITY_STUDENTS) }}>
					<CustomLink className="list-link text-reset" to="incomingmobilityhistory">
						Incoming history
					</CustomLink>
				</li>
				{
					location.pathname === "/administrator/incomingmobilityhistory/coursestudents" &&
					<li className="list-item">
						<CustomLink className="list-link text-reset" to="incomingmobilityhistory/coursestudents">
							Incoming history students
						</CustomLink>
					</li>
				}
			</ul>

			<h6 className="fw-bold text-uppercase mt-6">Joint programmes</h6>
			<ul className="card-list list text-gray-700 mb-6">
				<li className="list-item">
					<CustomLink className="list-link text-reset" to="adminjointprogrammes">
						My programmes (Administrating)
					</CustomLink>
				</li>
				<li className="list-item">
					<CustomLink className="list-link text-reset" to="partnerjointprogrammes">
						Partner programmes (Participating)
					</CustomLink>
				</li>
			</ul>

			<h6 className="fw-bold text-uppercase mt-6">Extra modules</h6>
			<ul className="card-list list text-gray-700 mb-6">
				<li className="list-item">
					<CustomLink className="list-link text-reset" to="extramodules">
						Module management
					</CustomLink>
				</li>
			</ul>
		</div>
	);
}

export default AdminSidebar;