import { useCallback, useEffect, useState } from "react"
import Error from "../../components/Error";
import { ADMIN_COURSE_INSTANCES_ACTIVE, ADMIN_COURSE_INSTANCES_FINISHED, COURSE_ID, COURSE_NAME, LOCAL_ID, aapi } from "../../assets/js/shared";
import { Modal, OverlayTrigger, Tab, Tabs, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CourseInstanceTable from "./components/CourseInstanceTable";
import { getQuery } from "../../assets/js/datatables";

const AdminCourseInstances = props => {

    const navigate = useNavigate();

    const [active, setActive] = useState(null);
    const [finished, setFinished] = useState(null);
    const [tableDetailsActive, setTableDetailsActive] = useState(null);
    const [tableDetailsFinished, setTableDetailsFinished] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // session storage - data from AdminCourse component
    const courseId = sessionStorage.getItem(COURSE_ID);
    const localId = sessionStorage.getItem(LOCAL_ID);
    const courseName = sessionStorage.getItem(COURSE_NAME);

    // get initial data
    const getDataActive = useCallback((page, query) => {
        aapi.get(`/getcourseinstances/${courseId}/Y/${page}${query}`)
            .then(res => {
                setActive(res.data.data);
                setTableDetailsActive(res.data.paginationDetails);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [courseId]);

    const getDataFinished = useCallback((page, query) => {
        aapi.get(`/getcourseinstances/${courseId}/N/${page}${query}`)
            .then(res => {
                setFinished(res.data.data);
                setTableDetailsFinished(res.data.paginationDetails);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [courseId]);

    useEffect(() => {
        sessionStorage.removeItem(ADMIN_COURSE_INSTANCES_ACTIVE);
        sessionStorage.removeItem(ADMIN_COURSE_INSTANCES_FINISHED);

        if (courseId === null) {
            navigate("/administrator/courses");
        } else {
            getDataActive(1, "");
            getDataFinished(1, "");
        }
    }, [getDataActive, getDataFinished, courseId, navigate]);


    // add new instance modal hooks
    const [showAddModal, setShowAddModal] = useState(false);
    const [addModalError, setAddModalError] = useState(null);

    // new course instance data (POST)
    const [sendNewData, setSendNewData] = useState({
        orgUnitId: props.orgUnitId,
        courseId: courseId
    })

    // add new course instance (POST)
    const handleSubmit = (e) => {
        e.preventDefault();

        aapi.post("/pushcourseinstancedata", sendNewData, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                return JSON.stringify(data);
            }]
        })
            .then(res => {
                closeAddModal();
                getDataActive(tableDetailsActive.currentPage, getQuery(ADMIN_COURSE_INSTANCES_ACTIVE));
            })
            .catch(err => {
                setAddModalError(err);
            })
    }

    // close add new course instance modal
    const closeAddModal = () => {
        setShowAddModal(false);
        setAddModalError(null);
        setSendNewData({
            orgUnitId: props.orgUnitId,
            courseId: courseId
        });
    }

    // min course instance start date control for add course instance
    const minStartDate = () => {
        let date = new Date(Date.now());
        return new Date(date.setDate(date.getDate() + 1)).toISOString().split("T")[0];
    }

    //min course instance end date control for add course instance
    const minEndDate = () => {
        if (sendNewData.startDate) {
            let date = new Date(sendNewData.startDate);
            return new Date(date.setDate(date.getDate() + 1)).toISOString().split("T")[0];
        } else {
            let date = new Date(Date.now());
            return new Date(date.setDate(date.getDate() + 2)).toISOString().split("T")[0];
        }
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <div className="row">
                    <div className="col-1">
                        <h4 className="accent">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-back">Go back</Tooltip>
                                }
                            >
                                <button className="btn accent p-0" onClick={() => navigate(-1)}><i className="fe fe-chevron-left fw-bold"></i></button>
                            </OverlayTrigger>
                        </h4>
                    </div>
                    <div className="col-11">
                        <h4 className="accent">{courseName}
                            {
                                localId !== "null" &&
                                <> ({localId})</>
                            }
                        </h4>
                        <h4><b>Course instances</b></h4>
                    </div>
                </div>
            </div>
            <div className="card-body">
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                <div className="row mb-6">
                    <div className="col-12 col-md-auto">
                        <button className="btn w-100 btn-primary" onClick={() => setShowAddModal(true)}>New instance</button>
                    </div>
                </div>
                <Tabs defaultActiveKey="activeInstances" id="activeInstances" className="mb-3">
                    <Tab eventKey="activeInstances" title="Active instances">
                        <div className="row">
                            <div className="col">
                                {
                                    active &&
                                    <CourseInstanceTable data={active} getData={getDataActive} history={false} courseName={courseName} localId={localId} tableDetails={tableDetailsActive} setIsLoading={setLoading} />
                                }
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="instancesHistory" title="Instances history">
                        <div className="row">
                            <div className="col">
                                {
                                    finished &&
                                    <CourseInstanceTable data={finished} getData={getDataFinished} history={true} tableDetails={tableDetailsFinished} setIsLoading={setLoading} />
                                }
                            </div>
                        </div>
                    </Tab>
                </Tabs>

                <Modal
                    size="lg"
                    show={showAddModal}
                    onHide={closeAddModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="row mb-3">
                                <div className="col-12">
                                    <h4><span className="accent">{courseName}
                                        {
                                            localId !== "null" &&
                                            <> ({localId})</>
                                        }
                                    </span></h4>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h4>Add new course instance</h4>
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            addModalError &&
                            <Error text={addModalError}></Error>
                        }
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <p className="mt-0">* - required field</p>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Course instance number</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            min={1}
                                            onChange={e => setSendNewData({ ...sendNewData, courseInstanceNo: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Academic year*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            pattern="[0-9]{4}/[0-9]{4}"
                                            placeholder="yyyy/yyyy"
                                            title="yyyy/yyyy"
                                            required
                                            onChange={e => setSendNewData({ ...sendNewData, academicYear: e.target.value.split("/")[0] })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row d-flex">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>Start date*</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            required
                                            onChange={e => setSendNewData({ ...sendNewData, startDate: e.target.value })}
                                            min={minStartDate()}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label>End date*</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            required
                                            onChange={e => setSendNewData({ ...sendNewData, endDate: e.target.value })}
                                            min={minEndDate()}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Quota</label>
                                        <input
                                            className="form-control"
                                            type="number"
                                            min={1}
                                            onChange={e => setSendNewData({ ...sendNewData, studentQuota: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Teachers (use <span className="fw-bolder">;</span> as a separator)</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Semicolon separated list of teachers"
                                            onChange={e => setSendNewData({ ...sendNewData, teachers: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-6">
                                <div className="col-12 col-md-auto d-flex">
                                    <button className="btn w-100 btn-primary" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default AdminCourseInstances;