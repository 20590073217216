import { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Error from "../../components/Error";
import { RESEARCHER_CONNECT_DETAILS, aapi, keywordType } from "../../assets/js/shared";
import { Chart as ChartJS, ArcElement, Legend, RadialLinearScale, Tooltip as ChartTooltip, PointElement, LineElement, Filler } from "chart.js";
import { PolarArea, Radar } from "react-chartjs-2";
import MatchPercentageView from "./components/MatchPercentageView";
import KeywordsComparison from "./components/KeywordsComparison";

const ResearcherConnectDetails = () => {

    ChartJS.register(RadialLinearScale, ArcElement, RadialLinearScale, PointElement, LineElement, Filler, ChartTooltip, Legend);

    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [userInterests, setUserInterests] = useState(null);
    const [chartData, setChartData] = useState(null);
    const [radarData, setRadarData] = useState(null);
    const [urls, setUrls] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const getData = useCallback(() => {
        if (sessionStorage.getItem(RESEARCHER_CONNECT_DETAILS) === null) {
            navigate("/researcher/connect");
        }
        Promise.all([
            aapi.get(`/getresearcherconnectdetails/${sessionStorage.getItem(RESEARCHER_CONNECT_DETAILS)}`),
            aapi.get(`/getpersonalinterests`)
        ])
            .then(res => {
                setData(res[0].data);
                if (res[0].data.urls !== null) {
                    setUrls(res[0].data.urls.split("; "));
                }
                setChartData(
                    {
                        labels: ["Thematic lines (%)", "Modes of cooperation (%)", "Disciplines (%)", "Fields of research (%)", "Fields of teaching (%)"],
                        datasets: [
                            {
                                data: [
                                    res[0].data.thematicLineMatch,
                                    res[0].data.cooperationMatch,
                                    res[0].data.disciplineMatch,
                                    res[0].data.fieldOfResearchMatch,
                                    res[0].data.teachingMatch
                                ],
                                backgroundColor: [
                                    'rgba(255, 99, 132, 0.5)',
                                    'rgba(54, 162, 235, 0.5)',
                                    'rgba(255, 206, 86, 0.5)',
                                    'rgba(75, 192, 192, 0.5)',
                                    'rgba(153, 102, 255, 0.5)'
                                ]
                            }
                        ]
                    }
                );
                setUserInterests(res[1].data);
                setRadarData(
                    {
                        labels: ['Thematic lines', 'Modes of cooperation', 'Disciplines', 'Fields of research', 'Fields of teaching'],
                        datasets: [
                            {
                                label: '# of my keywords',
                                data: [
                                    res[1].data.thematicLines.length,
                                    res[1].data.cooperationKeywords.length,
                                    res[1].data.disciplines.length,
                                    res[1].data.fieldsOfResearch.length,
                                    res[1].data.teachingKeywords.length
                                ],
                                backgroundColor: 'rgba(245, 161, 5, 0.2)',
                                borderColor: 'rgba(245, 161, 5)',
                                borderWidth: 1,
                            },
                            {
                                label: "# of co-researcher's keywords",
                                data: [
                                    res[0].data.thematicLines.length,
                                    res[0].data.cooperationKeywords.length,
                                    res[0].data.disciplines.length,
                                    res[0].data.fieldsOfResearch.length,
                                    res[0].data.teachingKeywords.length
                                ],
                                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                                borderColor: 'rgba(54, 162, 235)',
                                borderWidth: 1,
                            },
                            {
                                label: "# of matching keywords",
                                data: [
                                    countMatchingKeywords(res[0].data.thematicLines, res[1].data.thematicLines),
                                    countMatchingKeywords(res[0].data.cooperationKeywords, res[1].data.cooperationKeywords),
                                    countMatchingKeywords(res[0].data.disciplines, res[1].data.disciplines),
                                    countMatchingKeywords(res[0].data.fieldsOfResearch, res[1].data.fieldsOfResearch),
                                    countMatchingKeywords(res[0].data.teachingKeywords, res[1].data.teachingKeywords)
                                ],
                                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                                borderColor: 'rgba(255, 99, 132)',
                                borderWidth: 1,
                            }
                        ],
                    }
                );
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [navigate]);

    useEffect(() => {
        getData();
    }, [getData]);

    const countMatchingKeywords = (keywordsList1, keywordList2) => {
        let count = 0;
        keywordsList1.forEach(k1 => {
            keywordList2.forEach(k2 => {
                if (k1 === k2.keywordName) {
                    count++;
                }
            })
        })
        return count;
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <div className="row">
                    <div className="col-1">
                        <h4 className="accent">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-back">Go back</Tooltip>
                                }
                            >
                                <button className="btn accent p-0" onClick={() => navigate(-1)}><i className="fe fe-chevron-left fw-bold"></i></button>
                            </OverlayTrigger>
                        </h4>
                    </div>
                    <div className="col-11">
                        <h4 className="accent">Co-researcher details</h4>
                    </div>
                </div>
            </div>
            <div className="card-body">
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                {
                    data &&
                    <>
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="text-primary fw-bold">ORCiD INFORMATION</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="fw-bolder">Name:</p>
                                <p>{data.personalNames} {data.familyNames}</p>
                            </div>
                            <div className="col-lg-6">
                                <p className="fw-bolder">Email:</p>
                                <a href={`mailto:${data.email}`}> {data.email} </a>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="fw-bolder">ORCiD:</p>
                                <p>{data.orcid}</p>
                            </div>
                            <div className="col-lg-6">
                                <p className="fw-bolder">ORCiD profile:</p>
                                <span><a href={`https://orcid.org/${data.orcid}`} target="_blank" rel="noreferrer" className="btn btn-success btn-sm">ORCiD profile <i className="fe fe-external-link"></i></a></span>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                <p className="fw-bolder">Biography:</p>
                                {
                                    data.biography &&
                                    <p>{data.biography}</p>
                                }
                                {
                                    !data.biography &&
                                    <p className="fst-italic text-black-50">Not available</p>
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="fw-bolder">Websites & social links:</p>
                                {
                                    urls && urls.map(url => {
                                        return <><a href={url} target="_blank" rel="noreferrer"> {url} <i className="fe fe-external-link"></i></a><br /></>
                                    })
                                }
                                {
                                    !urls &&
                                    <p className="fst-italic text-black-50">Not available</p>
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12">
                                <p className="fw-bolder">ORCiD Keywords:</p>
                                {
                                    data.orcidKeywords &&
                                    <p>{data.orcidKeywords}</p>
                                }
                                {
                                    !data.orcidKeywords &&
                                    <p className="fst-italic text-black-50">Not available</p>
                                }
                            </div>
                        </div>
                        <hr className="text-primary" />
                        <div className="row my-5">
                            <div className="col-sm-12 py-3">
                                <p className="text-primary fw-bold m-0">KEYWORD COMPARISON</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                {
                                    chartData &&
                                    <PolarArea data={chartData} style={{ height: "500px" }} options={{ maintainAspectRatio: false }} />
                                }
                            </div>
                        </div>
                        <hr />
                        <KeywordsComparison personInterests={data.thematicLines} userInterests={userInterests.thematicLines} match={data.thematicLineMatch} type={keywordType.thematicLines} />
                        <hr />
                        <KeywordsComparison personInterests={data.cooperationKeywords} userInterests={userInterests.cooperationKeywords} match={data.cooperationMatch} type={keywordType.cooperationKeywords} />
                        <hr />
                        <KeywordsComparison personInterests={data.disciplines} userInterests={userInterests.disciplines} match={data.disciplineMatch} type={keywordType.disciplines} />
                        <hr />
                        <KeywordsComparison personInterests={data.fieldsOfResearch} userInterests={userInterests.fieldsOfResearch} match={data.fieldOfResearchMatch} type={keywordType.fieldsOfResearch} />
                        <hr />
                        <KeywordsComparison personInterests={data.teachingKeywords} userInterests={userInterests.teachingKeywords} match={data.teachingMatch} type={keywordType.teachingKeywords} />
                        <hr />
                        <div className="row">
                            <div className="col-sm-10 my-auto">
                                <p className="fw-bolder m-0">Total match:</p>
                            </div>
                            <div className="col-sm-2 text-center my-auto">
                                <MatchPercentageView data={data.totalMatch} />
                            </div>
                        </div>
                        <hr className="text-primary" />
                        <div className="row my-5">
                            <div className="col-sm-12 py-3">
                                <p className="text-primary fw-bold m-0">KEYWORD CATEGORY COMPARISON</p>
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-sm-12">
                                {
                                    radarData &&
                                    <Radar data={radarData} style={{ height: "500px" }} options={{ maintainAspectRatio: false }} />
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default ResearcherConnectDetails;