import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { Alert, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { USER_MANAGEMENT_STAFF, USER_MANAGEMENT_STUDENTS, aapi, getDate } from "../../../assets/js/shared";
import { muiCache, customTheme, paginationOptions, getQuery } from "../../../assets/js/datatables";
import Error from "../../../components/Error";
import { genderFO } from "../../../assets/js/filterOptions";

const PersonTable = ({ staff, data, getData, tableDetails, setIsLoading }) => {

    const [dateOfBirth, setDateOfBirth] = useState(null);

    // columns for person table
    const columns = [
        {
            name: "titleBeforeName",
            label: "Title before name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "personalNames",
            label: "First name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "familyNames",
            label: "Last name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "titleAfterName",
            label: "Title after name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "eMail",
            label: "eMail",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (eMail) => {
                    return (
                        <a href={`mailto:${eMail}`}> {eMail} </a>
                    )
                }
            }
        },
        {
            name: "esi",
            label: "ESI",
            options: {
                filter: staff ? false : true,
                sort: true,
                display: staff ? "excluded" : true
            }
        },
        {
            name: "birthDate",
            label: "Date of birth",
            options: {
                filter: true,
                filterType: "custom",
                sort: true,
                customBodyRender: (birthDate) => {
                    return (
                        <span>{getDate(birthDate)}</span>
                    )
                },
                customFilterListOptions: {
                    update: (filterList, filterPos, index) => {
                        setDateOfBirth("");
                        getData(tableDetails.currentPage, getQuery(staff ? USER_MANAGEMENT_STAFF : USER_MANAGEMENT_STUDENTS));
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    }
                },
                filterOptions: {
                    display: (filterList, onChange, index, column) => {
                        return (
                            <>
                                <label className="date-filter-label">Date of birth</label>
                                <input
                                    type="date"
                                    className="date-filter-input"
                                    value={dateOfBirth}
                                    required
                                    onChange={e => {
                                        filterList[index] = [e.target.value];
                                        onChange(filterList[index], index, column);
                                        setDateOfBirth(e.target.value);
                                    }}
                                />
                            </>
                        );
                    }
                }
            }
        },
        {
            name: "gender",
            label: "Gender",
            options: {
                filter: true,
                filterType: "dropdown",
                filterOptions: { names: genderFO },
                sort: true,
                customBodyRender: (gender) => {
                    if (gender === "M") {
                        return <span>Male</span>
                    } else if (gender === "F") {
                        return <span>Female</span>
                    } else {
                        return <span>Other</span>
                    }
                }
            }
        },
        {
            name: "roleName",
            label: "Role",
            options: {
                filter: true,
                sort: true,
                display: staff ? true : "excluded"
            }
        },
        {
            name: "personId",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (personId, tableMeta) => {
                    if (tableMeta.rowData[10] === "Y") {
                        return (
                            <div className="btn-group">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-revoke">Deactivate profile</Tooltip>
                                    }
                                >
                                    <button className="btn text-danger" onClick={() => deactivate(personId)}>
                                        <i className="fe fe-user-x"></i>
                                    </button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-edit">Edit</Tooltip>
                                    }
                                >
                                    <button className="btn text-info" onClick={() => handleShow(tableMeta)}>
                                        <i className="fe fe-edit"></i>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        )
                    } else {
                        return (
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-activate">Activate profile</Tooltip>
                                }
                            >
                                <button className="btn text-success" onClick={() => activate(personId)}>
                                    <i className="fe fe-user-plus"></i>
                                </button>
                            </OverlayTrigger>
                        )
                    }
                }
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        }
    ];

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    // activate account for user with given id
    const activate = (id) => {
        setSuccess(null);
        setError(null);

        aapi.put(`/activateaccount/${id}`, {}, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                return JSON.stringify(data);
            }]
        })
            .then(res => {
                getData(tableDetails.currentPage, getQuery(staff ? USER_MANAGEMENT_STAFF : USER_MANAGEMENT_STUDENTS));
                setSuccess("User account successfully activated");
            })
            .catch(err => {
                setError(err);
            })
    }

    // deactivate account for user with given id
    const deactivate = (id) => {
        setSuccess(null);
        setError(null);

        aapi.put(`/deactivateaccount/${id}`, {}, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                return JSON.stringify(data);
            }]
        })
            .then(res => {
                getData(tableDetails.currentPage, getQuery(staff ? USER_MANAGEMENT_STAFF : USER_MANAGEMENT_STUDENTS));
                setSuccess("User account successfully deactivated");
            })
            .catch(err => {
                setError(err);
            })
    }

    // edit person modal hooks
    const [person, setPerson] = useState(null);
    const [currentPerson, setCurrentPerson] = useState(null);
    const [show, setShow] = useState(false);
    const [modalError, setModalError] = useState(null);

    // show edit person modal
    const handleShow = (tableMeta) => {
        setPerson({
            titleBeforeName: tableMeta.rowData[0],
            personalNames: tableMeta.rowData[1],
            familyNames: tableMeta.rowData[2],
            titleAfterName: tableMeta.rowData[3],
            eMail: tableMeta.rowData[4],
            birthDate: tableMeta.rowData[6].split("T")[0],
            gender: tableMeta.rowData[7]
        });
        setCurrentPerson({
            id: tableMeta.rowData[9],
            personalNames: tableMeta.rowData[1],
            familyNames: tableMeta.rowData[2]
        });
        setShow(true);
    }

    // close edit person modal
    const handleClose = () => {
        setModalError(null);
        setPerson(null);
        setCurrentPerson(null);
        setShow(false);
    }

    // edit person (PUT)
    const handleSubmit = (e) => {
        e.preventDefault();
        setSuccess(null);
        setError(null);

        aapi.put(`/editpersondetails/${currentPerson.id}`, person, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                return JSON.stringify(data);
            }]
        })
            .then(res => {
                handleClose();
                getData(tableDetails.currentPage, getQuery(staff ? USER_MANAGEMENT_STAFF : USER_MANAGEMENT_STUDENTS));
                setSuccess("User details successfully updated");
            })
            .catch(err => {
                setModalError(err);
            })
    }

    return (
        <>
            {
                error &&
                <Error text={error}></Error>
            }
            {
                success &&
                <Alert variant="success-soft" className="mt-3 p-3" onClose={() => setSuccess()} dismissible>{success}</Alert>
            }
            {
                data &&
                <CacheProvider value={muiCache}>
                    <ThemeProvider theme={customTheme}>
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={paginationOptions(tableDetails, getData, setIsLoading, staff ? USER_MANAGEMENT_STAFF : USER_MANAGEMENT_STUDENTS)}
                        />
                    </ThemeProvider>
                </CacheProvider>
            }

            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    {
                        currentPerson &&
                        <Modal.Title>Edit person information <span className="accent">{currentPerson.personalNames} {currentPerson.familyNames}</span></Modal.Title>
                    }
                </Modal.Header>
                <Modal.Body>
                    {
                        modalError &&
                        <Error text={modalError}></Error>
                    }
                    {
                        person &&
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <p className="mt-0">* - required field</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">First name*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            defaultValue={person.personalNames}
                                            onChange={(e) => setPerson({ ...person, personalNames: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Last name*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            defaultValue={person.familyNames}
                                            onChange={(e) => setPerson({ ...person, familyNames: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Title before name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={person.titleBeforeName}
                                            onChange={(e) => setPerson({ ...person, titleBeforeName: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Title after name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={person.titleAfterName}
                                            onChange={(e) => setPerson({ ...person, titleAfterName: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Date of birth*</label>
                                        <input
                                            className="form-control"
                                            type="date"
                                            required
                                            defaultValue={person.birthDate}
                                            onChange={(e) => setPerson({ ...person, birthDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Gender*</label>
                                        <select
                                            className="form-select"
                                            required
                                            onChange={(e) => setPerson({ ...person, gender: e.target.value })}
                                            defaultValue={person.gender}
                                        >
                                            <option value="F">Female</option>
                                            <option value="M">Male</option>
                                            <option value="O">Other</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Email*</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    required
                                    defaultValue={person.eMail}
                                    onChange={(e) => setPerson({ ...person, eMail: e.target.value })}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-auto d-flex">
                                    <button className="btn w-100 btn-primary" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PersonTable;