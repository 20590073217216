import useFetch from "../assets/js/useFetch";


const Footer = () => {
  const {data} = useFetch('/public/getappversion');

  return (
  <footer className="py-8 py-md-11 bg-white">
    <div className="container">
      <div className="row justify-content-center">
      <div className="row text-muted">
        <div className="col-12 text-center">
          <ul className="list-inline">
            <li className="list-inline-item mb-3">
              <a href="https://www.srce.unizg.hr/en" target="_blank" rel="noreferrer">
                <img src={"https://www.srce.unizg.hr/logo/Logo-Srce-horizontal-ENG.png"} alt="University Computing Centre Logo"
                  className="footer-brand img-fluid mb-4 logo-srce" />  
              </a>
            </li>
            <li>
              <a href="/yufevc/public/accessibility-statement" target="_blank">Accessibility statement</a>
            </li>
            <li>
              <a className="text-muted mt-1" href="https://www.srce.unizg.hr/en/" target="_blank" rel="noreferrer">Software support (c) and maintenance :: University of Zagreb University Computing Centre</a>
            </li>
            {data &&
              <li>
                <span className="text-muted mt-1">Version: {data["appversion"]}</span>
              </li>
            }
          </ul>
        </div>
      </div>
    </div>
    </div>
  </footer>
    
  );
}
 
export default Footer;