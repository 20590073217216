import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import AdminSidebar from "./AdminSidebar";
import { useState } from "react";

const AdminNavbar = props => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Navbar className="d-md-none mt-4 student-navbar" bg="admin" expand="lg" variant="light" expanded={expanded}>
      <Container fluid="md">
        <div className="row align-items-center">
          <div className="col">
            <div className="text-white p-2">
              <div className="user__name fw-bold text-uppercase">
                {props.userName}
              </div>
              <div className="user__email">
                {props.userOrgUnit}
              </div>  
            </div>
          </div>

          <div className="col-auto">
            <div className="navbar-dark">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" label="Toggle navigation" onClick={() => setExpanded(expanded ? false : true)}/>
            </div>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col">
            <Navbar.Collapse id="responsive-navbar-nav" className="d-md-block student-navbar">
              <AdminSidebar />
            </Navbar.Collapse>
        
          </div>
        </div>
      </Container>
    </Navbar>
  );
}
 
export default AdminNavbar;