import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { muiCache, customTheme, paginationOptions, getQuery } from "../../assets/js/datatables";
import { useState, useEffect, useCallback } from "react";
import { STUDENT_CARDS, aapi, getDate } from "../../assets/js/shared";
import Error from "../../components/Error";
import { Alert } from "react-bootstrap";

const StudentCard = () => {

	const [generatedDate, setGeneratedDate] = useState(null);
	const [deactivatedDate, setDeactivatedDate] = useState(null);

	// columns for person table
	const columns = [
		{
			name: "esi",
			label: "ESI",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "personalNames",
			label: "First name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "familyNames",
			label: "Last name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "generatedDate",
			label: "Generated",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (date) => {
					return (
						<span> {getDate(date)} </span>
					)
				},
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setGeneratedDate("");
						getData(tableDetails.currentPage, getQuery());
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">Generated date</label>
								<input
									type="date"
									className="date-filter-input"
									value={generatedDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setGeneratedDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
			}
		},
		{
			name: "deactivatedDate",
			label: "Deactivated",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (date) => {
					return (
						<span> {getDate(date)} </span>
					)
				},
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setDeactivatedDate("");
						getData(tableDetails.currentPage, getQuery());
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">Deactivated date</label>
								<input
									type="date"
									className="date-filter-input"
									value={deactivatedDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setDeactivatedDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
			}
		},
		{
			name: "personId",
			label: "Action",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (personId, tableMeta) => {
					if (tableMeta.rowData[4]) {
						return (
							<form onSubmit={handleActivate}>
								<input name="studentId" hidden value={personId} readOnly></input>
								<button className="btn btn-outline-warning" type="submit">Activate</button>
							</form>
						);
					} else {
						return (
							<form onSubmit={handleDeactivate}>
								<input name="studentId" hidden value={personId} readOnly></input>
								<button className="btn btn-outline-warning" type="submit">Deactivate</button>
							</form>
						);
					}
				}
			}
		},
		{
			name: "orgUnitName",
			label: "Organization name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "superOrgUnitName",
			label: "University name",
			options: {
				filter: true,
				sort: true,
			}
		},
	];

	const [data, setData] = useState(null);
	const [tableDetails, setTableDetails] = useState(null);

	const [isLoading, setIsLoading] = useState(false);
	const [success, setSuccess] = useState(null);
	const [error, setError] = useState(null);

	// get initial data
	const getData = useCallback(async (page, query) => {
		setIsLoading(true);

		await aapi.get(`/getunistudentcards/${page}${query}`)
			.then(res => {
				setData(res.data.data);
				setTableDetails(res.data.paginationDetails);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}, []);

	useEffect(() => {
		sessionStorage.removeItem(STUDENT_CARDS);
		getData(1, "");
	}, [getData])

	const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

	// activate student card (PUT)
	const handleActivate = async (e) => {
		e.preventDefault();
		setSuccess(null);
		setError(null);

		aapi.put(`/activatestudentcard/${e.target.querySelector("input[name='studentId']").value}`, {},
			{
				transformRequest: [function (data, headers) {
					headers['X-CSRF-TOKEN'] = csrfToken;

					return JSON.stringify(data);
				}]
			})
			.then(_res => {
				getData(tableDetails.currentPage, getQuery(STUDENT_CARDS));
				setSuccess("Student card successfully activated");
			})
			.catch(err => {
				setError(err);
			});
	}

	// deactivate student card (PUT)
	const handleDeactivate = async (e) => {
		e.preventDefault();
		setSuccess(null);
		setError(null);

		aapi.put(`/deactivatestudentcard/${e.target.querySelector("input[name='studentId']").value}`,
			{
				transformRequest: [function (data, headers) {
					headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

					return JSON.stringify(data);
				}]
			})
			.then(_res => {
				getData(tableDetails.currentPage, getQuery(STUDENT_CARDS));
				setSuccess("Student card successfully deactivated");
			})
			.catch(err => {
				setError(err);
			});
	}


	return (
		<div className="card card-bleed shadow-light-lg mb-6">
			<div className="card-header">
				<h4 className="accent"> Student cards </h4>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col">
						<CacheProvider value={muiCache}>
							<ThemeProvider theme={customTheme}>
								{
									error &&
									<Error text={error}></Error>
								}
								{
									success &&
									<Alert variant="success-soft" className="mt-3 p-3" onClose={() => setSuccess()} dismissible>{success}</Alert>
								}
								{
									isLoading &&
									<div className="spinner-border text-primary mb-1" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								}
								{
									data &&
									<MUIDataTable
										data={data}
										columns={columns}
										options={paginationOptions(tableDetails, getData, setIsLoading, STUDENT_CARDS)}
									/>
								}

							</ThemeProvider>
						</CacheProvider>
					</div>
				</div>
			</div>
		</div>
	)
}

export default StudentCard;