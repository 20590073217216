import Alert from 'react-bootstrap/Alert';
import { useEffect, useState } from 'react';

const Error = ({text, statusCode, statusText}) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    setShow(true)
  }, [text])

  const getError = () => {
    if ((text.response.data === '' || text.response.data === undefined)) {
      if(statusText === '' || statusText === undefined) {
        return text.message;
      } else {
        return statusText;
      }
      // if (statusCode === 400) {
      //   return 'Kriva adresa zahtjeva!';
      // } else if (statusCode === 401) {
      //   return 'Potrebna autorizacija, prijavite se u sustav!';
      // } else if (statusCode === 403) {
      //   return 'Pristup zabranjen!';
      // } else if (statusCode === 404) {
      //   return 'Datoteka ne postoji!';
      // } else if (statusCode === 408) {
      //   return 'Server preopterećen, pokušajte za par minuta!';
      // } else if (statusCode === 500) {
      //   return 'Greška na serveru, kontaktirajte administratora!';
      // } else if (statusCode === 503) {
      //   return 'Servis nedostupan!';
      // } else if (statusCode === 'parserError') {
      //   return 'Neuspješno dohvaćanje JSON podataka!';
      // } else if (statusCode === 'timeout') {
      //   return 'Isteklo vrijeme zahtjeva!';
      // } else if (statusCode === 'abort') {
      //   return 'Zahtjev otkazan!';
      // }
    } else {
      return text.response.data;
    }
  }

  if (show){
    return (
      <Alert variant="danger-soft" onClose={() => setShow(false)} dismissible>
        <div className="alert-icon">
              <i className="fe fe-alert-triangle"></i>
          </div>
          <div className="alert-message">
            Following error occurred: <span>{getError()}</span>
          </div>
      </Alert>
    );
  }
}
 
export default Error;