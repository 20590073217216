import { Routes, Route } from "react-router-dom";
import { getNameWithTitle } from "../../assets/js/shared";
import StudentHeader from "./components/StudentHeader";
import StudentNavbar from "./components/StudentNavbar";
import StudentSidebar from "./components/StudentSidebar";
import StudentCard from "./StudentCard";
import StudentHome from "./StudentHome";
import ActiveCourses from "./ActiveCourses";
import EnrolCourses from "./EnrolCourses";
import PendingEnrolment from "./PendingEnrolment";
import CourseHistory from "./CourseHistory";
import ErrorPage from "../ErrorPage";
import useFetch from "../../assets/js/useFetch";
import StudentExtraModules from "./StudentExtraModules";

const Student = () => {
	const { data: user, error } = useFetch('/public/getcurrentuser');

	return (
		<>
			{
				error &&
				<ErrorPage></ErrorPage>
			}
			{
				user &&
				<>
					<StudentNavbar userName={getNameWithTitle(user)} userOrgUnit={user.orgUnitName} />
					<StudentHeader userName={getNameWithTitle(user)} userOrgUnit={user.orgUnitName} />

					<main className="pb-8 pb-md-11 mt-md-n6">
						<div className="container-fluid">
							<div className="row">
								<div className="col-12 col-md-3">

									<div className="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
										<div className="collapse d-md-block" id="sidenavCollapse">
											<StudentSidebar></StudentSidebar>
										</div>
									</div>

								</div>
								<div className="col-12 col-md-9 ps-md-0 pe-md-4">

									<Routes>
										<Route path="/" element={<StudentHome />} />
										<Route path="studentcard" element={<StudentCard />} />
										<Route path="enrolcourses" element={<EnrolCourses />} />
										<Route path="activecourses" element={<ActiveCourses />} />
										<Route path="pendingenrolment" element={<PendingEnrolment />} />
										<Route path="coursehistory" element={<CourseHistory />} />
										<Route path="extramodules" element={<StudentExtraModules />} />
									</Routes>

								</div>
							</div>
						</div>
					</main>
				</>
			}
		</>
	);
}

export default Student;
