import { NavLink } from "react-router-dom";
import logo from '../assets/img/logo-yufe-web.svg';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import { useState } from "react";
import packageJson from '../../package.json';
import useFetch from "../assets/js/useFetch";
import { UNICUserConsts } from "../assets/js/shared";

const PublicNavbar = props => {
	const [expanded, setExpanded] = useState(false);
	const hrefLogin = "/" + packageJson.homepage + "/saml2/login";
	const hrefLogout = "/" + packageJson.homepage + "/logout";
	const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

	const { data } = useFetch('/public/getcurrentuser');

	return (
		<Navbar className="fixed-top border-bottom" bg="blue" expand="lg" variant="dark" expanded={expanded}>
			<Container fluid>
				<a href="https://yufe.eu/" target="_blank" rel="noreferrer">
					<img
						src={logo}
						className="navbar-brand-img"
						alt="Yufe"
						style={{ maxHeight: "80px" }}
					/>
				</a>

				<Navbar.Toggle aria-controls="navbarCollapse" label="Toggle navigation" onClick={() => setExpanded(expanded ? false : true)} />
				<Navbar.Collapse id="navbarCollapse">
					<button className="navbar-toggler" type="button" onClick={() => setExpanded(false)}>
						<i className="fe fe-x"></i>
					</button>
					<Nav
						className="ms-auto"
					>
						<Nav.Item>
							<NavLink onClick={() => setExpanded(false)} className="nav-link" to="/">Home</NavLink>
						</Nav.Item>
						<Nav.Item>
							<NavLink onClick={() => setExpanded(false)} className="nav-link" to="/public/courses">Courses</NavLink>
						</Nav.Item>
						<Nav.Item>
							<NavLink onClick={() => setExpanded(false)} className="nav-link" to="/public/jointprogrammes">Joint Programmes</NavLink>
						</Nav.Item>
						<Nav.Item>
							<NavLink onClick={() => setExpanded(false)} className="nav-link" to="/public/extramodules">Extra modules</NavLink>
						</Nav.Item>
						<Nav.Item>
							<NavLink onClick={() => setExpanded(false)} className="nav-link" to="/public/researchoutputs">Research outputs</NavLink>
						</Nav.Item>
						{
							data && data.active === "Y" && data.roleId === UNICUserConsts.roleStudent &&
							<Nav.Item>
								<NavLink onClick={() => setExpanded(false)} className="nav-link" to="/student">Student</NavLink>
							</Nav.Item>
						}
						{
							data && data.active === "Y" && data.roleId > 0 && data.roleId !== UNICUserConsts.roleStudent && data.roleId !== UNICUserConsts.roleResearcher &&
							<Nav.Item>
								<NavLink onClick={() => setExpanded(false)} className="nav-link" to="/administrator">Administrator</NavLink>
							</Nav.Item>
						}
						{
							data && data.active === "Y" && data.roleId > 0 && data.roleId === UNICUserConsts.roleResearcher &&
							<Nav.Item>
								<NavLink onClick={() => setExpanded(false)} className="nav-link" to="/researcher">Researcher</NavLink>
							</Nav.Item>
						}
						<Nav.Item>
							<a onClick={() => setExpanded(false)} className="nav-link" href="https://wiki.srce.hr/display/UNICVC/UNIC_Virtual+Campus" target="_blank" rel="noreferrer">Help</a>
						</Nav.Item>
					</Nav>
					{
						!data &&
						<a href={hrefLogin} className="navbar-btn btn btn-sm btn-info lift ms-auto">
							Sign in
						</a>
					}
					{
						data &&
						<form className="ms-auto" action={hrefLogout} method="POST">
							<input type="hidden" name="_csrf" value={csrfToken} />
							<button className="navbar-btn btn btn-sm btn-info lift" type="submit">
								Sign out
							</button>
						</form>
					}
				</Navbar.Collapse>
			</Container>
		</Navbar>
	);
}

export default PublicNavbar;