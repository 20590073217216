import { useLocation } from "react-router-dom";
import CustomLink from "../../../components/CustomLink";

const ResearcherSidebar = () => {

    const location = useLocation();

    return (
        <div className="card-body">
            <ul className="card-list list text-gray-700 mb-6">
                <li className="list-item">
                    <h6 className="fw-bold text-uppercase mb-3">
                        <CustomLink className={"list-link fs-6 text-gray-700"} to="">
                            Home
                        </CustomLink>
                    </h6>
                </li>
            </ul>

            <ul className="card-list list text-gray-700 mb-6">
                <li className="list-item">
                    <h6 className="fw-bold text-uppercase mb-3">
                        <CustomLink className={"list-link fs-6 text-gray-700"} to="personal-info">
                            Personal information
                        </CustomLink>
                    </h6>
                </li>
            </ul>

            <ul className="card-list list text-gray-700 mb-6">
                <li className="list-item">
                    <h6 className="fw-bold text-uppercase mb-3">
                        <CustomLink className={"list-link fs-6 text-gray-700"} to="personal-interests">
                            Research and teaching interests
                        </CustomLink>
                    </h6>
                </li>
            </ul>

            <ul className="card-list list text-gray-700 mb-6">
                <li className="list-item">
                    <h6 className="fw-bold text-uppercase mb-3">
                        <CustomLink className={"list-link fs-6 text-gray-700"} to="connect">
                            UNIC mapping
                        </CustomLink>
                    </h6>
                </li>
                {
                    location.pathname === "/researcher/connect/researcher-details" &&
                    <li className="list-item">
                        <CustomLink className="list-link text-reset" to="connect/researcher-details">
                            Co-researcher details
                        </CustomLink>
                    </li>
                }
            </ul>

            <h6 className="fw-bold text-uppercase mt-6">Keywords catalogue</h6>
            <ul className="card-list list text-gray-700 mb-6">
                <li className="list-item">
                    <CustomLink className="list-link text-reset" to="thematicLines">
                        Thematic Lines
                    </CustomLink>
                </li>
                <li className="list-item">
                    <CustomLink className="list-link text-reset" to="disciplines">
                        Disciplines
                    </CustomLink>
                </li>
                <li className="list-item">
                    <CustomLink className="list-link text-reset" to="fieldsofresearch">
                        Fields of research
                    </CustomLink>
                </li>
                <li className="list-item">
                    <CustomLink className="list-link text-reset" to="teachingkeywords">
                        Fields of teaching
                    </CustomLink>
                </li>
                <li className="list-item">
                    <CustomLink className="list-link text-reset" to="cooperationkeywords">
                        Modes of cooperation
                    </CustomLink>
                </li>
            </ul>
        </div>
    )
}

export default ResearcherSidebar;