const AdminHome = () => {
  
  return (
    <div className="card card-bleed shadow-light-lg mb-6">
		<div className="card-header">
			<h4 className="accent"> Welcome to Virtual Campus </h4>
		</div>
		<div className="card-body">
			<div className="row justify-content-center">
				<div className="col-12 col-md-10 col-lg-7 text-start" data-aos="fade-up">
					<p className="fs-lg text-muted mb-6 mb-md-8">
						This virtual campus allows administrators to approve pending staff/student registrations for their organization unit.
						Also, it allows them to add/edit new courses, course instances and joint programmes.
					</p>
					<p className="fs-lg text-muted mt-6 mb-6 mb-md-8">
						Through this portal administrators can browse generated YUFE student cards for their organization unit and activate/deactive them.
					</p>
					<p className="fs-lg text-muted mb-6 mb-md-8">
						Other than that, this portal allows mobility management.
						Outgoing mobility gives an overview of students who applied for student exchange, while incoming mobility gives an overview of all courses that have at least one applicant.
						Student requests can either be approved or denied by home or receiving university administrator.
						Mobility management gives a detailed overview of all applicants in diffent enrolment stages (statuses).
					</p>
				</div>
			</div>
		</div>
    </div>
  );
}
 
export default AdminHome;
