import { Routes, Route } from "react-router-dom";
import { aapi, getNameWithTitle } from "../../assets/js/shared";
import AdminHeader from "./components/AdminHeader";
import AdminNavbar from "./components/AdminNavbar";
import AdminSidebar from "./components/AdminSidebar";
import AdminHome from "./AdminHome";
import StudentCard from "./StudentCard";
import PersonRegistration from "./PersonRegistration";
import ErrorPage from "../ErrorPage";
import useFetch from "../../assets/js/useFetch";
import AdminCourses from "./AdminCourses";
import AdminCourseInstances from "./AdminCourseInstances";
import AdminJointProgramme from "./AdminJointProgramme";
import AdminOutgoingPending from "./outgoingMobility/AdminOutgoingPending";
import AdminOutgoingHistory from "./outgoingMobility/AdminOutgoingHistory";
import AdminOutgoingActive from "./outgoingMobility/AdminOutgoingActive";
import AdminIncomingPending from "./incomingMobility/AdminIncomingPending";
import AdminIncomingActive from "./incomingMobility/AdminIncomingActive";
import AdminIncomingHistory from "./incomingMobility/AdminIncomingHistory";
import AdminIncomingFinished from "./incomingMobility/AdminIncomingFinished";
import AdminOutgoingFinished from "./outgoingMobility/AdminOutgoingFinished";
import UserManagement from "./UserManagement";
import { useCallback, useEffect, useState } from "react";
import PartnerJointProgramme from "./PartnerJointProgramme";
// import TeacherManagement from "./TeacherManagement";
import AdminExtraModules from "./AdminExtraModules";
import AdminIncomingDetails from "./incomingMobility/AdminIncomingDetails";
import AdminOutgoingDetails from "./outgoingMobility/AdminOutgoingDetails";
import AdminIncomingActiveDetails from "./incomingMobility/AdminIncomingActiveDetails";
import AdminIncomingFinishedDetails from "./incomingMobility/AdminIncomingFinishedDetails";

const Administrator = () => {
	const { data: user, error } = useFetch('/public/getcurrentuser');

	const [counters, setCounters] = useState(null);

	// get values for counters
	// function is called on first render and it is passed to every component that can change any of the counters' values
	const getCounts = useCallback(() => {
		Promise.all([
			aapi.get("/getallpersonregistrationscount"),
			aapi.get("/outgoingmobilitypendingcount"),
			aapi.get("/incomingmobilitypendingcount"),
			aapi.get("/incomingmobilityactivecount")
		])
			.then(res => {
				setCounters({
					registrationCount: res[0].data,
					outPendingCount: res[1].data,
					inPendingCount: res[2].data,
					inActiveCount: res[3].data
				})
			}).catch(err => { })
	}, [])

	useEffect(() => {
		getCounts();
	}, [getCounts])

	return (
		<>
			{
				error &&
				<ErrorPage></ErrorPage>
			}
			{
				user &&
				<>
					<AdminNavbar userName={getNameWithTitle(user)} userOrgUnit={user.orgUnitName} />
					<AdminHeader userName={getNameWithTitle(user)} userOrgUnit={user.orgUnitName} />

					<main className="pb-8 pb-md-11 mt-md-n6">
						<div className="container-fluid">
							<div className="row">
								<div className="col-12 col-md-3">
									<div className="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
										<div className="collapse d-md-block" id="sidenavCollapse">
											<AdminSidebar counters={counters}></AdminSidebar>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-9 ps-md-0 pe-md-4">
									<Routes>
										<Route path="/" element={<AdminHome />} />
										<Route path="studentcard" element={<StudentCard />} />
										<Route path="personregistration" element={<PersonRegistration counter={getCounts} />} />
										<Route path="usermanagement" element={<UserManagement email={user.email} />} />
										{/* <Route path="teachermanagement" element={<TeacherManagement />} /> */}
										<Route path="courses" element={<AdminCourses orgUnitId={user.orgUnitId} />} />
										<Route path="courses/courseInstances" element={<AdminCourseInstances orgUnitId={user.orgUnitId} />} />
										<Route path="adminjointprogrammes" element={<AdminJointProgramme orgUnitId={user.orgUnitId} roleId={user.roleId} />} />
										<Route path="partnerjointprogrammes" element={<PartnerJointProgramme />} />
										<Route path="outgoingmobilitypending" element={<AdminOutgoingPending counter={getCounts} count={counters ? counters.outPendingCount : null} />} />
										<Route path="outgoingmobilitypending/studentcourses" element={<AdminOutgoingDetails counter={getCounts} />} />
										<Route path="outgoingmobilityactive" element={<AdminOutgoingActive />} />
										<Route path="outgoingmobilityactive/studentcourses" element={<AdminOutgoingDetails counter={getCounts} />} />
										<Route path="outgoingmobilityfinished" element={<AdminOutgoingFinished />} />
										<Route path="outgoingmobilityfinished/studentcourses" element={<AdminOutgoingDetails counter={getCounts} />} />
										<Route path="outgoingmobilityhistory" element={<AdminOutgoingHistory />} />
										<Route path="outgoingmobilityhistory/studentcourses" element={<AdminOutgoingDetails counter={getCounts} />} />
										<Route path="incomingmobilitypending" element={<AdminIncomingPending counter={getCounts} count={counters ? counters.inPendingCount : null} />} />
										<Route path="incomingmobilitypending/coursestudents" element={<AdminIncomingDetails counter={getCounts} />} />
										<Route path="incomingmobilityactive" element={<AdminIncomingActive counter={getCounts} />} />
										<Route path="incomingmobilityactive/coursestudents" element={<AdminIncomingActiveDetails orgUnitId={user.orgUnitId} counter={getCounts} />} />
										<Route path="incomingmobilityfinished" element={<AdminIncomingFinished />} />
										<Route path="incomingmobilityfinished/coursestudents" element={<AdminIncomingFinishedDetails />} />
										<Route path="incomingmobilityhistory" element={<AdminIncomingHistory />} />
										<Route path="incomingmobilityhistory/coursestudents" element={<AdminIncomingDetails />} />
										<Route path="extramodules" element={<AdminExtraModules roleId={user.roleId} />} />
									</Routes>
								</div>
							</div>
						</div>
					</main>
				</>
			}
		</>
	);
}

export default Administrator;
