import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { OUTGOING_MOBILITY_FINISHED, OUTGOING_MOBILITY_FINISHED_DATES, OUTGOING_MOBILITY_STUDENT_COURSES, aapi } from "../../../assets/js/shared";
import { customTheme, muiCache, paginationOptions } from "../../../assets/js/datatables";
import Error from "../../../components/Error";
import { useNavigate } from "react-router-dom";

const AdminOutgoingFinished = () => {

    // columns for student table
    const columns = [
        {
            name: "studentId",
            label: "Student ID",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "studentNameFirst",
            label: "First Name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentNameLast",
            label: "Last name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "eMail",
            label: "eMail",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (eMail) => {
                    if(eMail !== null && eMail !== "") {
                        return <a href={`mailto:${eMail}`}> {eMail} </a>
                    } else {
                        return <span></span>
                    }
                }
            }
        },
        {
            name: "esi",
            label: "ESI",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "coursesInfo",
            label: "Courses",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (coursesInfo, tableMeta) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-info">Inspect</Tooltip>
                            }
                        >
                            <button 
                                className="btn" 
                                aria-label="More details"
                                onClick={() => viewStudentCourses(tableMeta)}
                                >
                                    <i className="fe fe-eye"></i>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [dates, setDates] = useState(JSON.parse(sessionStorage.getItem(OUTGOING_MOBILITY_FINISHED_DATES)));

    // call get data chosen course start date interval
    const handleSearch = (e) => {
        e.preventDefault();
        getData(1, "");
    }

    // get initial data
    const getData = (page, query) => {
        setLoading(true);
        aapi.get(`/outgoingmobilityfinished/${dates.startDate}/${dates.endDate}/${page}${query}`)
        .then(res => {
            setData(res.data.data);
            setTableDetails(res.data.paginationDetails);
            sessionStorage.setItem(OUTGOING_MOBILITY_FINISHED_DATES, JSON.stringify(dates));
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        sessionStorage.removeItem(OUTGOING_MOBILITY_FINISHED);
        let exists = JSON.parse(sessionStorage.getItem(OUTGOING_MOBILITY_FINISHED_DATES));
        if(exists !== null && exists !== "null") {
            getData(1, "")
        }
        // eslint-disable-next-line
    }, []);

    const navigate = useNavigate();

    const viewStudentCourses = (tableMeta) => {
        let object = {
            studentId: tableMeta.rowData[0],
            student: `${tableMeta.rowData[1]} ${tableMeta.rowData[2]}`,
            source: "Outgoing finished",
            pending: false,
            info: false,
            active: false,
            finished: true,
            history: false
        }
        sessionStorage.setItem(OUTGOING_MOBILITY_STUDENT_COURSES, JSON.stringify(object));
        navigate("studentcourses");
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Outgoing finished</h4>
            </div>
            <div className="card-body">
                <form onSubmit={handleSearch}>
                    <div className="row mb-3">
                        <div className="col-12">
                            <h5><b>Course start date between:</b></h5>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label>Interval start</label>
                                <input 
                                    type="date"
                                    className="form-control"
                                    required
                                    value={dates !== null && dates !== "null" ? dates.startDate : null}
                                    onChange={e => setDates({...dates, startDate: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className=" col-12 col-md-4">
                            <div className="form-group">
                                <label>Interval end</label>
                                <input 
                                    type="date"
                                    className="form-control"
                                    required
                                    value={dates !== null && dates !== "null" ? dates.endDate : null}
                                    onChange={e => setDates({...dates, endDate: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="p-2"></label>
                                <button className="btn w-100 btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
                <hr className="break"></hr>
                <div className="row">
                    <div className="col-12">
                        {
                            loading &&
                            <div className="spinner-border text-primary mb-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        {
                            error &&
                            <Error text={error}></Error>
                        }
                        {
                            data &&
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={data}
                                        columns={columns}
                                        options={paginationOptions(tableDetails, getData, setLoading, OUTGOING_MOBILITY_FINISHED)}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminOutgoingFinished;