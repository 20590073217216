import { useCallback, useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { aapi } from "../../assets/js/shared";
import Error from "../../components/Error";

const ResearcherOrcidProfile = ({ user }) => {

    const [data, setData] = useState(null);

    const [error, setError] = useState(null);
    const [urls, setUrls] = useState(null);

    const getData = useCallback(() => {
        aapi.get("/getresearcherdetails")
            .then(res => {
                if (res.data !== "") {
                    setData(res.data);
                    if (res.data.researcherUrl !== null) {
                        setUrls(res.data.researcherUrl.split("; "));
                    }
                }
            })
            .catch(err => {
                setError(err);
            })
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    const changeInfoVisibility = e => {
        aapi.put(`/changeresearchervisibility/${e.target.checked ? "Y" : "N"}`, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                return JSON.stringify(data);
            }]
        })
            .then(res => {
                getData();
            })
            .catch(err => {
                setError(err);
            })
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Personal information</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <Alert variant="success text-light" className="p-4" dismissible>
                            <i className="fe fe-info mt-1 me-3"></i>
                            Data displayed on this page is gathered from your ORCiD profile. If you want more information to be shown, please consider updating your ORCiD profile.
                        </Alert>
                        {
                            error &&
                            <Error text={error}></Error>
                        }
                    </div>
                </div>
                {
                    data &&
                    <>
                        <div className="row mt-3">
                            <div className="col-12">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" defaultChecked={data.visible === "Y"} onChange={e => changeInfoVisibility(e)} />
                                    <label className="form-check-label fw-bolder mt-1">I allow my personal information to be visible to other researchers in UNIC VC</label>
                                </div>
                            </div>
                        </div>
                        <hr />
                    </>
                }
                <div className="row">
                    <div className="col-lg-6">
                        <p className="fw-bolder">First name:</p>
                        <p>{user.personalNames}</p>
                    </div>
                    <div className="col-lg-6">
                        <p className="fw-bolder">Last name:</p>
                        <p>{user.familyNames}</p>
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-lg-6">
                        <p className="fw-bolder">Email:</p>
                        <p>{user.email}</p>
                    </div>
                    {
                        data &&
                        <div className="col-lg-6">
                            <p className="fw-bolder">Country:</p>
                            {
                                data.countryName &&
                                <p>{data.countryName}</p>
                            }
                            {
                                !data.countryName &&
                                <p className="fst-italic">Not available</p>
                            }
                        </div>
                    }
                </div>
                <hr />
                <div className="row">
                    <div className="col-lg-6">
                        <p className="fw-bolder">ORCiD ID:</p>
                        <p className="pt-lg-3">{user.orcid}</p>
                    </div>
                    <div className="col-lg-6">
                        <p className="fw-bolder">ORCiD profile:</p>
                        <span><a href={`https://orcid.org/${user.orcid}`} target="_blank" rel="noreferrer" className="btn btn-success btn-sm">My ORCiD profile <i className="fe fe-external-link"></i></a></span>
                    </div>
                </div>
                {
                    data &&
                    <>
                        <hr />
                        <div className="row">
                            <div className="col-lg-6">
                                <p className="fw-bolder">Biography:</p>
                                {
                                    data.biography &&
                                    <p>{data.biography}</p>
                                }
                                {
                                    !data.biography &&
                                    <p className="fst-italic text-black-50">Not available</p>
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-lg-12">
                                <p className="fw-bolder">Websites & social links:</p>
                                {
                                    urls && urls.map(url => {
                                        return <><a href={url} target="_blank" rel="noreferrer"> {url} <i className="fe fe-external-link"></i></a><br /></>
                                    })
                                }
                                {
                                    !urls &&
                                    <p className="fst-italic text-black-50">Not available</p>
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="row">
                            <div className="col-12">
                                <p className="fw-bolder">ORCiD Keywords:</p>
                                {
                                    data.keywords &&
                                    <p>{data.keywords}</p>
                                }
                                {
                                    !data.keywords &&
                                    <p className="fst-italic text-black-50">Not available</p>
                                }
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default ResearcherOrcidProfile;