import { Alert, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { INCOMING_MOBILITY_STUDENTS, aapi, getCourseStatusBg } from "../../../assets/js/shared";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { customTheme, muiCache, options } from "../../../assets/js/datatables";
import CourseStatusLegend from "../../../components/CourseStatusLegend";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import Error from "../../../components/Error";

const AdminIncomingDetails = ({ counter }) => {

    const getFromStorage = () => {
        return JSON.parse(sessionStorage.getItem(INCOMING_MOBILITY_STUDENTS));
    }

    const columns = [
        {
            name: "orgUnitName",
            label: "Home university",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentNameFirst",
            label: "First name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentNameLast",
            label: "Last name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "esi",
            label: "ESI",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "eMail",
            label: "eMail",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (eMail) => {
                    if (eMail !== null && eMail !== "") {
                        return <a href={`mailto:${eMail}`}> {eMail} </a>
                    } else {
                        return <span></span>
                    }
                }
            }
        },
        {
            name: "virtualOrPhysical",
            label: "Attendance",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (virtualOrPhysical) => {
                    if (virtualOrPhysical === 'V') return (<>Virtual</>)
                    else return (<>Physical</>)
                }
            }
        },
        {
            name: "courseStatusNameShort",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (courseStatusNameShort, tableMeta) => {
                    return (
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id='tooltip-right'>
                                    {tableMeta.rowData[7]}
                                </Tooltip>
                            }
                        >
                            <Badge bg={getCourseStatusBg(tableMeta.rowData[7])} className="badge-lg">{courseStatusNameShort}</Badge>
                        </OverlayTrigger>
                    )
                }
            }
        },
        {
            name: "courseStatusId",
            label: "Status ID",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "studentId",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                display: getFromStorage() !== null ? getFromStorage().pending ? true : "excluded" : "excluded",
                customBodyRender: (studentId) => {
                    return (
                        <>
                            <div className="btn-group">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-approve">Approve</Tooltip>
                                    }
                                >
                                    <i onClick={() => approve(studentId)} className="btn text-success fe fe-check-circle"></i>
                                </OverlayTrigger>
                            </div>
                            <div className="btn-group">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-deny">Deny</Tooltip>
                                    }
                                >
                                    <i onClick={e => deny(studentId)} className="btn text-danger fe fe-x-circle"></i>
                                </OverlayTrigger>
                            </div>
                        </>
                    )
                }
            }
        }
    ];

    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const findStatus = useCallback((storage) => {
        if (storage.pending) {
            return "P";
        } else if (storage.info) {
            return "I";
        } else {
            return "H";
        }
    }, [])

    const getData = useCallback(() => {
        let storage = getFromStorage();
        let status = findStatus(storage);
        aapi.get(`/getstudentsforcourseinstance/${storage.courseInstanceId}/${status}`)
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [findStatus]);

    useEffect(() => {
        if (sessionStorage.getItem(INCOMING_MOBILITY_STUDENTS) === null) {
            navigate("/administrator");
        } else {
            getData();
        }
    }, [getData, navigate]);

    // approve enrolment for a student (PUT)
    const approve = (studentId) => {
        resetMessages();

        aapi.put("/receivinguniapproved/" + getFromStorage().courseInstanceId + "/" + studentId, {}, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                return JSON.stringify(data);
            }]
        })
            .then(res => {
                setSuccess("Enrolment approved! More info can be found in info tab.");
                getData();
                counter();
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    // deny enrolment for a student (PUT)
    const deny = (studentId) => {
        resetMessages();

        aapi.put("/receivingunidenied/" + getFromStorage().courseInstanceId + "/" + studentId, {}, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                return JSON.stringify(data);
            }]
        })
            .then(res => {
                setSuccess("Enrolment denied! More info can be found in info tab.");
                getData();
                counter();
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    const resetMessages = () => {
        setSuccess(null);
        setError(null);
        setLoading(true);
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <div className="row">
                    <div className="col-1">
                        <h4 className="accent">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-back">Go back</Tooltip>
                                }
                            >
                                <button className="btn accent p-0" onClick={() => navigate(-1)}><i className="fe fe-chevron-left fw-bold"></i></button>
                            </OverlayTrigger>
                        </h4>
                    </div>
                    <div className="col-11">
                        <h4 className="accent">
                            <h4 className="accent">{getFromStorage().source} ({getFromStorage().course})</h4>
                        </h4>
                        <h4><b>List of students</b></h4>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        {
                            loading &&
                            <div className="spinner-border text-primary mb-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        <CourseStatusLegend />
                        {
                            success &&
                            <Alert variant="success-soft" className="mt-3 p-3" onClose={() => setSuccess(null)} dismissible>{success}</Alert>
                        }
                        {
                            error &&
                            <Error className="mt-3" text={error}></Error>
                        }
                        {
                            data &&
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminIncomingDetails;