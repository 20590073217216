import { Tab, Tabs } from "react-bootstrap";
import AdminOutgoingPendingTable from "./AdminOutgoingPendingTable";

const AdminOutgoingPending = ({ counter, count }) => {
    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Outgoing pending enrolment</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        <Tabs defaultActiveKey="applied" id="pendingEnrolments" className="mb-3">
                            <Tab eventKey="applied" title={`To do (${count})`}>
                                <AdminOutgoingPendingTable counter={counter} pending={true} />
                            </Tab>
                            <Tab eventKey="approved" title="Info">
                                <AdminOutgoingPendingTable counter={counter} pending={false} />
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminOutgoingPending;