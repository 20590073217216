export const programmeLevelFO = ["bachelor", "master"];

export const moduleTypeFO = ["Language modules"];

export const genderFO = ["M", "F", "O"];

export const courseActiveFO = ["Y", "N"];

export const virtualOrPhysicalFO = ["V", "P"];

export const courseStatusShortFO = ["applied", "home UNI approved", "home UNI denied", "receiving UNI approved", "receiving UNI denied", "dropped off", "enroled", "completed/passed"];

export const gradesFO = ["A", "B", "C", "D", "E", "F", "FX"];