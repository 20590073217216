import { useCallback, useEffect, useState } from "react";
import { TEACHING_KEYWORDS, aapi } from "../../assets/js/shared";
import Error from "../../components/Error";
import { CacheProvider } from "@emotion/react";
import { customTheme, muiCache, paginationOptions } from "../../assets/js/datatables";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";

const TeachingKeywordCatalogue = () => {

    const columns = [
        {
            name: "teachingKeywordId",
            label: "Keyword ID",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "teachingKeywordName",
            label: "Field of teaching",
            options: {
                filter: true,
                sort: true
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const getData = useCallback((page, query) => {
        setLoading(true);

        aapi.get(`/getteachingkeywords/${page}${query}`)
            .then(res => {
                setData(res.data.data);
                setTableDetails(res.data.paginationDetails);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        sessionStorage.removeItem(TEACHING_KEYWORDS);
        getData(1, "");
    }, [getData]);

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Fields of teaching</h4>
            </div>
            <div className="card-body">
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                {
                    data &&
                    <div className="row">
                        <div className="col-12">
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        title={""}
                                        data={data}
                                        columns={columns}
                                        options={paginationOptions(tableDetails, getData, setLoading, TEACHING_KEYWORDS)}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default TeachingKeywordCatalogue;