import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { customTheme, getQuery, muiCache, options2, paginationOptions } from "../../assets/js/datatables";
import { ADMIN_JOINT_PROGRAMME, aapi, searchSelectStyle } from "../../assets/js/shared";
import Error from "../../components/Error";
import BaseSelect from "react-select";
import FixRequiredSelect from "../../components/FixRequiredSelect";
import { programmeLevelFO } from "../../assets/js/filterOptions";

const Select = props => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

const AdminJointProgramme = props => {

    // columns for joint programme table (admin view)
    const adminColumns = [
        {
            name: "programmeName",
            label: "Programme name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (name, tableMeta ) => {
                    const url = tableMeta.rowData[2];
            
                    if(url) {
                        return (
                            <a href={url} target="_blank" rel="noreferrer"> {name} <i className="fe fe-external-link"></i></a>
                        )
                    } else {
                        return (
                            <span> {name} </span>
                        )
                    }
                }
            }
        },
        {
            name: "programmeLevelName",
            label: "Programme level",
            options: {
                filter: true,
                filterType: 'dropdown',
                filterOptions: { names: programmeLevelFO },
                sort: true,
            }
        },
        {
            name: "programmeUrl",
            label: "URL",
            options: {
                filter: false,
                sort: false,
                display: "excluded",
            }
        },
        {
            name: "adminOrgUnitName",
            label: "Administrative institution",
            options: {
                filter: props.roleId > 2 ? false : true,
                sort: true,
                display: props.roleId > 2 ? "excluded" : true
            }
        },
        {
            name: "partners",
            label: "Partner institutions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (partners) => {
                    if(partners.length > 0) {
                        return (
                            partners.map(p => {
                                return (<>{p.partnerOrgUnitName}; </>)
                            })
                        )
                    } else {
                        return (<>-</>)
                    }
                }
            }
        },
        {
            name: "courses",
            label: "Courses",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (courses) => {
                    if(courses.length > 0) {
                        return (
                            courses.map(c => {
                                return (<>{c.courseName}; </>)
                            })
                        )
                    } else {
                        return (<>-</>)
                    }
                }
            }
        },
        {
            name: "programmeId",
            label: "Actions",
            options: {
                download: false,
                filter: false,
                sort: false,
                customBodyRender: (programmeId, tableMeta) => {
                    return (
                        <div className="btn-group">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-edit">Edit programme</Tooltip>
                                }
                            >
                                <button className="btn text-info" onClick={() => handleShowEditModal(tableMeta)}>
                                    <i className="fe fe-edit"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-edit">Edit partners</Tooltip>
                                }
                            >
                                <button className="btn text-info" onClick={() => handleShowEditPartnersModal(programmeId, tableMeta)}>
                                    <i className="fe fe-home"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-edit">Edit courses</Tooltip>
                                }
                            >
                                <button className="btn text-info" onClick={() => handleShowEditCoursesModal(programmeId, tableMeta)}>
                                    <i className="fe fe-book"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        },
        {
            name: "programmeLevelId",
            label: "Programme level ID",
            options: {
                filter: false,
                sort: false,
                download: false,
                display: "excluded"
            }
        }
    ];

    // columns for table containing partner institutions
    const partnerColumns = [
        {
            name: "partnerOrgUnitName",
            label: "Partner institution",
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "locationName",
            label: "Location",
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "partnerOrgUnitId",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (partnerOrgUnitId) => {
                    return (
                        <div className="btn-group">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-edit">Remove</Tooltip>
                                }
                            >
                                <button className="btn text-danger" onClick={() => removePartner(partnerOrgUnitId)}>
                                    <i className="fe fe-trash"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        }
    ];

    // columns for table containing courses
    const courseColumns = [
        {
            name: "courseName",
            label: "Course",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (courseName, tableMeta) => {
                    const url = tableMeta.rowData[1];

                    if(url !== null && url !== "") {
                        return <a href={url} target="_blank" rel="noreferrer">{courseName} <i className="fe fe-external-link"></i></a>
                    } else {
                        return <span>{courseName}</span>
                    }
                }
            }
        },
        {
            name: "uri",
            label: "URL",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "orgUnitName",
            label: "Organization unit",
            options: {
                filter: false,
                sort: true
            }
        },
        {
            name: "courseId",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (courseId) => {
                    return (
                        <div className="btn-group">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-edit">Remove</Tooltip>
                                }
                            >
                                <button className="btn text-danger" onClick={() => removeCourse(courseId)}>
                                    <i className="fe fe-trash"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        }
    ];

    const [programmes, setProgrammes] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [levels, setLevels] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // get initial data (joint programmes and programme levels)
    // if called from add new joint programme modal then get courses too
    const getData = useCallback((page, query) => {
        aapi.get(`/getadminunijointprogramme/${page}${query}`)
        .then(res => {
            setProgrammes(res.data.data);
            setTableDetails(res.data.paginationDetails);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        sessionStorage.removeItem(ADMIN_JOINT_PROGRAMME);
        getData(1, "");
    }, [getData]);

    const getProgLevels = useCallback(() => {
        aapi.get("/programmelevel")
        .then(res => {
            setLevels(res.data);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        getProgLevels();
    }, [getProgLevels]);

    // add new joint programme modal hooks
    const [showAddModal, setShowAddModal] = useState(false);
    const [addModalError, setAddModalError] = useState(null);
    const [orgUnits, setOrgUnits] = useState(null);
    const [addCourses, setAddCourses] = useState(null);
    const [programmeId, setProgrammeId] = useState(null);
    const [disabled, setDisabled] = useState(false);

    // function called when add joint programme button is clicked
    const handleShowAddModal = () => {
        setShowAddModal(true);
        aapi.get("/getorgunits")
        .then(res => {
            let data = [];
            let sorted = sortOrgUnits(res.data);
            sorted.forEach(item => {
                data.push({
                    label: item.orgUnitName + " [" + item.locationName + "]",
                    value: item.orgUnitId
                })
            })
            setOrgUnits(data);
        })
        .catch(err => {
            setAddModalError(err);
        })
    }

    const sortOrgUnits = orgUnits => {
        // remove UNIC VC Support Center and user's orgUnit
        orgUnits = orgUnits.filter(ou => {return (ou.orgUnitId !== props.orgUnitId) && (ou.orgUnitId !== 1)});
        // sort by orgUnit name
        orgUnits = orgUnits.sort((a, b) => a.orgUnitName < b.orgUnitName ? 1 : -1);
        // then sort by super orgUnit
        orgUnits = orgUnits.sort((a, b) => a.superOrgUnitId > b.superOrgUnitId ? 1 : -1);

        let sorted = [];
        orgUnits.forEach(ou => {
            // check if orgUnit already exists in result array
            if(!sorted.some(el => el.orgUnitId === ou.orgUnitId)) {
                sorted.push(ou);
            }
            // find all sub orgUnits for current orgUnit
            orgUnits.forEach(ouu => {
                if(ouu.superOrgUnitId === ou.orgUnitId) {
                    sorted.push(ouu);
                }
            })
        })
        return sorted;
    }

    // new joint programme data
    const [sendNewData, setSendNewData] = useState({
        adminOrgUnitId: props.orgUnitId,
        partners: []
    });

    // handles changes for select list containing list of all available partners
    const handlePartnerChange = (e) => {
        let partnerIds = [];
        e.forEach(item => {
            partnerIds.push({
                partnerOrgUnitId: item.value
            });
        })
        setSendNewData({...sendNewData, partners: partnerIds});
    }

    // new joint programme (POST) - creates new programme and adds partners to it
    const handleSubmit = (e) => {
        e.preventDefault();

        aapi.post("/pushjointprogrammedata", sendNewData, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            getData(tableDetails.currentPage, getQuery(ADMIN_JOINT_PROGRAMME));
            setDisabled(true);
            closeAddModal();
        })
        .catch(err => {
            setAddModalError(err);
        })
    }

    const [newCourses, setNewCourses] = useState(null);

    // handles changes for select list containing list of courses
    const handleCourseChange = (e) => {
        let courseIds = [];
        e.forEach(item => {
            courseIds.push({
                courseId: item.value
            });
        })
        setNewCourses({...newCourses, courses: courseIds});
    }

    // add courses to joint programme (POST)
    const submitCourses = (e) => {
        e.preventDefault();

        aapi.post(`/addcoursetojointprogramme/${programmeId}`, newCourses, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            getData(tableDetails.currentPage, getQuery(ADMIN_JOINT_PROGRAMME));
        })
        .catch(err => {
            setAddModalError(err);
        })
    }

    // close new joint programme modal
    const closeAddModal = () => {
        setShowAddModal(false);
        setAddModalError(null);
        setSendNewData({
            adminOrgUnitId: props.orgUnitId,
            partners: []
        });
        setDisabled(false);
        setAddCourses(null);
        setProgrammeId(null);
    }

    // edit joint programme modal hooks
    const [showEditModal, setShowEditModal] = useState(false);
    const [editModalError, setEditModalError] = useState(null);
    const [currentName, setCurrentName] = useState(null);

    // edit joint programme data
    const [sendEditData, setSendEditData] = useState(null);

    // show edit joint programme modal
    const handleShowEditModal = (tableMeta) => {
        setSendEditData({...sendEditData,
            adminOrgUnitId: props.orgUnitId,
            programmeId: tableMeta.rowData[6],
            programmeName: tableMeta.rowData[0],
            programmeLevelId: tableMeta.rowData[7],
            programmeUrl: tableMeta.rowData[2]
        });
        setCurrentName(tableMeta.rowData[0]);
        setShowEditModal(true);
    }

    // close edit joint programme modal
    const closeEditModal = () => {
        setShowEditModal(false);
        setEditModalError(null);
    }

    // edit joint programme (PUT)
    const handleEdit = (e) => {
        e.preventDefault();

        aapi.put("/editbasicjointprogramme", sendEditData, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            closeEditModal();
            getData(tableDetails.currentPage, getQuery(ADMIN_JOINT_PROGRAMME));
        })
        .catch(err => {
            setEditModalError(err);
        })
    }

    // edit partners modal hooks
    const [showEditPartnersModal, setShowEditPartnersModal] = useState(false);
    const [editPartnersModalError, setEditPartnersModalError] = useState(null);

    const [partners, setPartners] = useState(null);
    const [partnersList, setPartnersList] = useState(null);
    const [newPartner, setNewPartner] = useState(null);

    // get list of all orgUnits and partner orgUnits
    const getPartnerData = async (programmeId) => {
        await Promise.all([
            aapi.get("/getorgunits"),
            aapi.get(`/getpartnerunijointprogramme/${programmeId}`)
        ])
        .then(res => {
            setPartners(res[1].data.sort((a, b) => a.partnerOrgUnitName > b.partnerOrgUnitName ? 1 : -1));
            let filter = res[0].data.filter(
                x => {
                    return !res[1].data.some(
                        y => {
                            return x.orgUnitId === y.partnerOrgUnitId;
                        }
                    ) && x.orgUnitId !== props.orgUnitId && x.orgUnitId !== 1
                }
            ).sort((a, b) => a.orgUnitName > b.orgUnitName ? 1 : -1);
            let data = [];
            filter.forEach(item => {
                data.push({
                    label: item.orgUnitName + " [" + item.locationName + "]",
                    value: item.orgUnitId
                })
            })
            setPartnersList(data);
        })
        .catch(err => {
            setEditPartnersModalError(err);
        })
    };

    // show edit partners modal
    const handleShowEditPartnersModal = (programmeId, tableMeta) => {
        setProgrammeId(programmeId);
        getPartnerData(programmeId);
        setShowEditPartnersModal(true);
        setCurrentName(tableMeta.rowData[0]);
    }

    // add partner institution to joint programme (POST)
    const addPartner = (e) => {
        e.preventDefault();

        aapi.post(`/addpartnertojointprogramme/${programmeId}/${newPartner}`, {}, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            getPartnerData(programmeId);
            setPartnerValue("");
        })
        .catch(err => {
            setEditPartnersModalError(err);
        })
    }

    // change displayed value in partner institution react-select
    const [partnerValue, setPartnerValue] = useState("");
    const handleSelectPartnerChange = (e) => {
        setNewPartner(e.value);
        setPartnerValue(e);
    }

    // remove partner institution from joint programme (DELETE)
    const removePartner = (partnerId) => {
        aapi.delete(`/removepartnerfromjointprogramme/${programmeId}/${partnerId}`)
        .then(res => {
            getPartnerData(programmeId);
        })
        .catch(err => {
            setEditPartnersModalError(err);
        })
    }

    // close edit partners modal
    const closeEditPartnersModal = () => {
        setShowEditPartnersModal(false);
        setCurrentName(null);
        getData(tableDetails.currentPage, getQuery(ADMIN_JOINT_PROGRAMME));
        setEditPartnersModalError(null);
    }

    // edit courses hooks
    const [showEditCoursesModal, setShowEditCoursesModal] = useState(false);
    const [editCoursesModalError, setEditCoursesModalError] = useState(null);

    const [courses, setCourses] = useState(null);
    const [coursesList, setCoursesList] = useState(null);
    const [newCourse, setNewCourse] = useState(null);

    // get list of all courses from partner institutions and courses that are already added to joint programme
    const getCourseData = async (programmeId) => {
        await Promise.all([
            aapi.get(`/getunicoursesadminandpartner/${programmeId}/0`),
            aapi.get(`/getcoursesforjointprogramme/${programmeId}`)
        ])
        .then(res => {
            if(res[1].data !== "") {
                setCourses(res[1].data.coursesList.sort((a, b) => a.courseName > b.courseName ? 1 : -1));
                let filter = res[0].data.filter(
                    x => {
                        return !res[1].data.coursesList.some(
                            y => {
                                return x.courseId === y.courseId;
                            }
                        )
                    }
                ).sort((a, b) => a.courseName > b.courseName ? 1 : -1);
                let data = [];
                filter.forEach(item => {
                    data.push({
                        label: item.courseName + " [" + item.orgUnitName + "]",
                        value: item.courseId
                    })
                })
                setCoursesList(data);
            } else {
                setCourses([]);
                let data = [];
                res[0].data.forEach(item => {
                    data.push({
                        label: item.courseName + " [" + item.orgUnitName + "]",
                        value: item.courseId
                    })
                })
                setCoursesList(data);
            }
        })
        .catch(err => {
            setEditCoursesModalError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    };

    // show edit courses modal
    const handleShowEditCoursesModal = (programmeId, tableMeta) => {
        setProgrammeId(programmeId);
        getCourseData(programmeId);
        setShowEditCoursesModal(true);
        setCurrentName(tableMeta.rowData[0]);
    }

    // add course to joint programme (POST)
    const addCourse = (e) => {
        e.preventDefault();

        aapi.post(`/addcoursetojointprogramme/${programmeId}`, newCourse, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            getCourseData(programmeId);
            setCourseValue("");
        })
        .catch(err => {
            setError(err);
        })
    }

    // change displayed value in course react-select
    const [courseValue, setCourseValue] = useState("");
    const handleSelectCourseChange = (e) => {
        let courseIds = [];
        e.forEach(item => {
            courseIds.push({
                courseId: item.value
            });
        })
        setNewCourse({...newCourse, courses: courseIds});
        setCourseValue(e);
    }

    // remove course from joint programme (DELETE)
    const removeCourse = (courseId) => {
        aapi.delete(`/removecoursefromjointprogramme/${courseId}/${programmeId}`)
        .then(res => {
            getCourseData(programmeId);
        })
        .catch(err => {
            setError(err);
        })
    }

    // close edit courses modal
    const closeEditCoursesModal = () => {
        setShowEditCoursesModal(false);
        setCurrentName(null);
        getData(tableDetails.currentPage, getQuery(ADMIN_JOINT_PROGRAMME));
        setEditCoursesModalError(null);
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">My programmes</h4>
            </div>
            <div className="card-body">
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                <div className="row mt-5">
                    <div className="col-12 col-md-auto">
                        <button className="btn w-100 btn-primary" onClick={() => handleShowAddModal()}>Add joint programme</button>
                    </div>
                </div>
                <hr className="break"></hr>
                <div className="row">
                    <div className="col">
                        {
                            programmes &&
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={programmes}
                                        columns={adminColumns}
                                        options={paginationOptions(tableDetails, getData, setLoading, ADMIN_JOINT_PROGRAMME)}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        }
                    </div>
                </div>

                <Modal
                    size="lg"
                    show={showAddModal}
                    onHide={closeAddModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >   
                    <Modal.Header closeButton>
                        <Modal.Title>Add new joint programme</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            addModalError &&
                            <Error text={addModalError}></Error>
                        }
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <p className="mt-0">* - required field</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Programme name*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            onChange={e => setSendNewData({...sendNewData, programmeName: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Programme level*</label>
                                        <select
                                            className="form-select"
                                            defaultValue=""
                                            required
                                            onChange={e => setSendNewData({...sendNewData, programmeLevelId: e.target.value})}
                                        >
                                            <option key="0" value="" selected disabled hidden>Choose level</option>
                                            {
                                                levels &&
                                                levels.map((item) => {
                                                    return (
                                                        <option key={item.programmeLevelId} value={item.programmeLevelId}>{item.programmeLevelName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>URL</label>
                                        <input
                                            className="form-control"
                                            type="url"
                                            placeholder="https://example.com"
                                            onChange={e => setSendNewData({...sendNewData, programmeUrl: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Partners*</label>
                                        {
                                            orgUnits &&
                                            <Select
                                                styles={searchSelectStyle}
                                                isSearchable={true}
                                                options={orgUnits}
                                                placeholder="Choose"
                                                onChange={e => handlePartnerChange(e)}
                                                isMulti
                                                isDisabled={disabled}
                                                required
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-auto d-flex">
                                    <button className="btn w-100 btn-primary" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                        {
                            addCourses &&
                            <form onSubmit={submitCourses}>
                                <div className="row mt-5">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Courses*</label>
                                            <Select
                                                styles={searchSelectStyle}
                                                isSearchable={true}
                                                options={addCourses}
                                                placeholder="Choose"
                                                onChange={e => handleCourseChange(e)}
                                                isMulti
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-auto d-flex">
                                        <button className="btn w-100 btn-primary" type="submit">Add courses</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    show={showEditModal}
                    onHide={closeEditModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >   
                    <Modal.Header closeButton>
                        {
                            currentName &&
                            <Modal.Title>Edit joint programme - <span className="accent">{currentName}</span></Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {
                            editModalError &&
                            <Error text={editModalError}></Error>
                        }
                        {
                            sendEditData &&
                            <form onSubmit={handleEdit}>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <p className="mt-0">* - required field</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Programme name*</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                required
                                                defaultValue={sendEditData.programmeName}
                                                onChange={e => setSendEditData({...sendEditData, programmeName: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Programme level*</label>
                                            <select
                                                className="form-select"
                                                required
                                                defaultValue={sendEditData.programmeLevelId}
                                                onChange={e => setSendEditData({...sendEditData, programmeLevelId: e.target.value})}
                                            >
                                                {
                                                    levels &&
                                                    levels.map((item) => {
                                                        return (
                                                            <option key={item.programmeLevelId} value={item.programmeLevelId}>{item.programmeLevelName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>URL</label>
                                            <input
                                                className="form-control"
                                                type="url"
                                                placeholder="https://example.com"
                                                defaultValue={sendEditData.programmeUrl}
                                                onChange={e => setSendEditData({...sendEditData, programmeUrl: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-auto d-flex">
                                        <button className="btn w-100 btn-primary" type="submit">Save</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    show={showEditPartnersModal}
                    onHide={closeEditPartnersModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >   
                    <Modal.Header closeButton>
                        {
                            currentName &&
                            <Modal.Title>Edit partners - <span className="accent">{currentName}</span></Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {
                            editPartnersModalError &&
                            <Error text={editPartnersModalError}></Error>
                        }
                        <form onSubmit={addPartner}>
                            <div className="row mt-5 mb-2">
                                <div className="col-12">
                                    <label>Partner institution</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-9">
                                    {
                                        partnersList &&
                                        <Select
                                            styles={searchSelectStyle}
                                            isSearchable={true}
                                            value={partnerValue}
                                            options={partnersList}
                                            placeholder="Choose"
                                            onChange={e => handleSelectPartnerChange(e)}
                                            required
                                        />
                                    }
                                </div>
                                <div className="col-lg-3">
                                    <button type="submit" className="btn w-100 btn-primary">Add partner</button>
                                </div>
                            </div>
                        </form>
                        <hr className="break"></hr>
                        <div className="row">
                            <div className="col-12">
                                {
                                    partners &&
                                    <CacheProvider value={muiCache}>
                                        <ThemeProvider theme={customTheme}>
                                            <MUIDataTable
                                                data={partners}
                                                columns={partnerColumns}
                                                options={options2}
                                            />
                                        </ThemeProvider>
                                    </CacheProvider>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    show={showEditCoursesModal}
                    onHide={closeEditCoursesModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >   
                    <Modal.Header closeButton>
                        {
                            currentName &&
                            <Modal.Title>Edit courses - <span className="accent">{currentName}</span></Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {
                            editCoursesModalError &&
                            <Error text={editCoursesModalError}></Error>
                        }
                        <form onSubmit={addCourse}>
                            <div className="row mt-5 mb-2">
                                <div className="col-12">
                                    <label>Course</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        coursesList &&
                                        <Select
                                            styles={searchSelectStyle}
                                            isSearchable={true}
                                            value={courseValue}
                                            options={coursesList}
                                            placeholder="Choose"
                                            onChange={e => handleSelectCourseChange(e)}
                                            isMulti
                                            required
                                        />
                                    }
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 col-md-auto">
                                    <button type="submit" className="btn w-100 btn-primary">Add course</button>
                                </div>
                            </div>
                        </form>
                        <hr className="break"></hr>
                        <div className="row">
                            <div className="col-12">
                                {
                                    courses &&
                                    <CacheProvider value={muiCache}>
                                        <ThemeProvider theme={customTheme}>
                                            <MUIDataTable
                                                data={courses}
                                                columns={courseColumns}
                                                options={options2}
                                            />
                                        </ThemeProvider>
                                    </CacheProvider>
                                }
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default AdminJointProgramme;