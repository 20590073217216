import { CacheProvider } from "@emotion/react";
import Error from "../../components/Error";
import { customTheme, muiCache, paginationOptions } from "../../assets/js/datatables";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { STUDENT_EXTRA_MODULES, aapi } from "../../assets/js/shared";
import { useCallback } from "react";

const StudentExtraModules = () => {

    const columns = [
        {
            name: "uri",
            label: "URI",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "extraModuleName",
            label: "Module name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (extraModuleName, tableMeta) => {
                    const url = tableMeta.rowData[0];

                    if(url && url.startsWith("http")){
                        return (
                            <a href={url} target="_blank" rel="noreferrer"> {extraModuleName} <i className="fe fe-external-link"></i></a>
                        )
                    } else {
                        return (
                            <span> {extraModuleName} </span>
                        )
                    }
                }
            }
        },
        {
            name: "extraModuleDescription",
            label: "Description",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "moduleTypeName",
            label: "Module type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "orgUnitName",
            label: "University name",
            options: {
                filter: true,
                sort: true
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

	const getData = useCallback((page, query) => {
		aapi.get(`/public/getextramodules/${page}${query}`)
			.then(res => {
				setData(res.data.data);
				setTableDetails(res.data.paginationDetails);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}, [])

	useEffect(() => {
        sessionStorage.removeItem(STUDENT_EXTRA_MODULES);
		getData(1, "");
	}, [getData]);

    return (
        <div className="card shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Extra modules</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">        
                        <CacheProvider value={muiCache}>
                            <ThemeProvider theme={customTheme}>
                            {
                                error &&
                                <Error text={error}></Error>
                            }
                            {
                                isLoading &&
                                <div className="spinner-border text-primary mb-1" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                            {
                                data &&
                                <MUIDataTable
                                    data={data}
                                    columns={columns}
                                    options={paginationOptions(tableDetails, getData, setIsLoading, STUDENT_EXTRA_MODULES)}
                                />
                            }
                            </ThemeProvider>
                        </CacheProvider>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentExtraModules;