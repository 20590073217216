import { CacheProvider } from "@emotion/react";
import { customTheme, muiCache, paginationOptions } from "../../assets/js/datatables";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useState } from "react";
import Error from "../../components/Error";
import { RESEARCHER_CONNECT, RESEARCHER_CONNECT_DETAILS, aapi } from "../../assets/js/shared";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import MatchPercentageView from "./components/MatchPercentageView";

const ResearchersConnect = () => {

    const columns = [
        {
            name: "personalNames",
            label: "First name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "familyNames",
            label: "Last name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "email",
            label: "eMail",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (email) => {
                    return <a href={`mailto:${email}`}> {email} </a>
                }
            }
        },
        {
            name: "orcid",
            label: "ORCiD",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (orcid) => {
                    if (orcid !== null) {
                        return (
                            <a className="btn btn-success" href={`https://orcid.org/${orcid}`} target="_blank" rel="noreferrer">ORCiD <i className="fe fe-external-link"></i></a>
                        )
                    }
                }
            }
        },
        {
            name: "orgUnitName",
            label: "Organization name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "superOrgUnitName",
            label: "University name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "totalMatch",
            label: "Total match",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (totalMatch) => {
                    return (<MatchPercentageView data={totalMatch.toFixed(2)} />)
                }
            }
        },
        {
            name: "personId",
            label: "Details",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (personId) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-info">Inspect</Tooltip>
                            }
                        >
                            <button 
                                className="btn" 
                                aria-label="More details"
                                onClick={() => detailsLink(personId)}
                                >
                                    <i className="fe fe-eye"></i>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState([]);
    const [tableDetails, setTableDetails] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);

    const getData = useCallback((page, query) => {
        setLoading(true);

        aapi.get(`/getresearchers/${page}${query}`)
            .then(res => {
                setData(res.data.data);
                setTableDetails(res.data.paginationDetails);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        sessionStorage.removeItem(RESEARCHER_CONNECT);
        getData(1, "");
    }, [getData]);

    const navigate = useNavigate();

    const detailsLink = (id) => {
        sessionStorage.setItem(RESEARCHER_CONNECT_DETAILS, id);
        navigate("researcher-details");
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">UNIC mapping</h4>
            </div>
            <div className="card-body">
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                {
                    data &&
                    <div className="row">
                        <div className="col-12">
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        title={"YUFE VC Researchers"}
                                        data={data}
                                        columns={columns}
                                        options={paginationOptions(tableDetails, getData, setLoading, RESEARCHER_CONNECT)}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default ResearchersConnect;