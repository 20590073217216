import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { customTheme, getQuery, muiCache, paginationOptions } from "../../../assets/js/datatables";
import { ADMIN_COURSE_INSTANCES_ACTIVE, ADMIN_COURSE_INSTANCES_FINISHED, aapi, getDate } from "../../../assets/js/shared";
import { useState } from "react";
import Error from "../../../components/Error";

const CourseInstanceTable = ({data, getData, history, courseName, localId, tableDetails, setIsLoading}) => {

    const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

    // columns for course instance table
    const columns = [
        {
            name: "courseInstanceNo",
            label: "Instance number",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "academicYear",
            label: "Academic year",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (academicYear) => {
                    return <>{academicYear}/{academicYear+1}</>
                }
            }
        },
        {
            name: "startDate",
            label: "Start date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (startDate) => {
                    return (
                        <>{getDate(startDate)}</>
                    )
                },
                customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setStartDate("");
						getData(tableDetails.currentPage, getQuery(history ? ADMIN_COURSE_INSTANCES_FINISHED : ADMIN_COURSE_INSTANCES_ACTIVE));
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">Start date</label>
								<input
									type="date"
									className="date-filter-input"
									value={startDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setStartDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
            }
        },
        {
            name: "endDate",
            label: "End date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (endDate) => {
                    return (
                        <>{getDate(endDate)}</>
                    )
                },
                customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setEndDate("");
						getData(tableDetails.currentPage, getQuery(history ? ADMIN_COURSE_INSTANCES_FINISHED : ADMIN_COURSE_INSTANCES_ACTIVE));
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">End date</label>
								<input
									type="date"
									className="date-filter-input"
									value={endDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setEndDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
            }
        },
        {
            name: "studentQuota",
            label: "Quota",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "courseInstanceId",
            label: "Actions",
            options: {
                download: false,
                filter: false,
                sort: false,
                display: !history,
                customBodyRender: (courseInstanceId, tableMeta) => {
                    if(+new Date(tableMeta.rowData[3]) >= +new Date(Date.now())) {
                        return (
                            <div className="btn-group">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-edit">Edit</Tooltip>
                                    }
                                >
                                    <button className="btn text-info" onClick={() => handleShowEditModal(tableMeta)}>
                                        <i className="fe fe-edit"></i>
                                    </button>
                                </OverlayTrigger>
                                {/* <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-teachers">Add/remove teachers</Tooltip>
                                    }
                                >
                                    <button className="btn text-success" onClick={() => handleShowTeacherModal(tableMeta)}>
                                        <i className="fe fe-user-plus"></i>
                                    </button>
                                </OverlayTrigger> */}
                            </div>
                        )
                    } else {
                        return (
                            <span></span>
                        )
                    }
                }
            }
        },
        {
            name: "teachers",
            label: "Teachers",
            options: {
                filter: false,
                sort: false
                // customBodyRender: (teachers) => {
                //     return (
                //         teachers.map(teacher => {
                //             if(teacher.titleAfterName) {
                //                 return (<>{teacher.titleBeforeName} {teacher.personalNames} {teacher.familyNames} {teacher.titleAfterName}; </>)
                //             } else {
                //                 return (<>{teacher.titleBeforeName} {teacher.personalNames} {teacher.familyNames}; </>)
                //             }
                //         })
                //     )
                // }
            }
        }
    ];

    
    // columns for teacher table in manage teachers modal
    // const addTeacherColumns = [
    //     {
    //         name: "titleBeforeName",
    //         label: "Title before name",
    //         options: {
    //             filter: false,
    //             sort: false
    //         }
    //     },
    //     {
    //         name: "personalNames",
    //         label: "First name",
    //         options: {
    //             filter: false,
    //             sort: false
    //         }
    //     },
    //     {
    //         name: "familyNames",
    //         label: "Last name",
    //         options: {
    //             filter: false,
    //             sort: false
    //         }
    //     },
    //     {
    //         name: "titleAfterName",
    //         label: "Title after name",
    //         options: {
    //             filter: false,
    //             sort: false
    //         }
    //     },
    //     {
    //         name: "teacherRoleId",
    //         label: "Role",
    //         options: {
    //             filter: false,
    //             sort: false,
    //             customBodyRender: (teacherRoleId) => {
    //                 return (
    //                     <>{getRole(teacherRoleId)}</>
    //                 )
    //             }
    //         }
    //     },
    //     {
    //         name: "personId",
    //         label: "Action",
    //         options: {
    //             filter: false,
    //             sort: false,
    //             customBodyRender: (personId) => {
    //                 return (
    //                     <OverlayTrigger
    //                         placement="top"
    //                         overlay={
    //                             <Tooltip id="tooltip-remove">Remove</Tooltip>
    //                         }
    //                     >
    //                         <button className="btn text-danger">
    //                             <i id={personId} onClick={(e) => removeTeacher(e.target.id)} className="fe fe-trash"></i>
    //                         </button>
    //                     </OverlayTrigger>
    //                 )
    //             }
    //         }
    //     }
    // ];

    // edit instance modal hooks
    const [showEditModal, setShowEditModal] = useState(false);
    const [editModalError, setEditModalError] = useState(null);
    const [currentInstance, setCurrentInstance] = useState(null);
    const [currentInstanceNo, setCurrentInstanceNo] = useState(null);

    // edit course instance data (PUT)
    const [sendEditData, setSendEditData] = useState(null);

    // show edit course instance modal
    const handleShowEditModal = (tableMeta) => {
        setSendEditData({...sendEditData,
            courseInstanceNo: tableMeta.rowData[0],
            academicYear: tableMeta.rowData[1],
            startDate: tableMeta.rowData[2].split("T")[0],
            endDate: tableMeta.rowData[3].split("T")[0],
            studentQuota: tableMeta.rowData[4],
            teachers: tableMeta.rowData[6]
        });
        setCurrentInstance(tableMeta.rowData[5]);
        setCurrentInstanceNo(tableMeta.rowData[0]);
        setShowEditModal(true);
    }

    // close edit course instance modal
    const closeEditModal = () => {
        setShowEditModal(false);
        setEditModalError(null);
        setSendEditData(null);
    }

    // edit course instance (PUT)
    const handleEdit = (e) => {
        e.preventDefault();

        aapi.put("/editcourseinstancedata/" + currentInstance, sendEditData, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            closeEditModal();
            getData(tableDetails.currentPage, getQuery(history ? ADMIN_COURSE_INSTANCES_FINISHED : ADMIN_COURSE_INSTANCES_ACTIVE));
        })
        .catch(err => {
            setEditModalError(err);
        })
    }

    // manage teachers modal hooks
    // const [showTeacherModal, setShowTeacherModal] = useState(false);
    // const [newTeacher, setNewTeacher] = useState(null);
    // const [teachers, setTeachers] = useState(null);
    // const [teacherRoles, setTeacherRoles] = useState(null);
    // const [currentTeachers, setCurrentTeachers] = useState([]);
    // const [teacherModalError, setTeacherModalError] = useState(null);

    // show manage teachers modal
    // const handleShowTeacherModal = (tableMeta) => {
    //     setCurrentInstanceNo(tableMeta.rowData[0]);
    //     setCurrentInstance(tableMeta.rowData[5]);
    //     setNewTeacher({...newTeacher, courseInstanceId: tableMeta.rowData[5]});
    //     getCurrentTeachers(tableMeta.rowData[5]);
    //     setShowTeacherModal(true);
    // }

    // close manage teachers modal
    // const closeTeacherModal = () => {
    //     setShowTeacherModal(false);
    //     setTeacherModalError(null);
    //     getData(tableDetails.currentPage, getQuery(history ? ADMIN_COURSE_INSTANCES_FINISHED : ADMIN_COURSE_INSTANCES_ACTIVE));
    // }

    // const [warning, setWarning] = useState(null);

    // get all teachers, teachers who are already added to course instance and list of teacher roles
    // const getCurrentTeachers = async (ci) => {
    //     await Promise.all([
    //         aapi.get("/getcourseinstancedata/" + ci),
    //         aapi.get("/getuniteachers/0"),
    //         aapi.get("/getteacherrole")
    //     ])
    //     .then(res => {
    //         setCurrentTeachers(res[0].data.teachers.sort((a, b) => (a.personalNames+" "+a.familyNames) > (b.personalNames+" "+b.familyNames) ? 1 : -1));
    //         let filter = [];
    //         if(res[1].data.data.length > 0) {
    //             filter = res[1].data.data.filter(
    //                 x => {
    //                     return !res[0].data.teachers.some(
    //                         y => {
    //                             return x.personId === y.personId;
    //                         }
    //                     )
    //                 }
    //             ).sort((a, b) => (a.personalNames+" "+a.familyNames) > (b.personalNames+" "+b.familyNames) ? 1 : -1);
    //         } else {
    //             setWarning("In order to add teachers to the course, they must be added through Teacher management first!");
    //         }
    //         let data = [];
    //         filter.forEach(item => {
    //             data.push({
    //                 label: item.personalNames + " " + item.familyNames,
    //                 value: item.personId 
    //             })
    //         })
    //         setTeachers(data);
    //         let data2 = [];
    //         res[2].data.forEach(item => {
    //             data2.push({
    //                 label: item.teacherRoleName,
    //                 value: item.teacherRoleId
    //             })
    //         })
    //         setTeacherRoles(data2);
    //     })
    //     .catch(err => {
    //         setTeacherModalError(err);
    //     })
    // }

    // get role name for given role id
    // function getRole(id) {
    //     const filter = teacherRoles.filter(item => item.value === id);
    //     return filter[0].label;
    // }

    // add teacher to course instance (POST)
    // const addTeacher = (e) => {
    //     e.preventDefault();

    //     aapi.post("/courseinstanceaddteacher", newTeacher, {
    //         transformRequest: [function (data, headers) {
    //             headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
    //             return JSON.stringify(data);
    //         }]
    //     })
    //     .then(res => {
    //         setTeacherValue("");
    //         setRoleValue("");
    //         getCurrentTeachers(currentInstance);
    //     })
    //     .catch(err => {
    //         setTeacherModalError(err);
    //     })
    // }

    // remove teacher from course instance (DELETE)
    // const removeTeacher = (id) => {

    //     aapi.delete("/courseinstanceremoveteacher/" + currentInstance + "/" + id)
    //         .then(res => {
    //             getCurrentTeachers(currentInstance);
    //         })
    //         .catch(err => {
    //             setTeacherModalError(err);
    //         })
    // }

    // change displayed value in teacher select list
    // const [teacherValue, setTeacherValue] = useState("");
    // const handleSelectTeacherChange = (e) => {
    //     setNewTeacher({...newTeacher, personId: e !== null ? e.value : null});
    //     setTeacherValue(e);
    // }

    // change displayed value in teacher role select list
    // const [roleValue, setRoleValue] = useState("");
    // const handleSelectRoleChange = (e) => {
    //     setNewTeacher({...newTeacher, teacherRoleId: e !== null ? e.value : null});
    //     setRoleValue(e);
    // }

    // min course instance start date control for edit course instance
    const minStartDate = () => {
        let date = new Date(Date.now());
        return new Date(date.setDate(date.getDate() + 1)).toISOString().split("T")[0];
    }

    //min course instance end date control for edit course instance
    const minEndDate = () => {
        if(sendEditData && sendEditData.startDate) {
            let date = new Date(sendEditData.startDate);
            return new Date(date.setDate(date.getDate() + 1)).toISOString().split("T")[0];
        } else {
            let date = new Date(Date.now());
            return new Date(date.setDate(date.getDate() + 2)).toISOString().split("T")[0];
        }
    }

    return (
        <div>
            <CacheProvider value={muiCache}>
                <ThemeProvider theme={customTheme}>
                    <MUIDataTable
                        data={data}
                        columns={columns}
                        options={paginationOptions(tableDetails, getData, setIsLoading, history ? ADMIN_COURSE_INSTANCES_FINISHED : ADMIN_COURSE_INSTANCES_ACTIVE)}
                    />
                </ThemeProvider>
            </CacheProvider>

            <Modal
                size="lg"
                show={showEditModal}
                onHide={closeEditModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="row mb-3">
                            <div className="col-12">
                                <h4><span className="accent">{courseName} 
                                    {
                                        localId !== "null" &&
                                        <> ({localId})</>
                                    }
                                </span></h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>Edit course instance 
                                    {
                                        currentInstanceNo &&
                                        <span className="accent"> ({currentInstanceNo})</span>
                                    }
                                </h4>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        editModalError &&
                        <Error text={editModalError}></Error>
                    }
                    {
                        sendEditData &&
                        <>
                            <form onSubmit={handleEdit}>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <p className="mt-0">* - required field</p>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label>Course instance number</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                min={1}
                                                defaultValue={sendEditData.courseInstanceNo}
                                                onChange={e => setSendEditData({...sendEditData, courseInstanceNo: e.target.value === "" ? null : e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label>Academic year*</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                pattern="[0-9]{4}/[0-9]{4}"
                                                placeholder="yyyy/yyyy"
                                                title="yyyy/yyyy"
                                                required
                                                defaultValue={`${sendEditData.academicYear}/${sendEditData.academicYear+1}`}
                                                onChange={e => setSendEditData({...sendEditData, academicYear: e.target.value.split("/")[0]})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex">
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label>Start date*</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                required
                                                defaultValue={sendEditData.startDate}
                                                onChange={e => setSendEditData({...sendEditData, startDate: e.target.value})}
                                                min={minStartDate()}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <div className="form-group">
                                            <label>End date*</label>
                                            <input
                                                className="form-control"
                                                type="date"
                                                required
                                                defaultValue={sendEditData.endDate}
                                                onChange={e => setSendEditData({...sendEditData, endDate: e.target.value})}
                                                min={minEndDate()}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Quota</label>
                                            <input
                                                className="form-control"
                                                type="number"
                                                min={1}
                                                defaultValue={sendEditData.studentQuota}
                                                onChange={e => setSendEditData({...sendEditData, studentQuota: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Teachers (use <span className="fw-bolder">;</span> as a separator)</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                placeholder="Semicolon separated list of teachers"
                                                defaultValue={sendEditData.teachers}
                                                onChange={e => setSendEditData({...sendEditData, teachers: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-auto d-flex">
                                        <button className="btn w-100 btn-primary" type="submit">Save</button>
                                    </div>
                                </div>
                            </form>
                        </>
                    }
                </Modal.Body>
            </Modal>

            {/* <Modal
                size="lg"
                show={showTeacherModal}
                onHide={closeTeacherModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="row mb-3">
                            <div className="col-12">
                                <h4><span className="accent">{courseName} 
                                    {
                                        localId !== "null" &&
                                        <> ({localId})</>
                                    }
                                </span></h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h4>Manage teachers - <span className="accent">Course instance 
                                    {
                                        currentInstanceNo &&
                                        <> ({currentInstanceNo})</>
                                    }
                                </span></h4>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        teacherModalError &&
                        <Error text={teacherModalError}></Error>
                    }
                    {
                        warning &&
                        <Alert variant="warning text-light" className="p-4">
                            <i className="fe fe-alert-triangle mt-1 me-3"></i>
                            {warning}
                        </Alert>
                    }
                    <form onSubmit={addTeacher}>
                        <div className="row mb-2">
                            <div className="col-12">
                                <p className="mt-0">* - required field</p>
                            </div>
                        </div>
                        <div className="row d-flex">
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label>Teacher*</label>
                                    {
                                        teachers &&
                                        <Select
                                            styles={searchSelectStyle}
                                            value={teacherValue}
                                            isSearchable={true}
                                            options={teachers}
                                            placeholder="Choose"
                                            onChange={e => handleSelectTeacherChange(e)}
                                            required
                                        />
                                    }
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label>Role*</label>
                                    <Select
                                        styles={searchSelectStyle}
                                        value={roleValue}
                                        isSearchable={true}
                                        options={teacherRoles}
                                        placeholder="Choose"
                                        onChange={e => handleSelectRoleChange(e)}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-12 col-md-4">
                                <div className="form-group">
                                    <label className="p-2"></label>
                                    <button className="btn w-100 btn-primary" type="submit">Add</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    <hr className="break"></hr>
                    <div className="row">
                        <div className="col-12">
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={currentTeachers}
                                        columns={addTeacherColumns}
                                        options={options2}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        </div>
                    </div>
                </Modal.Body>
            </Modal> */}
        </div>
    )
}

export default CourseInstanceTable;