import useFetch from '../assets/js/useFetch';
import { getDate } from "../assets/js/shared";
import { CacheProvider } from '@emotion/react';
import { customTheme, muiCache, options2 } from '../assets/js/datatables';
import { ThemeProvider } from '@mui/material';
import MUIDataTable from 'mui-datatables';

const CourseInstanceTable = ({courseId}) => {

	// columns for course instance table
	const columns = [
		{
			name: "courseInstanceId",
			label: "#",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (courseInstanceId, tableMeta) => {
					return (
						<>{tableMeta.rowIndex + 1}.</>
					)
				}
			}
		},
		{
			name: "academicYear",
			label: "Academic Year",
			options: {
				filter: true,
				sort: true,
                customBodyRender: (academicYear) => {
                    return <>{academicYear}/{academicYear+1}</>
                }
			}
		},
		{
			name: "startDate",
			label: "Period",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (startDate, tableMeta) => {
					return (
						<>{getDate(startDate)} - {getDate(tableMeta.rowData[3])}</>
					)
				}
			}
		},
		{
			name: "endDate",
			label: "End date",
			options: {
				filter: false,
				sort: false,
				display: "excluded"
			}
		},
		{
			name: "studentQuota",
			label: "Student quota",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "teachers",
			label: "Teachers",
			options: {
				filter: true,
				sort: false
			}
		}
	];

    const {data: coursesInstList} = useFetch(`/public/getcourseinstances/${courseId}/A`);

    return (
      <div className="row">
			<h4 className="accent">Instances:</h4>
			{
				coursesInstList &&
				<CacheProvider value={muiCache}>
					<ThemeProvider theme={customTheme}>
						<MUIDataTable
							data={coursesInstList.data}
							columns={columns}
							options={options2}
						/>
					</ThemeProvider>
				</CacheProvider>
			}
      </div>
    );
}
 
export default CourseInstanceTable;
