import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { muiCache, customTheme, getQuery, paginationOptions } from "../../assets/js/datatables";
import { useState, useEffect, useCallback } from "react";
import { PENDING_ENROLMENT_STUDENTS, aapi, getCourseStatusBg, getDate } from "../../assets/js/shared";
import Error from "../../components/Error";
import { Tooltip, OverlayTrigger, Badge } from "react-bootstrap";
import { courseStatusShortFO, virtualOrPhysicalFO } from "../../assets/js/filterOptions";
import CourseStatusLegend from "../../components/CourseStatusLegend";

const PendingEnrolment = () => {

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	// columns for course table
	const columns = [
		{
			name: "courseName",
			label: "Name",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (name, tableMeta) => {
					const url = tableMeta.rowData[7];

					if (url) {
						return (
							<a href={url} target="_blank" rel="noreferrer"> {name} <i className="fe fe-external-link"></i></a>
						)
					} else {
						return (
							<span> {name} </span>
						)
					}
				}
			}
		},
		{
			name: "ectsCredits",
			label: "ECTS Credits",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "academicYear",
			label: "Academic Year",
			options: {
				filter: false,
				sort: true,
				display: "excluded",
				customBodyRender: (academicYear) => {
					return <>{academicYear}/{academicYear + 1}</>
				}
			}
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (startDate) => {
					return (
						<span> {getDate(startDate)} </span>
					)
				},
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setStartDate("");
						getData(tableDetails.currentPage, getQuery(PENDING_ENROLMENT_STUDENTS));
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">Start date</label>
								<input
									type="date"
									className="date-filter-input"
									value={startDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setStartDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
			}
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (endDate) => {
					return (
						<span> {getDate(endDate)} </span>
					)
				},
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setEndDate("");
						getData(tableDetails.currentPage, getQuery(PENDING_ENROLMENT_STUDENTS));
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">End date</label>
								<input
									type="date"
									className="date-filter-input"
									value={endDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setEndDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
			}
		},
		{
			name: "lectures",
			label: "Lecture hours",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "exercises",
			label: "Exercise hours",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "uri",
			label: "URI",
			options: {
				filter: false,
				sort: false,
				display: "excluded",
			}
		},
		{
			name: "virtualOrPhysical",
			label: "Mode of attendance",
			options: {
				filter: true,
				filterType: "dropdown",
				filterOptions: { names: virtualOrPhysicalFO },
				sort: true,
				customBodyRender: (mode) => {
					if (mode === "V") {
						return (
							<span> Virtual </span>
						)
					} else {
						return (
							<span> Physical </span>
						)
					}
				}
			}
		},
		{
			name: "courseStatusShortName",
			label: "Status",
			options: {
				filter: true,
				filterType: "dropdown",
				filterOptions: { names: courseStatusShortFO },
				sort: true,
				customBodyRender: (courseStatusShortName, tableMeta) => {
					return (
						<OverlayTrigger
							placement='right'
							overlay={
								<Tooltip id='tooltip-right'>
									{tableMeta.rowData[10]}
								</Tooltip>
							}
						>
							<Badge bg={getCourseStatusBg(tableMeta.rowData[10])} className="badge-lg"> {courseStatusShortName} </Badge>
						</OverlayTrigger>
					)
				}
			}
		},
		{
			name: "courseStatusId",
			label: "Status ID",
			options: {
				filter: false,
				sort: false,
				display: "excluded"
			}
		},
		{
			name: "courseInstanceId",
			label: "Action",
			options: {
				filter: false,
				sort: false,
				download: false,
				display: true,
				customBodyRender: (courseInstanceId, tableMeta) => {
					let formName = "studentEnroll_" + courseInstanceId;
					const statusId = tableMeta.rowData[10];

					if (hasPair(permChanges, statusId, 6)) {
						return (
							<form id={formName} onSubmit={handleCancel}>
								<input name="courseInstanceId" hidden value={courseInstanceId} readOnly></input>
								<button className="btn btn-outline-danger" type="submit">Cancel</button>
							</form>
						)
					}
				}
			}
		},
		{
			name: "orgUnitName",
			label: "Organization name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "superOrgUnitName",
			label: "University name",
			options: {
				filter: true,
				sort: true,
			}
		},
	];

	function hasPair(array, a, b) {
		let pairStatus = false;
		for (let pair of array) {
			if (pair.oldCourseStatusId === a && pair.newCourseStatusId === b) {
				pairStatus = true;
			}
		}
		return pairStatus;
	}

	const [data, setData] = useState(null);
	const [tableDetails, setTableDetails] = useState(null);

	const [permChanges, setPermChanges] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	// get initial data
	const getData = useCallback(async (page, query) => {
		aapi.get(`/getstudentcoursesstatus/Y/${page}${query}`)
			.then(res => {
				setData(res.data.data);
				setTableDetails(res.data.paginationDetails);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}, []);

	useEffect(() => {
		sessionStorage.removeItem(PENDING_ENROLMENT_STUDENTS);
		getData(1, "");
	}, [getData]);

	const getPermChanges = useCallback(() => {
		aapi.get('/getpermittedcoursestatuschange')
			.then(res => {
				setPermChanges(res.data);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}, []);

	useEffect(() => {
		getPermChanges();;
	}, [getPermChanges])

	// cancel course enrolment/drop off (PUT)
	const handleCancel = async (e) => {
		e.preventDefault();

		aapi.put(`/cancelcourseenrollment/${e.target.querySelector("input[name='courseInstanceId']").value}`,
			{
				transformRequest: [function (data, headers) {
					headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

					return JSON.stringify(data);
				}]
			})
			.then(_res => {
				getData(tableDetails.currentPage, getQuery(PENDING_ENROLMENT_STUDENTS));
			})
			.catch(err => {
				setError(err);
			});
	}

	return (
		<div className="card shadow-light-lg mb-6">
			<div className="card-header">
				<h4 className="accent"> Pending enrolment </h4>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col">
						<CacheProvider value={muiCache}>
							<ThemeProvider theme={customTheme}>
								{
									isLoading &&
									<div className="spinner-border text-primary mb-1" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								}
								<CourseStatusLegend />
								{
									error &&
									<Error text={error}></Error>
								}
								{
									data &&
									<MUIDataTable
										data={data}
										columns={columns}
										options={paginationOptions(tableDetails, getData, setIsLoading, PENDING_ENROLMENT_STUDENTS)}
									/>
								}
							</ThemeProvider>
						</CacheProvider>
					</div>
				</div>
			</div>
		</div>
	)

}

export default PendingEnrolment;