import { createTheme } from "@mui/material/styles";
import createCache from "@emotion/cache";

export const muiCache = createCache({
	key: "mui-datatables",
	prepend: true
});


export const customTheme = createTheme({
	components: {
		MuiPaper: {
			styleOverrides: {
				root: { boxShadow: "none" }
			},
		},
		// MuiSvgIcon: {
		//   styleOverrides: {
		//     root: {fill: "#555555" }
		//   },
		// },
		MUIDataTableToolbar: {
			styleOverrides: {
				icon: {
					'&:hover': {
						color: "#c00000"
					},
				},
				iconActive: {
					color: "#c00000",
				},
				titleText: {
					fontWeight: "700",
					fontSize: '1.1625em',
				}
			},
		},
		MUIDataTableViewCol: {
			styleOverrides: {
				title: {
					fontWeight: "700"
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					fontFamily: 'HKGroteskPro, serif',
					fontSize: '1.0625em',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					fontFamily: 'HKGroteskPro, serif',
					fontSize: '0.8625em',
					padding: '12px',
					minWidth: '120px'
				},
			},
		},
		MuiTablePagination: {
			styleOverrides: {
				selectLabel: {
					fontFamily: 'HKGroteskPro, serif',
					fontSize: '1.0625em',
					margin: "unset",
				},
				displayedRows: {
					fontFamily: 'HKGroteskPro, serif',
					fontSize: '1.0625em',
					margin: "unset",
				},
				menuItem: {
					fontFamily: 'HKGroteskPro, serif',
					"&.Mui-selected": {
						backgroundColor: "#c0000011",
					},
					"&.Mui-selected:hover": {
						backgroundColor: "#c0000033",
					},
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					fontFamily: 'HKGroteskPro, serif',
					"&.Mui-selected": {
						backgroundColor: "#c0000011",
					},
					"&.Mui-selected:hover": {
						backgroundColor: "#c0000033",
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					fontFamily: 'HKGroteskPro, serif',
				},
			},
		},
		MuiInput: {
			styleOverrides: {
				root: {
					fontFamily: 'HKGroteskPro, serif',
					"&:after": {
						borderBottomColor: "#c00000",
					},
				},
			},
		},
		MuiInputLabel: {
			styleOverrides: {
				root: {
					"&.Mui-focused": {
						color: "#c00000",
					},
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					color: "#222222",
					fontFamily: 'HKGroteskPro, serif',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					"&.Mui-disabled": {
						"color": "rgba(0, 0, 0, 0.26)"
					}
				}
			},
		},
		MuiCheckbox: {
			styleOverrides: {
				root: {
					"&.Mui-checked": {
						"color": "#212121"
					}
				}
			},
		},
		MUIDataTableHeadCell: {
			styleOverrides: {
				data: {
					fontWeight: "700",
					fontFamily: 'HKGroteskPro, serif',
					fontSize: '1.0625em',
					padding: '12px',
				},
				fixedHeader: {
					fontWeight: "700"
				},
			},
		},
		MUIDataTableFilter: {
			styleOverrides: {
				root: {
					fontFamily: 'HKGroteskPro, serif',
				}
			}
		}
	}
});


export const options = {
	filterType: 'textField',
	responsive: 'vertical',
	download: false,
	print: false,
	selectableRowsHideCheckboxes: true,
	rowsPerPageOptions: [10]
};

export const options2 = {
	responsive: 'vertical',
	search: false,
	filter: false,
	download: false,
	print: false,
	selectableRowsHideCheckboxes: true,
	rowsPerPageOptions: [10]
};

export const paginationOptions = (tableDetails, getData, setLoading, sessionKey) => {
	let ts = null;

	let options = {
		filterType: 'textField',
		responsive: 'vertical',
		download: false,
		print: false,
		selectableRowsHideCheckboxes: true,

		// filteri
		confirmFilters: true,
		customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
			return (
				<div style={{ marginTop: "40px", fontFamily: "HKGroteskPro, serif" }}>
					<button className="btn btn-primary" onClick={() => applyNewFilters()}>Apply</button>
				</div>
			);
		},

		// parametri tablice
		serverSide: true,
		count: tableDetails ? tableDetails.count : 0,
		page: tableDetails ? tableDetails.currentPage-1 : 0,
		rowsPerPage: tableDetails ? tableDetails.size : 10,
		rowsPerPageOptions: [10],
		onTableChange: (action, tableState) => {
			if(action === "changePage" || action === "onFilterDialogClose" || action === "sort") {
				getData(tableState.page+1, constructQuery(tableState, sessionKey));
				setLoading(true);
			}
			ts = tableState;
		},
		onFilterChange: (column, filterList, type) => {
			if (type === "chip") {
				getData(ts.page+1, constructQuery(ts, sessionKey));
				setLoading(true);
			}
			if(type === "custom" && String(column).toLocaleLowerCase().includes("date")) {
				getData(ts.page+1, constructQuery(ts, sessionKey));
				setLoading(true);
			}
		},
		searchProps: {
			onKeyUp: (e) => {
				if(e.key === "Enter") {
					getData(ts.page + 1, constructQuery(ts, sessionKey));
				}
			}
		},
		onSearchClose: () => {
			ts.searchText = null;
			getData(ts.page + 1, constructQuery(ts, sessionKey));
		}
	}

	return options;
}

export const constructQuery = (tableState, sessionKey) => {
	let result = "?";
	if (tableState) {
		tableState.columns.forEach((column, i) => {
			if(column.filter) {
				if (tableState.filterList[i][0] !== undefined && tableState.filterList[i][0] !== null) {
					result += `${column.name}=${tableState.filterList[i][0]}&`;
				}
			}
		})
		if(tableState.sortOrder.name !== undefined && tableState.sortOrder.name !== null) {
			result += `sortBy=${tableState.sortOrder.name}&sortDir=${tableState.sortOrder.direction}&`;
		}
		if(tableState.searchText !== undefined && tableState.searchText !== null) {
			result += `search=${tableState.searchText}&`;
		}
	}
	sessionStorage.setItem(sessionKey, result === "?" ? "" : result.substring(0, result.length - 1));
	return result === "?" ? "" : result.substring(0, result.length - 1);
}

export const getQuery = (sessionKey) => {
	let query = sessionStorage.getItem(sessionKey);
	return query !== null ? query : "";
}