import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { customTheme, muiCache, paginationOptions } from "../../../assets/js/datatables";
import { OUTGOING_MOBILITY_ACTIVE, OUTGOING_MOBILITY_STUDENT_COURSES, aapi } from "../../../assets/js/shared";
import Error from "../../../components/Error";
import { useNavigate } from "react-router-dom";

const AdminOutgoingActive = () => {

    // columns for student table
    const columns = [
        {
            name: "studentId",
            label: "Student ID",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "studentNameFirst",
            label: "First name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentNameLast",
            label: "Last name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "eMail",
            label: "eMail",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (eMail) => {
                    if(eMail !== null && eMail !== "") {
                        return <a href={`mailto:${eMail}`}> {eMail} </a>
                    } else {
                        return <span></span>
                    }
                }
            }
        },
        {
            name: "esi",
            label: "ESI",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "coursesInfo",
            label: "Courses",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (coursesInfo, tableMeta) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-info">Inspect</Tooltip>
                            }
                        >
                            <button 
                                className="btn" 
                                aria-label="More details"
                                onClick={() => viewStudentCourses(tableMeta)}
                                >
                                    <i className="fe fe-eye"></i>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // get initial data
    const getData = useCallback((page, query) => {
        aapi.get(`/outgoingmobilityactive/${page}${query}`)
        .then(res => {
            setData(res.data.data);
            setTableDetails(res.data.paginationDetails);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        sessionStorage.removeItem(OUTGOING_MOBILITY_ACTIVE);
        getData(1, "");
    }, [getData]);

    const navigate = useNavigate();

    const viewStudentCourses = (tableMeta) => {
        let object = {
            studentId: tableMeta.rowData[0],
            student: `${tableMeta.rowData[1]} ${tableMeta.rowData[2]}`,
            source: "Outgoing active",
            pending: false,
            info: false,
            active: true,
            finished: false,
            history: false
        }
        sessionStorage.setItem(OUTGOING_MOBILITY_STUDENT_COURSES, JSON.stringify(object));
        navigate("studentcourses");
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Outgoing active students</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        {
                            loading &&
                            <div className="spinner-border text-primary mb-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        {
                            error &&
                            <Error text={error}></Error>
                        }
                        {
                            data &&
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable 
                                        data={data}
                                        columns={columns}
                                        options={paginationOptions(tableDetails, getData, setLoading, OUTGOING_MOBILITY_ACTIVE)}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminOutgoingActive;