import logo from '../assets/img/yufe-symbol-big.png';
import { Link } from 'react-router-dom';

const Error403Page = () => {

  return (
    <div className="container d-flex flex-column">
        <div
          className="row align-items-center justify-content-center gx-0 min-vh-100"
        >
          <div
            className="col-8 col-md-6 col-lg-5 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
          >

            <img
              src={logo}
              alt="YUFE logo"
              className="img-fluid"
            />
          </div>
          <div
            className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
          >
            <h1 className="display-3 fw-bold text-center">Sorry, you are no longer an active user of Virtual Campus.</h1>

            <p className="mb-5 text-center text-muted">
              To return to part of virtual campus open to public, click on the button below.
            </p>

            <div className="text-center">
            <Link to="/" className="btn btn-primary-soft me-1 mb-1 lift"> 
              Home <span className="fe fe-home ms-1"></span>
            </Link>
            </div>
          </div>

        </div>

      </div>
  );
}
 
export default Error403Page;