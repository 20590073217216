import { CacheProvider } from "@emotion/react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { customTheme, muiCache, paginationOptions } from "../../../assets/js/datatables";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { OUTGOING_MOBILITY_INFO, OUTGOING_MOBILITY_PENDING, OUTGOING_MOBILITY_STUDENT_COURSES, aapi } from "../../../assets/js/shared";
import Error from "../../../components/Error";

const AdminOutgoingPendingTable = ({ counter, pending }) => {

    // columns for student table
    const columns = [
        {
            name: "studentId",
            label: "Student ID",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "studentNameFirst",
            label: "First name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentNameLast",
            label: "Last name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "eMail",
            label: "eMail",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (eMail) => {
                    if (eMail !== null && eMail !== "") {
                        return <a href={`mailto:${eMail}`}> {eMail} </a>
                    } else {
                        return <span></span>
                    }
                }
            }
        },
        {
            name: "esi",
            label: "ESI",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "coursesInfo",
            label: "Courses",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (coursesInfo, tableMeta) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-info">Inspect</Tooltip>
                            }
                        >
                            <button
                                className="btn"
                                aria-label="More details"
                                onClick={() => viewStudentCourses(tableMeta)}
                            >
                                <i className="fe fe-eye"></i>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getData = useCallback((page, query) => {
        setLoading(true);
        let param = pending ? "Y" : "N";
        aapi.get(`/outgoingmobilitypending/${param}/${page}${query}`)
            .then(res => {
                setData(res.data.data);
                setTableDetails(res.data.paginationDetails);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [pending])

    useEffect(() => {
        sessionStorage.removeItem(pending ? OUTGOING_MOBILITY_PENDING : OUTGOING_MOBILITY_INFO);
        getData(1, "");
        if (pending) {
            counter();
        }
    }, [getData, pending, counter]);

    const navigate = useNavigate();

    const viewStudentCourses = (tableMeta) => {
        let object = {
            studentId: tableMeta.rowData[0],
            student: `${tableMeta.rowData[1]} ${tableMeta.rowData[2]}`,
            source: pending ? "Outgoing pending" : "Outgoing info",
            pending: pending ? true : false,
            info: pending ? false : true,
            active: false,
            finished: false,
            history: false
        }
        sessionStorage.setItem(OUTGOING_MOBILITY_STUDENT_COURSES, JSON.stringify(object));
        navigate("studentcourses");
    }

    return (
        <div className="row">
            <div className="col">
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    data &&
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={customTheme}>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={paginationOptions(tableDetails, getData, setLoading, pending ? OUTGOING_MOBILITY_PENDING : OUTGOING_MOBILITY_INFO)}
                            />
                        </ThemeProvider>
                    </CacheProvider>
                }
            </div>
        </div>
    )
}

export default AdminOutgoingPendingTable;