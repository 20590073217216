import { useState, useEffect, useCallback } from "react";
import { getDate } from "../../assets/js/shared";
import { aapi } from '../../assets/js/shared';
import Error from "../../components/Error";

const StudentCard = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const [errorDownloading, setErrorDownloading] = useState(null);

  const [data, setData] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // get initial data (card status and available languages list)
  const getData = useCallback(async () => {
    await Promise.all([
      aapi.get(`/getcardstatus`),
      aapi.get("/getlanguages")
    ])
    .then(res => {
      setData(res[0].data);
      setLanguages(res[1].data.sort((a, b) => a.languageName > b.languageName ? 1 : -1));
    })
    .catch(err => {
      setError(err);
    })
    .finally( () =>{
      setIsLoading(false);
    })
  }, []);

  useEffect(() => {
    getData();
  }, [getData])

  // generate/download virtual student card (GET)
  const handleDownload = async(e) => {
    e.preventDefault();
    setIsDownloading(true);

    aapi.get(`/generatevirtualcard/${e.target.querySelector("select[name='languageLabel']").value}`)
    .then((response) => {
      getData();

      let a = document.createElement("a");
      a.href = `${response.data}`;
      a.download = 'studentCard.png';
      a.click();
    })
    .catch(err => {
      setErrorDownloading(err);
    })
    .finally( () =>{
      setIsDownloading(false);
    })
  }

  return (
  <div className="card card-bleed shadow-light-lg mb-6">
    <div className="card-body">
      { error &&
        <Error text={error}></Error>
      }
      { isLoading &&
          <div className="spinner-border text-primary mb-1" role="status">
              <span className="visually-hidden">Loading...</span>
          </div>
      }
      { data &&
        <div>
          <div className="row">
            <div className="col" data-aos="fade-up">
              <h4 className="fw-bold"> Card status: <span className="accent text-uppercase"> {data.deactivatedDate ? "not active" : "active"} </span> </h4>
              </div>
          </div>
          <div className="row">
            <div className="col" data-aos="fade-up">
              <hr className="break"></hr>
          
              <p className="m-0"> 
                <small> If you have active card status you can download your virtual student card here. </small> <br />
                <small> If your card is not active, and you want to activate it, contact your home university's administrator. </small>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col" data-aos="fade-up">
              <hr className="break"></hr>

              <span className="fw-bold">Generated date:</span> { getDate(data.generatedDate) } <br />
              <span className="fw-bold">Deactivated date:</span> { getDate(data.deactivatedDate) } <br />
            </div>
          </div>
          <div className="row">
            <div className="col" data-aos="fade-up">
              <hr className="break"></hr>
              {
                data.deactivatedDate ?
                  <span className="d-inline-block" tabIndex="0" data-toggle="tooltip" title="Your student card is not active.">
                    <button className="btn btn-dark mb-1 dark" disabled>Download</button>
                  </span>
                : <form onSubmit={handleDownload}>
                    <div class="form-group row">
                      <div class="col">
                        {
                          languages &&
                          <select
                            className="form-select"
                            name="languageLabel"
                            defaultValue="en"
                          >
                            {
                              languages.map(item => {
                                return (
                                  <option key={item.languageId} value={item.languageLabel}>{item.languageName}</option>
                                )
                              })
                            }
                          </select>
                        }
                      </div>
                      <div class="col">
                        <button className="btn btn-success mb-1 text-text-black">Download</button>
                        { isDownloading &&
                          <div className="spinner-grow text-success mb-1 mx-1" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        }
                      </div>
                    </div>
                  </form>
              }
            </div>
          </div>
        </div>
      }
      {
        !data && !error &&
        <div>
          <div className="row">
            <div className="col" data-aos="fade-up">
              <h4 className="fw-bold"> Card status: <span className="accent text-uppercase"> not generated </span> </h4>
              </div>
          </div>
          <div className="row">
            <div className="col" data-aos="fade-up">
              <hr className="break"></hr>
          
              <p className="m-0"> 
                <small> Generate your virtual student card here to be able to show that you are a YUFE student. </small> <br />
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col" data-aos="fade-up">
              <hr className="break"></hr>
              <form onSubmit={handleDownload}>
                <div class="form-group row">
                  <div class="col">
                    {
                      languages &&
                      <select
                        className="form-select"
                        name="languageLabel"
                        defaultValue="en"
                      >
                        {
                          languages.map(item => {
                            return (
                              <option key={item.languageId} value={item.languageLabel}>{item.languageName}</option>
                            )
                          })
                        }
                      </select>
                    }
                  </div>
                  <div class="col">
                    <button className="btn btn-success mb-1 text-black">Generate</button>
                  { isDownloading &&
                    <div className="spinner-grow text-success mb-1 mx-1" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  }
                  </div>
                </div>
                
              </form>
            </div>
          </div>
        </div>
      }
      {
        errorDownloading &&
        <Error text={errorDownloading}></Error>
      }
      
    </div>
  </div>
  );
}
 
export default StudentCard;
