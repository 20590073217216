import { CacheProvider } from "@emotion/react"
import { ThemeProvider } from "@mui/material"
import MUIDataTable from "mui-datatables"
import { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { customTheme, muiCache, paginationOptions } from "../../../assets/js/datatables"
import { INCOMING_MOBILITY_HISTORY, INCOMING_MOBILITY_HISTORY_DATES, INCOMING_MOBILITY_STUDENTS, aapi, getDate } from "../../../assets/js/shared"
import Error from "../../../components/Error"
import { useNavigate } from "react-router-dom"

const AdminIncomingHistory = () => {

    // columns for course table
    const columns = [
        {
            name: "courseInstanceId",
            label: "Course instance ID",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "localCourseId",
            label: "Local course ID",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "courseName",
            label: "Course",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "academicYear",
            label: "Academic year",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (academicYear) => {
                    return <>{academicYear}/{academicYear+1}</>
                }
            }
        },
        {
            name: "startDate",
            label: "Start date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (startDate) => {
                    return (getDate(startDate))
                }
            }
        },
        {
            name: "endDate",
            label: "End date",
            options: {
                filter: false,
                sort: true,
                customBodyRender: (endDate) => {
                    return (getDate(endDate))
                }
            }
        },
        {
            name: "ectsCredits",
            label: "ECTS",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentsEnrolledCourses",
            label: "Students",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (students, tableMeta) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-info">Inspect</Tooltip>
                            }
                        >
                            <button 
                                className="btn" 
                                aria-label="More details"
                                onClick={() => viewStudentCourses(tableMeta)}
                                >
                                    <i className="fe fe-eye"></i>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);

    const [dates, setDates] = useState(JSON.parse(sessionStorage.getItem(INCOMING_MOBILITY_HISTORY_DATES)));

    // call get data for chosen start date interval
    const handleSearch = (e) => {
        e.preventDefault();
        getData(1, "");
    }

    // get data
    const getData = (page, query) => {
        setLoading(true);
        aapi.get(`/incomingmobilityhistory/${dates.start}/${dates.end}/${page}${query}`)
        .then(res => {
            setData(res.data.data);
            setTableDetails(res.data.paginationDetails);
            sessionStorage.setItem(INCOMING_MOBILITY_HISTORY_DATES, JSON.stringify(dates));
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        sessionStorage.removeItem(INCOMING_MOBILITY_HISTORY);
        let exists = JSON.parse(sessionStorage.getItem(INCOMING_MOBILITY_HISTORY_DATES));
        if(exists !== null && exists !== "null") {
            getData(1, "");
        }
        // eslint-disable-next-line
    }, []);

    const navigate = useNavigate();

    const viewStudentCourses = (tableMeta) => {
        let object = {
            courseInstanceId: tableMeta.rowData[0],
            course: tableMeta.rowData[2],
            source: "Incoming history",
            pending: false,
            info: false,
            history: true
        }
        sessionStorage.setItem(INCOMING_MOBILITY_STUDENTS, JSON.stringify(object));
        navigate("coursestudents");
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Incoming history</h4>
            </div>
            <div className="card-body">
                <form onSubmit={handleSearch}>
                    <div className="row mb-3">
                        <div className="col-12">
                            <h5><b>Course start date between:</b></h5>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label>Interval start</label>
                                <input 
                                    type="date"
                                    className="form-control"
                                    required
                                    value={dates !== null ? dates.start : null}
                                    onChange={e => setDates({...dates, start: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className=" col-12 col-md-4">
                            <div className="form-group">
                                <label>Interval end</label>
                                <input 
                                    type="date"
                                    className="form-control"
                                    required
                                    value={dates !== null ? dates.end : null}
                                    onChange={e => setDates({...dates, end: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="form-group">
                                <label className="p-2"></label>
                                <button className="btn w-100 btn-primary">Search</button>
                            </div>
                        </div>
                    </div>
                </form>
                <hr className="break"></hr>
                <div className="row">
                    <div className="col-12">
                        {
                            loading &&
                            <div className="spinner-border text-primary mb-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        {
                            error &&
                            <Error text={error}></Error>
                        }
                        {
                            data &&
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={data}
                                        columns={columns}
                                        options={paginationOptions(tableDetails, getData, setLoading, INCOMING_MOBILITY_HISTORY)}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminIncomingHistory;