import logo from '../assets/img/yufe-symbol-big.png';
import { useParams } from 'react-router-dom';
import useFetch from '../assets/js/useFetch';
import { getDate } from "../assets/js/shared";
import Error from '../components/Error';

const Verify = () => {
  const { cardNo } = useParams();

  const { data, isLoading, error } = useFetch(`/public/virtualcardvalid/${cardNo}`);

  function getIsValid(isValid) {
    if(isValid){
      return <span className="text-success fw-bolder">YES</span>;
    } else {
      return <span className="text-danger fw-bolder">NO</span>;
    }
  }

  return (
    <div className="container d-flex flex-column">
        <div
          className="row align-items-center justify-content-center gx-0 min-vh-100"
        >
          <div
            className="col-8 col-md-6 col-lg-5 offset-md-1 order-md-2 mt-auto mt-md-0 pt-8 pb-4 py-md-11"
          >

            <img
              src={logo}
              alt="YUFE logo"
              className="img-fluid"
            />
          </div>
          <div
            className="col-12 col-md-5 col-lg-4 order-md-1 mb-auto mb-md-0 pb-8 py-md-11"
          >
          { error &&
            <Error text={error} statusText={error.response.statusText}></Error>
          }
          { isLoading &&
            <div className="spinner-border text-primary mb-1" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          }
          { data &&
            <>
            <h1 className="mb-0 fw-bold text-center">YUFE VIRTUAL CARD</h1>

 
            <p className="mb-6 text-center text-muted">
              Student card information
            </p>

            <ul className="list mb-6">
              <li className="list-item">
                <label className="fw-bold me-1"> First name: </label>
                 {data.personalNames}
              </li>
              <li className="list-item">
                <label className="fw-bold me-1"> Last name: </label>
                {data.familyNames}
              </li>
              <li className="list-item">
                <label className="fw-bold me-1">Card generated:</label>
                {getDate(data.generatedDate)}
              </li>
              <li className="list-item">
                <label className="fw-bold me-1">Valid: </label>
                {getIsValid(data.isValid)}
              </li>
            </ul>
          </>}

            <p className="mb-0 fs-sm text-center text-muted">
              For more information visit <a href="https://wiki.srce.hr/display/UNICVC/UNIC_Virtual+Campus" target="_blank" rel="noreferrer">YUFE help pages</a>.
            </p>
          </div>
        </div>

      </div>
  );
}
 
export default Verify;