import { Route, Routes } from "react-router-dom";
import useFetch from "../../assets/js/useFetch";
import ErrorPage from "../ErrorPage";
import ResearcherHeader from "./components/ResearcherHeader";
import { getNameWithTitle } from "../../assets/js/shared";
import ResearcherNavbar from "./components/ResearcherNavbar";
import ResearcherSidebar from "./components/ResearcherSidebar";
import ResearcherHome from "./ResearcherHome";
import ResearcherOrcidProfile from "./ResearcherOrcidProfile";
import ResearchersConnect from "./ResearchersConnect";
import ModesOfCooperationCatalogue from "./ModesOfCooperationCatalogue";
import TeachingKeywordCatalogue from "./TeachingKeywordCatalogue";
import ScienceKeywordCatalogue from "./ScienceKeywordCatalogue";
import ThematicLinesCatalogue from "./ThematicLinesCatalogue";
import ResearcherInterests from "./ResearcherInterests";
import ResearcherConnectDetails from "./ResearcherConnectDetails";
import DisciplinesCatalogue from "./DisciplinesCatalogue";

const Researcher = () => {
    const { data: user, error } = useFetch('/public/getcurrentuser');

    return (
        <div>
            {
                error &&
                <ErrorPage></ErrorPage>
            }
            {
                user &&
                <>
                    <ResearcherNavbar userName={getNameWithTitle(user)} userOrgUnit={user.orgUnitName} />
                    <ResearcherHeader userName={getNameWithTitle(user)} userOrgUnit={user.orgUnitName} />

                    <main className="pb-8 pb-md-11 mt-md-n6">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12 col-md-3">
                                    <div className="card card-bleed border-bottom border-bottom-md-0 shadow-light-lg">
                                        <div className="collapse d-md-block" id="sidenavCollapse">
                                            <ResearcherSidebar />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-9 ps-md-0 pe-md-4">
                                    <Routes>
                                        <Route path="/" element={<ResearcherHome />} />
                                        <Route path="/personal-info" element={<ResearcherOrcidProfile user={user} />} />
                                        <Route path="/personal-interests" element={<ResearcherInterests />} />
                                        <Route path="/connect">
                                            <Route index element={<ResearchersConnect />} />
                                            <Route path="researcher-details" element={<ResearcherConnectDetails />} />
                                        </Route>
                                        <Route path="/thematicLines" element={<ThematicLinesCatalogue />} />
                                        <Route path="/disciplines" element={<DisciplinesCatalogue />} />
                                        <Route path="/fieldsofresearch" element={<ScienceKeywordCatalogue />} />
                                        <Route path="/teachingkeywords" element={<TeachingKeywordCatalogue />} />
                                        <Route path="/cooperationkeywords" element={<ModesOfCooperationCatalogue />} />
                                    </Routes>
                                </div>
                            </div>
                        </div>
                    </main>
                </>
            }
        </div>
    )
}

export default Researcher;