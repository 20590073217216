import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { muiCache, customTheme, paginationOptions } from "../../assets/js/datatables";
import Modal from 'react-bootstrap/Modal';
import CourseInstanceTable from "../../components/CourseInstanceTable";
import { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Error from "../../components/Error";
import { COURSES_PUBLIC, aapi } from "../../assets/js/shared";

const Courses = () => {

	// columns for course table
	const columns = [
		{
			name: "courseName",
			label: "Name",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (name, tableMeta) => {
					const url = tableMeta.rowData[5];

					if (url && url.startsWith("http")) {
						return (
							<a href={url} target="_blank" rel="noreferrer"> {name} <i className="fe fe-external-link"></i></a>
						)
					} else {
						return (
							<span> {name} </span>
						)
					}
				}
			}
		},
		{
			name: "descriptionCourse",
			label: "Description",
			options: {
				filter: true,
				sort: false,
			}
		},
		{
			name: "ectsCredits",
			label: "ECTS Credits",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "lectures",
			label: "Lecture hours",
			options: {
				filter: false,
				sort: true,
				display: "excluded"
			}
		},
		{
			name: "exercises",
			label: "Exercise hours",
			options: {
				filter: false,
				sort: true,
				display: "excluded"
			}
		},
		{
			name: "uri",
			label: "URI",
			options: {
				filter: false,
				sort: false,
				display: "excluded",
			}
		},
		{
			name: "prerequisites",
			label: "Prerequisites",
			options: {
				filter: true,
				sort: false,
				display: "excluded"
			}
		},
		{
			name: "orgUnitName",
			label: "Organization name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "superOrgUnitName",
			label: "University name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "iscedFieldCode",
			label: "ISCED code",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "iscedFieldName",
			label: "ISCED name",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "courseId",
			label: "Details",
			options: {
				download: false,
				filter: false,
				sort: false,
				customBodyRender: (courseId, tableMeta) => {
					return (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip id="tooltip-inspect">Inspect</Tooltip>
							}
						>
							<button className="btn btn-sm btn-rounded-circle btn-outline-primary" aria-label="More details" onClick={() => handleShow(courseId, tableMeta)}>
								<i className="fe fe-plus"></i>
							</button>
						</OverlayTrigger>
					)
				}
			}
		},
	];

	const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

	const getData = useCallback((page, query) => {
		aapi.get(`/public/getcourses/${page}${query}`)
			.then(res => {
				setData(res.data.data);
				setTableDetails(res.data.paginationDetails);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}, [])

	useEffect(() => {
		sessionStorage.removeItem(COURSES_PUBLIC);
        getData(1, "");
	}, [getData]);

	// course details modal hooks
	const [show, setShow] = useState(false);
	const [courseId, setCourseId] = useState(0);
	const [modalName, setModalName] = useState(null);
	const [coursePrerequisites, setCoursePrerequisites] = useState(null);
	const [lecture, setLecture] = useState(null);
	const [excercise, setExcercise] = useState(null);

	// show course details modal
	const handleShow = (id, tableMeta) => {
		setCoursePrerequisites(tableMeta.rowData[6]);
		setModalName(`${tableMeta.rowData[0]} (${id})`);
		setCourseId(id);
		setLecture(tableMeta.rowData[3]);
		setExcercise(tableMeta.rowData[4]);
		setShow(true);
	}

	// close course details modal
	const handleClose = () => {
		setShow(false);
	}

	return (
		<div className="pt-8 pb-11 pt-md-9 pb-md-12 px-12">
			<CacheProvider value={muiCache}>
				<ThemeProvider theme={customTheme}>
					{
						isLoading &&
						<div className="spinner-border text-primary mb-1" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					}
					{
                        error &&
                        <Error text={error}></Error>
                    }
					{
						data &&
						<MUIDataTable
							title={"Courses"}
							data={data}
							columns={columns}
							options={paginationOptions(tableDetails, getData, setIsLoading, COURSES_PUBLIC)}
						/>
					}
				</ThemeProvider>
			</CacheProvider>

			<Modal
				size="lg"
				show={show}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Course info - <span className="accent">{modalName}</span></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="row">
						<h4 className="accent">Prerequisites:</h4>
						<p>
							{coursePrerequisites ? coursePrerequisites : "There are no course prerequisites."}
						</p>
					</div>
					<div className="row">
						<h4 className="accent">Lecture hours:</h4>
						<p>
							{lecture ? lecture : "No info."}
						</p>
					</div>
					<div className="row">
						<h4 className="accent">Excercise hours:</h4>
						<p>
							{excercise ? excercise : "No info."}
						</p>
					</div>
					<CourseInstanceTable courseId={courseId} />

				</Modal.Body>
			</Modal>

		</div>
	)
}

export default Courses;