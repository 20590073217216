import { Link } from "react-router-dom";

const StudentHome = () => {
  return (
<div className="card card-bleed shadow-light-lg mb-6">
  <div className="card-header">
    <h4 className="accent"> Welcome to Virtual Campus </h4>
  </div>
  <div className="card-body">
    <div className="row justify-content-center">
      <div className="col-12 col-md-10 col-lg-7 text-center" data-aos="fade-up">

      <p className="fs-lg text-muted mb-6 mb-md-8">
          Through this virtual campus you can enrol in various courses and activate/view your YUFE Student Card.
          Through this virtual campus you can activate and view your YUFE Student Card.

        </p>
        <Link to="enrolcourses" className="btn btn-primary-soft me-1 mb-1 lift">
          Enrolment <span className="fe fe-log-in ms-1"></span>
        </Link>
        <Link to="studentcard" className="btn btn-primary me-1 mb-1 lift"> 
          Student card <span className="fe fe-credit-card ms-1"></span>
        </Link>

        <p className="fs-lg text-muted mt-6 mb-6 mb-md-8">
          After, you can use this site to monitor your active courses or to browse through your course history.
        </p>

        <Link to="activecourses" className="btn btn-primary-soft me-1 mb-1 lift">
          Active Courses <span className="fe fe-book ms-1"></span>
        </Link>
        <Link to="coursehistory" className="btn btn-primary-soft me-1 mb-1 lift"> 
          Course history <span className="fe fe-archive ms-1"></span>
        </Link>

      </div>
    </div>
  </div>
</div>
  );
}
 
export default StudentHome;
