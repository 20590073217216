import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { muiCache, customTheme, paginationOptions } from "../../assets/js/datatables";
import Modal from 'react-bootstrap/Modal';
import JPCoursesTable from "../../components/JPCoursesTable";
import { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { JOINT_PROGRAMMES_PUBLIC, aapi } from "../../assets/js/shared";
import Error from "../../components/Error";
import { programmeLevelFO } from "../../assets/js/filterOptions";

const JointProgrammes = () => {

	// columns for joint programme
	const columns = [
		{
			name: "programmeName",
			label: "Programme name",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (name, tableMeta) => {
					const url = tableMeta.rowData[2];

					if (url && url.startsWith("http")) {
						return (
							<a href={url} target="_blank" rel="noreferrer"> {name} <i className="fe fe-external-link"></i></a>
						)
					} else {
						return (
							<span> {name} </span>
						)
					}
				}
			}
		},
		{
			name: "programmeLevelName",
			label: "Programme level",
			options: {
				filter: true,
				filterType: "dropdown",
                filterOptions: { names: programmeLevelFO },
				sort: true,
			}
		},
		{
			name: "programmeUrl",
			label: "URL",
			options: {
				filter: false,
				sort: false,
				display: "excluded",
			}
		},
		{
			name: "adminOrgUnitName",
			label: "Administrative Organization name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "superOrgUnitName",
			label: "Administrative University name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "partners",
			label: "Partners",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (partners) => {
					if (partners.length > 0) {
						return (
							partners.map(p => {
								return (<>{p.partnerOrgUnitName} ({p.locationName}); </>)
							})
						)
					} else {
						return (<>-</>)
					}
				}
			}
		},
		{
			name: "programmeId",
			label: "Courses",
			options: {
				download: false,
				filter: false,
				sort: false,
				customBodyRender: (programmeId, tableMeta) => {
					return (
						<OverlayTrigger
							placement="top"
							overlay={
								<Tooltip id="tooltip-inspect">Inspect</Tooltip>
							}
						>
							<button className="btn btn-sm btn-rounded-circle btn-outline-primary" aria-label="Courses for JP" onClick={() => handleShow(programmeId, tableMeta.rowData[0])}>
								<i className="fe fe-plus"></i>
							</button>
						</OverlayTrigger>
					)
				}
			}
		},
	];

	const [data, setData] = useState(null);
	const [tableDetails, setTableDetails] = useState(null);

	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	const getData = useCallback((page, query) => {
		aapi.get(`/public/getjointprogrammes/${page}${query}`)
			.then(res => {
				setData(res.data.data);
				setTableDetails(res.data.paginationDetails);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}, [])

	useEffect(() => {
		sessionStorage.removeItem(JOINT_PROGRAMMES_PUBLIC);
        getData(1, "");
	}, [getData]);

	// joint programme details modal hooks
	const [show, setShow] = useState(false);
	const [jointProgrammeId, setJointProgrammeId] = useState(0);
	const [modalName, setModalName] = useState("");

	// close joint programme modal
	const handleClose = () => {
		setShow(false);
	}

	// show joint programme modal
	const handleShow = (id, name) => {
		setModalName(`${name} (${id})`);
		setJointProgrammeId(id);
		setShow(true);
	}

	return (
		<div className="pt-8 pb-11 pt-md-9 pb-md-12 px-12">
			<CacheProvider value={muiCache}>
				<ThemeProvider theme={customTheme}>
					{
						isLoading &&
						<div className="spinner-border text-primary mb-1" role="status">
							<span className="visually-hidden">Loading...</span>
						</div>
					}
					{
                        error &&
                        <Error text={error}></Error>
                    }
					{
						data &&
						<MUIDataTable
							title={"Joint Programmes"}
							data={data}
							columns={columns}
							options={paginationOptions(tableDetails, getData, setIsLoading, JOINT_PROGRAMMES_PUBLIC)}
						/>
					}
				</ThemeProvider>
			</CacheProvider>

			<Modal
				size="lg"
				show={show}
				onHide={handleClose}
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Courses for Joint Programme - <span className="accent">{modalName}</span></Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<JPCoursesTable jointProgrammeId={jointProgrammeId} />
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default JointProgrammes;