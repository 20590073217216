import { useState, useEffect } from 'react';
import { aapi } from './shared';

function useFetch(url) {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getData = async () => {
      await aapi.get(url)
      .then(res => {
        setData(res.data);
      })
      .catch(err => {
        setError(err);
      })
      .finally( () =>{
        setIsLoading(false);
      })
    };

    getData();
  }, [url])

  return { data, isLoading, error };

}

export default useFetch;