import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { aapi, keywordType } from "../../../assets/js/shared";

const KeywordsComponent = ({ data, type, getData, setError }) => {

    const removeKeyword = (id) => {
        let url = "";
        if (type === keywordType.thematicLines) {
            url = "/removethematicline";
        } else if (type === keywordType.cooperationKeywords) {
            url = "/removecooperationkeyword";
        } else if (type === keywordType.scienceKeywords) {
            url = "/removesciencekeyword";
        } else if (type === keywordType.teachingKeywords) {
            url = "/removeteachingkeyword";
        }
        aapi.delete(`${url}/${id}`)
            .then(res => {
                getData();
            })
            .catch(err => {
                setError(err);
            })
    }

    return (
        <div class="d-flex flex-wrap">
            {
                data.length > 0 &&
                data.map(k => {
                    return (
                        <div className="border border-radius m-1 ps-4">
                            {k.keywordName}
                            <span>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-edit">Remove</Tooltip>
                                    }
                                >
                                    <button className="btn btn-sm btn-light text-danger" onClick={() => removeKeyword(k.keywordId)}>
                                        <i className="fe fe-trash"></i>
                                    </button>
                                </OverlayTrigger>
                            </span>
                        </div>
                    )
                })
            }
            {
                data.length <= 0 &&
                <p className="fst-italic text-black-50">None selected</p>
            }
        </div>
    )
}

export default KeywordsComponent;