import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { customTheme, getQuery, muiCache, paginationOptions } from "../../../assets/js/datatables";
import { PERSON_REGISTRATION_STAFF, PERSON_REGISTRATION_STUDENTS, aapi, getDate } from "../../../assets/js/shared";
import Error from "../../../components/Error";
import { genderFO } from "../../../assets/js/filterOptions";

const PersonRegistrationTable = ({staff, counter, data, getData, tableDetails, setIsLoading}) => {

	const [dateOfBirth, setDateOfBirth] = useState(null);

	// columns for person table
    const columns = [
		{
			name: "personalNames",
			label: "First name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "familyNames",
			label: "Last name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "eMail",
			label: "eMail",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (email) => {
					const href = "mailto:" + email;

					return (
						<a href={href}> {email} </a>
					)
				}
			}
		},
		{
			name: "roleName",
			label: "Role",
			options: {
				filter: true,
				sort: true,
				display: staff ? true : "excluded",
			}
		},
		{
			name: "esi",
			label: "ESI",
			options: {
				filter: staff ? false : true,
				sort: true,
				display: staff ? "excluded" : true,
			}
		},
		{
			name: "birthDate",
			label: "Date of birth",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (birthDate) => {
					return (
						<span>{getDate(birthDate)}</span>
					)
				},
				customFilterListOptions: {
                    update: (filterList, filterPos, index) => {
                        setDateOfBirth("");
                        getData(tableDetails.currentPage, getQuery(staff ? PERSON_REGISTRATION_STAFF : PERSON_REGISTRATION_STUDENTS));
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    }
                },
                filterOptions: {
                    display: (filterList, onChange, index, column) => {
                        return (
							<>
								<label className="date-filter-label">Date of birth</label>
								<input
									type="date"
									className="date-filter-input"
									value={dateOfBirth}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setDateOfBirth(e.target.value);
									}}
								/>
							</>
                        );
                    }
                }
			}
		},
		{
			name: "gender",
			label: "Gender",
			options: {
				filter: true,
				filterType: "dropdown",
                filterOptions: { names: genderFO },
				sort: true,
				customBodyRender: (gender) => {
					if(gender === "M") {
						return <span>Male</span>
					} else if(gender === "F") {
						return <span>Female</span>
					} else {
						return <span>Other</span>
					}
				}
			}
		},
		{
			name: "personRegistrationId",
			label: "Actions",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (personRegistrationId) => {
					return (
						<>
							<div class="btn-group">
								<OverlayTrigger
									placement='top'
									overlay={
										<Tooltip id='tooltip-edit'>Edit</Tooltip>
									}
								>
									<button className="btn text-info" onClick={() => handleShow(personRegistrationId)}>
										<i class="fe fe-edit"></i>
									</button>
								</OverlayTrigger>
							</div>
							<div class="btn-group">
								<form onSubmit={handleConfirm}>
									<input name="personRegistrationId" hidden value={personRegistrationId} readOnly></input>
									<OverlayTrigger
										placement='top'
										overlay={
											<Tooltip id='tooltip-confirm'>Confirm</Tooltip>
										}
									>
										<button className="btn text-success" type="submit">
											<i class="fe fe-check-square"></i>
										</button>
									</OverlayTrigger>
								</form>
							</div>
						</>
					);
				}
			}
		},
		{
			name: "orgUnitName",
			label: "Organization name",
			options: {
				filter: true,
				sort: true,
			}
		}
	];

	const [error, setError] = useState(null);

	// confirm person registration
	const handleConfirm = async(e) => {
		e.preventDefault();

		aapi.put(`/confirmpersonregistration/${e.target.querySelector("input[name='personRegistrationId']").value}`, {
			transformRequest: [function (data, headers) {
				headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
				
				return JSON.stringify(data);
			}]
		})
		.then(res => {
			getData(tableDetails.currentPage, getQuery(staff ? PERSON_REGISTRATION_STAFF : PERSON_REGISTRATION_STUDENTS));
			counter();
		})
		.catch(err => {
			setError(err);
		});
	}

	// edit person modal hooks
	const [show, setShow] = useState(false);
	const [person, setPerson] = useState(null);
	const [modalError, setModalError] = useState(null);
	const [modalLoading, setModalLoading] = useState(null);
	const [id, setId] = useState(null);

	// show edit person modal
	const handleShow = (id) => {
		setModalLoading(true);
		setId(id);
		aapi.get(`/getpersonregistration/${id}`)
		.then(results => {
			setPerson(results.data);
		})
		.catch(err => {
			setModalError(err);
		})
		.finally(() => {
			setModalLoading(false);
		})
		setShow(true);
	}

	// close edit person modal
	const handleClose = () => {
		setShow(false);
		setPerson(null);
		setModalError(null);
	}

	// edit person (PUT)
	const handleSubmit = (e) => {
		e.preventDefault();

		const sendData = {
			personalNames: person.personalNames,
			familyNames: person.familyNames,
			titleBeforeName: person.titleBeforeName,
			titleAfterName: person.titleAfterName,
			eMail: person.eMail,
			birthDate: person.birthDate,
			gender: person.gender
		};
		
		aapi.put(`/editpersonregistration/${id}`, sendData, {
			transformRequest: [function (data, headers) {
				headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
				
				return JSON.stringify(data);
			}]
		})
		.then(res => {
			handleClose();
			getData(tableDetails.currentPage, getQuery(staff ? PERSON_REGISTRATION_STAFF : PERSON_REGISTRATION_STUDENTS));
		})
		.catch(err => {
			setModalError(err);
		});
	}

    return (
        <>
            {
                error &&
                <Error text={error}></Error>
            }
            {
                data &&
                <CacheProvider value={muiCache}>
                    <ThemeProvider theme={customTheme}>
                        <MUIDataTable
                            data={data}
                            columns={columns}
                            options={paginationOptions(tableDetails, getData, setIsLoading, staff ? PERSON_REGISTRATION_STAFF : PERSON_REGISTRATION_STUDENTS)}
                        />
                    </ThemeProvider>
                </CacheProvider>
            }
            <Modal
                size="lg"
                show={show}
                onHide={handleClose}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit person information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        modalLoading &&
                        <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                    {
                        modalError &&
                        <Error text={modalError}></Error>
                    }
                    {
                        person &&
                        <form onSubmit={handleSubmit}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <p className="mt-0">* - required field</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">First name*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            defaultValue={person.personalNames}
                                            onChange={(e) => setPerson({...person, personalNames: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Last name*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            defaultValue={person.familyNames}
                                            onChange={(e) => setPerson({...person, familyNames: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Title before name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={person.titleBeforeName}
                                            onChange={(e) => setPerson({...person, titleBeforeName: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Title after name</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            defaultValue={person.titleAfterName}
                                            onChange={(e) => setPerson({...person, titleAfterName: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
							<div className="row">
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Date of birth*</label>
                                        <input
                                            className="form-control"
                                            type="date"
											required
                                            defaultValue={person.birthDate.split("T")[0]}
                                            onChange={(e) => setPerson({...person, birthDate: e.target.value})}
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    <div className="form-group">
                                        <label className="form-label">Gender*</label>
                                        <select
											className="form-select"
											required
											onChange={(e) => setPerson({...person, gender: e.target.value})}
											defaultValue={person.gender}
										>
											<option value="F">Female</option>
											<option value="M">Male</option>
											<option value="O">Other</option>
										</select>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="form-label">Email*</label>
                                <input
                                    className="form-control"
                                    type="email"
                                    required
                                    defaultValue={person.eMail}
                                    onChange={(e) => setPerson({...person, eMail: e.target.value})}
                                />
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-auto d-flex">
                                    <button className="btn w-100 btn-primary" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PersonRegistrationTable;