import MatchPercentageView from "./MatchPercentageView";
import { keywordType } from "../../../assets/js/shared";

const KeywordsComparison = ({ personInterests, userInterests, match, type }) => {

    const getTitle1 = () => {
        if (type === keywordType.thematicLines) return "My thematic lines";
        else if (type === keywordType.cooperationKeywords) return "My modes of cooperation";
        else if (type === keywordType.disciplines) return "My disciplines";
        else if (type === keywordType.fieldsOfResearch) return "My fields of research";
        else if (type === keywordType.teachingKeywords) return "My fields of teaching";
        else if (type === keywordType.iscedField) return "My ISCED fields";
    }
    
    const getTitle2 = () => {
        if (type === keywordType.thematicLines) return "Co-researcher's thematic lines";
        else if (type === keywordType.cooperationKeywords) return "Co-researcher's modes of cooperation";
        else if (type === keywordType.disciplines) return "Co-researcher's disciplines";
        else if (type === keywordType.fieldsOfResearch) return "Co-researcher's fields of research";
        else if (type === keywordType.teachingKeywords) return "Co-researcher's fields of teaching";
        else if (type === keywordType.iscedField) return "Co-researcher's ISCED fields";
    }

    const checkIfKeywordsMatch = (keyword, user) => {
        if (user) {
            let filtered = userInterests.filter(ui => ui.keywordName === keyword);
            if (filtered.length !== 0) return true;
            else return false;
        } else {
            return personInterests.includes(keyword);
        }
    }

    return (
        <>
            <div className="row">
                <div className="col-sm-5">
                    <p className="fw-bolder">{getTitle1()}:</p>
                    {
                        userInterests.length <= 0 &&
                        <p className="fst-italic text-black-50">Not available</p>
                    }
                    {
                        userInterests.length > 0 && userInterests.map(k => {
                            return <><span className={checkIfKeywordsMatch(k.keywordName, false) ? "text-success" : "text-black-50"}>{k.keywordName}</span><br /></>
                        })
                    }
                </div>
                <div className="col-sm-5">
                    <p className="fw-bolder">{getTitle2()}:</p>
                    {
                        personInterests.length <= 0 &&
                        <p className="fst-italic text-black-50">Not available</p>
                    }
                    {
                        personInterests.length > 0 && personInterests.map(k => {
                            return <><span className={checkIfKeywordsMatch(k, true) ? "text-success" : "text-black-50"}>{k}</span><br /></>
                        })
                    }
                </div>
                <div className="col-sm-2 text-center my-auto">
                    <MatchPercentageView data={match} />
                </div>
            </div>
        </>
    )
}

export default KeywordsComparison;