const AccessibilityStatement = () => {
    return (
        <div  className="pt-8 pb-11 pt-md-9 pb-md-12 py-lg-10 bg-gray-200 bg-between">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-9" data-aos="fade-up">

                        <h1 className="fw-bold mb-6 pb-3">Accessibility Statement</h1>
                        <p>
                            University Computing Centre (SRCE) is committed to making its websites accessible, in accordance with the Law on Accessibility of Web Site and Programming Solutions for Mobile Devices of Public Sector Bodies.<br />
                            This accessibility statement applies to <a href="https://unic.srce.hr">UNIC Portal</a>.
                        </p>

                        <h2>Compliance status</h2>
                        <p>This website is partially compliant with <a href="https://rdd.gov.hr/UserDocsImages//dokumenti//Smjernice-digitalne-pristupac%CC%8Cnosti-ver.-1.1.pdf">Guidelines for ensuring digital accessibility v1.1</a> due to the exemptions listed below.</p>

                        <h2>Non-accessible content</h2>
                        <p>
                            The content listed below is non-accessible for the following reason(s):<br />
                            (a) non-compliance with the Law on Accessibility of Web Site and Programming Solutions for Mobile Devices of Public Sector Bodies:<br />
                            Accessibility widget does not support dyslexia font.
                        </p>

                        <h2>Preparation of this accessibility statement</h2>
                        <p>
                            This statement was prepared on November 2, 2022.<br />
                            Method used for the preparation of this accessibility statement is a self-assessment done by the public sector body.<br />
                            The statement was last reviewed on November 2, 2022. 
                        </p>

                        <h2>Feedback and contact information</h2>
                        <p>
                            If the user needs information that is published on the UNIC Portal in an inaccessible form or has noticed inconsistencies that are not covered by the description from the Non-accessible content, the user can ask for it:
                        </p>
                        <ul>
                            <li>by e-mail <a href="mailto:pristupacnost@srce.hr">pristupacnost@srce.hr</a>,</li>
                            <li>by SRCE Helpdesk phone +385 1 616 5165,</li>
                            <li>by sending mail at SRCE, Josipa Marohnića 5, 10000 Zagreb, Croatia.</li>
                        </ul>
                        <p>
                            University Computing Centre (SRCE) is obliged to respond to the user's inquiry, notification or request within 15 days from the date of receipt or, within the same period, inform him of a subsequent deadline in which it will respond, providing a detailed explanation of the reasons that require a delay.
                        </p>

                        <h2>Enforcement procedure</h2>
                        <p>
                            The institution responsible for monitoring the compliance of websites and programming solutions for mobile devices of public sector bodies with accessibility requirements and supervising the implementation of the Law on Accessibility of Web Site and Programming Solutions for Mobile Devices of Public Sector Bodies is the Information Commissioner of the Republic of Croatia.<br/>
                            If the user of the UNIC portal is not satisfied with the answers to the inquiry, or the notices inconsistencies, or believes that University Computing Centre (SRCE) has not complied with the request for accessible information, user can contact the <a href="https://pristupinfo.hr/?lang=en">Information Commissioner</a> by phone number +385 1 4609 041 or by e-mail: <a href="mailto:pristupacnost@pristupinfo.hr">pristupacnost@pristupinfo.hr</a>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccessibilityStatement;