import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useCallback, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { customTheme, muiCache, paginationOptions } from "../../assets/js/datatables";
import { PARTNER_JOINT_PROGRAMME_ADMIN, aapi } from "../../assets/js/shared";
import Error from "../../components/Error";
import JPCoursesTable from "../../components/JPCoursesTable";
import { programmeLevelFO } from "../../assets/js/filterOptions";

const PartnerJointProgramme = () => {

    const columns = [
        {
            name: "programmeName",
            label: "Programme name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (name, tableMeta ) => {
                    const url = tableMeta.rowData[1];
            
                    if(url) {
                        return (
                            <a href={url} target="_blank" rel="noreferrer"> {name} <i className="fe fe-external-link"></i></a>
                        )
                    } else {
                        return (
                            <span> {name} </span>
                        )
                    }
                }
            }
        },
        {
            name: "programmeUrl",
            label: "Programme URL",
            options: {
                filter: false, 
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "programmeLevelName",
            label: "Programme level",
            options: {
                filter: true,
                filterType: 'dropdown',
                filterOptions: { names: programmeLevelFO },
                sort: true
            }
        },
        {
            name: "adminOrgUnitName",
            label: "Administrative organization",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "superOrgUnitName",
            label: "Administrative University name",
            options: {
                filter: false,
                sort: false
            }
        },
        {
            name: "partners",
            label: "Partners",
            options: {
                filter: false, 
                sort: false,
                customBodyRender: (partners) => {
                    if(partners.length > 0) {
                        return (
                            partners.map(p => {
                                return (<>{p.partnerOrgUnitName}; </>)
                            })
                        )
                    } else {
                        return (<>-</>)
                    }
                }
            }
        },
        {
            name: "programmeId",
            label: "Courses",
            options: {
                filter: false, 
                sort: false,
                customBodyRender: (programmeId, tableMeta) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-inspect">Inspect</Tooltip>
                            }
                        >
                            <button className="btn btn-sm btn-rounded-circle btn-outline-primary" aria-label="Courses for JP" onClick={() => handleShow(programmeId, tableMeta.rowData[0] )}>
                                <i className="fe fe-plus"></i>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const getData = useCallback((page, query) => {
        aapi.get(`/getprogrammesofpartner/${page}${query}`)
        .then(res => {
            setData(res.data.data);
            setTableDetails(res.data.paginationDetails);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        sessionStorage.removeItem(PARTNER_JOINT_PROGRAMME_ADMIN);
        getData(1, "");
    }, [getData]);

    // joint programme details modal hooks
    const [show, setShow] = useState(false);
    const [jointProgrammeId, setJointProgrammeId] = useState(0);
    const [modalName, setModalName] = useState("");

    // close joint programme modal
    const handleClose = () => {
        setShow(false);
    }

    // show joint programme modal
    const handleShow = ( id, name ) => {
        setModalName(name);
        setJointProgrammeId(id);
        setShow(true);
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Partner programmes</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        {
                            loading &&
                            <div className="spinner-border text-primary mb-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        {
                            error &&
                            <Error text={error}></Error>
                        }
                        {
                            data &&
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={data}
                                        columns={columns}
                                        options={paginationOptions(tableDetails, getData, setLoading, PARTNER_JOINT_PROGRAMME_ADMIN)}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        }
                    </div>
                </div>
            </div>

            <Modal
                size="lg" 
                show={show} 
                onHide={handleClose} 
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Courses for Joint Programme - <span className="accent">{modalName}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <JPCoursesTable jointProgrammeId={jointProgrammeId}/>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PartnerJointProgramme;