const ResearcherHome = () => {
    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Welcome to Virtual Campus</h4>
            </div>
            <div className="card-body">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7 text-center" data-aos="fade-up">
                        <p className="fs-lg text-muted mb-6 mb-md-8">
                            Through this virtual campus, you can see your ORCiD information and browse through
                            all your scientific works which are published on OpenAIRE and ORCiD as well as other people's.
                            You can also find researchers with similar interests and connect with them. The matching algorithm
                            is based on the selected keywords from the provided catalogues. Keyword selection can be modified at any time.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ResearcherHome;