import { CacheProvider } from "@emotion/react";
import { customTheme, getQuery, muiCache, paginationOptions } from "../../assets/js/datatables";
import { ThemeProvider } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { RESEARCH_OUTPUTS_PUBLIC, aapi, getDate } from "../../assets/js/shared";
import { Alert, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import Error from "../../components/Error";

const Publications = () => {

    const [dateOfAcceptance, setDateOfAcceptance] = useState(null);

    const columns = [
        {
            name: "mainTitle",
            label: "Original title",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "translatedTitle",
            label: "Translated title",
            options: {
                filter: false,
                sort: true,
                display: false
            }
        },
        {
            name: "creators",
            label: "Creators",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "dateOfAcceptance",
            label: "Date of acceptance",
            options: {
                filter: true,
                filterType: "custom",
                sort: true,
                customBodyRender: (dateOfAcceptance) => {
                    return <span>{getDate(dateOfAcceptance)}</span>
                },
                customFilterListOptions: {
                    update: (filterList, filterPos, index) => {
                        setDateOfAcceptance("");
                        getData(tableDetails.currentPage, getQuery(RESEARCH_OUTPUTS_PUBLIC));
                        filterList[index].splice(filterPos, 1);
                        return filterList;
                    }
                },
                filterOptions: {
                    display: (filterList, onChange, index, column) => {
                        return (
                            <>
                                <label className="date-filter-label">Date of acceptance</label>
                                <input
                                    type="date"
                                    className="date-filter-input"
                                    value={dateOfAcceptance}
                                    required
                                    onChange={e => {
                                        filterList[index] = [e.target.value];
                                        onChange(filterList[index], index, column);
                                        setDateOfAcceptance(e.target.value);
                                    }}
                                />
                            </>
                        );
                    }
                }
            }
        },
        {
            name: "resourceType",
            label: "Type",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "publisher",
            label: "Publisher",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "description",
            label: "Description",
            options: {
                filter: false,
                sort: false,
                display: "excluded",
                customBodyRender: (description, tableMeta) => {
                    return (
                        <div>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-inspect">Display</Tooltip>
                                }
                            >
                                <button className="btn btn-sm btn-rounded-circle btn-outline-primary" onClick={() => showDescription(tableMeta.rowData)}>
                                    <i className="fe fe-plus"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        },
        {
            name: "countryName",
            label: "Country",
            options: {
                filter: true,
                sort: true,
                display: false
            }
        },
        {
            name: "uri",
            label: "Links",
            options: {
                filter: false,
                sort: false,
                display: false,
                customBodyRender: (uris) => {
                    let uri = uris.split(";");
                    return (
                        uri.map(u => {
                            return <><a href={u} target="_blank" rel="noreferrer">{u} <i className="fe fe-external-link"></i></a><br /></>
                        })
                    )
                }
            }
        },
        {
            name: "doiId",
            label: "Details",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (doiId, tableMeta) => {
                    return (
                        <div>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-inspect">Inspect</Tooltip>
                                }
                            >
                                <button className="btn btn-sm btn-rounded-circle btn-outline-primary" onClick={() => showDescription(tableMeta.rowData)}>
                                    <i className="fe fe-plus"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const getData = useCallback((page, query) => {
        aapi.get(`/public/getresearch/${page}${query}`)
            .then(res => {
                setData(res.data.data);
                setTableDetails(res.data.paginationDetails);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setIsLoading(false);
            })
    }, [])

    useEffect(() => {
        sessionStorage.removeItem(RESEARCH_OUTPUTS_PUBLIC);
        getData(1, "");
    }, [getData]);

    const [showDescriptionModal, setShowDescriptionModal] = useState(false);
    const [researchDetails, setResearchDetails] = useState(null);

    const showDescription = (rowData) => {
        setShowDescriptionModal(true);
        setResearchDetails({
            mainTitle: rowData[0],
            countryName: rowData[7],
            description: rowData[6],
            links: rowData[8].split(";")
        })
    }

    const closeDescriptionModal = () => {
        setShowDescriptionModal(false);
        setResearchDetails(null);
    }

    return (
        <div className="pt-8 pb-11 pt-md-9 pb-md-12 px-12">
            <Alert variant="secondary text-light" className="p-4">
                <i className="fe fe-info mt-1 me-3"></i>
                These research outputs are harvested from OpenAIRE Graph Dataset, which harvests the data from local universities' repositories.
                If any of the data presented is not correct, please make sure it is corrected in your local university repository, and the correct entry will propagated to OpenAIRE and consequently to YUFE Virtual Campus.
            </Alert>
            <CacheProvider value={muiCache}>
                <ThemeProvider theme={customTheme}>
                    {
                        isLoading &&
                        <div className="spinner-border text-primary mb-1" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    }
                    {
                        error &&
                        <Error text={error}></Error>
                    }
                    {
                        data &&
                        <MUIDataTable
                            title={"Research outputs"}
                            data={data}
                            columns={columns}
                            options={paginationOptions(tableDetails, getData, setIsLoading, RESEARCH_OUTPUTS_PUBLIC)}
                        />
                    }
                </ThemeProvider>
            </CacheProvider>

            <Modal
                size="xl"
                show={showDescriptionModal}
                onHide={closeDescriptionModal}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Research details
                        {
                            researchDetails &&
                            <span className="accent"> ({researchDetails.mainTitle})</span>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        researchDetails &&
                        <>
                            <h4 className="fw-bolder">Country</h4>
                            {
                                researchDetails.countryName &&
                                <span>{researchDetails.countryName}</span>
                            }
                            {
                                !researchDetails.countryName &&
                                <span className="fst-italic">Not available</span>
                            }
                            <h4 className="fw-bolder mt-5">Research description</h4>
                            {
                                researchDetails.description &&
                                <span>{researchDetails.description}</span>
                            }
                            {
                                !researchDetails.description &&
                                <span className="fst-italic">Not available</span>
                            }
                            <h4 className="fw-bolder mt-5">Links</h4>
                            <span>
                                {
                                    researchDetails.links && researchDetails.links.map(l => {
                                        return <><a href={l} target="_blank" rel="noreferrer">{l} <i className="fe fe-external-link"></i></a><br /></>
                                    })
                                }
                            </span>
                            {
                                !researchDetails.links &&
                                <span className="fst-italic">Not available</span>
                            }
                        </>
                    }

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Publications;