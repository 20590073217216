import { useState, useEffect } from "react";
import { aapi, PERSON_REGISTRATION, UNICUserConsts } from "../../assets/js/shared";
import packageJson from '../../../package.json';
import Error from "../../components/Error";

const RegistrationForm = () => {
  // form fields
  const [person, setPerson] = useState(null);

  // fetch
  const [orgUnitList, setOrgUnitList] = useState(null);
  const [roleList, setRoleList] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  // additional variables
  const [allRoles, setAllRoles] = useState(null);
  const [tempRole, setTempRole] = useState(null);
  const [roleValue, setRoleValue] = useState("");

  const validate = () => {
    return person.roleId > 0 && person.orgUnitId > 0;
  };

  // get initial data (user data, organization units and roles)
  // filter orgUnits
  useEffect(() => {

    const getData = async () => {
    	await Promise.all([
    		aapi.get('/public/getcurrentuser'),
    		aapi.get('/getorgunits'),
    		aapi.get('/getroles')
    	])
    	.then(results => {
        if(sessionStorage.getItem(PERSON_REGISTRATION) === null || sessionStorage.getItem(PERSON_REGISTRATION) === "null") {
          setPerson(results[0].data);
        }
        else {
          setPerson(JSON.parse(sessionStorage.getItem(PERSON_REGISTRATION)));
        }
    		
    		let homeOrgDomain = results[0].data.homeOrgDomain;

    		results[1].data.sort((orgUnitA, orgUnitB) => (orgUnitA.orgUnitName > orgUnitB.orgUnitName) ? 1 : -1);

    		let filterOrgUnit = results[1].data.filter(orgUnit => {
    			return orgUnit.orgUnitDnsDomain === homeOrgDomain;
    		});

    		setOrgUnitList(filterOrgUnit);

    		setTempRole(results[0].data.roleId);
    		setAllRoles(results[2].data);
    	})
    	.catch(err => {
    		setError(err);
    	})
    	.finally(() => {
    		setIsLoading(false);
    	})
    };

    getData();
  }, [])

  useEffect(() => {
    if(person !== null) {
      sessionStorage.setItem(PERSON_REGISTRATION, JSON.stringify(person));
    }
  }, [person]);

  useEffect(() => {
    let savedData = JSON.parse(sessionStorage.getItem(PERSON_REGISTRATION));
    if(savedData !== null && savedData.orgUnitId > 0 && orgUnitList !== null) {
      orgUnitChange(savedData.orgUnitId);
      window.orcidWidget();
    }
    // eslint-disable-next-line
  }, [orgUnitList])

  // submit registration form (POST)
  const handleSubmit = (e) => {
    e.preventDefault();

    const url = (parseInt(person.roleId) === UNICUserConsts.roleStudent) ? '/registerstudent' : '/pushpersonregistration';
    const csrfToken = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

    person.orcid = extractOrcid();
    aapi.post(url, {
      ...person
    },
      {
        transformRequest: [function (data, headers) {
          headers['X-CSRF-TOKEN'] = csrfToken;

          return JSON.stringify(data);
        }]
      }
    )
    .then(_res => {
      //if successful
      sessionStorage.removeItem(PERSON_REGISTRATION);
      window.location.href = '/' + packageJson.homepage + '/registration/success';
    })
    .catch(err => {
      setError(err);
    });
  }

  const extractOrcid = () => {
    let el = document.getElementById("orcidId");
    if(el !== null) {
      let split = el.value.split("/");
      return split[split.length-1];
    }
    return null;
  }

  // called on orgUnit selection - filters roles based on selected orgUnit
  const orgUnitChange = value => {
    setRoleValue("");
    setPerson({ ...person, orgUnitId: value });

    let filterOrgUnit = orgUnitList.filter(unit => unit.orgUnitId === parseInt(value));

    // filter roles 
    let filterRoles = allRoles.filter(role => {
      if (tempRole === UNICUserConsts.roleStudent) {
        return role.roleId === UNICUserConsts.roleStudent;
      } else if (tempRole === UNICUserConsts.tempRoleAdmin) {
        if (filterOrgUnit[0].orgUnitTypeId === 1) {
          return role.roleId === 1 || role.roleId === 2 || role.roleId === 3 || role.roleId === 8;
        } else if (filterOrgUnit[0].orgUnitTypeId === 2 || filterOrgUnit[0].orgUnitTypeId === 5) {
          return role.roleId === 2 || role.roleId === 8;
        } else {
          return role.roleId === 3 || role.roleId === 8;
        }
      } else {
        return true;
      }
    });

    // sort roles by role ID
    filterRoles = filterRoles.sort((a, b) => a.roleId > b.roleId ? 1 : -1);
    setRoleList(filterRoles);
  }

  return (
    <div>
      <header className="bg-gray-200 pt-9 pb-11 d-none d-md-block">
        <div className="container-md">
          <div className="row align-items-center" id="main">
            <div className="col">
              <h1 className="fw-bold mb-2">Registration</h1>
              {
                person &&
                <p className="fs-lg mb-0">
                  Registration for <span className="text-reset">{person.email}</span>
                </p>
              }
              {
                isLoading &&
                <div className="spinner-border text-primary mb-1" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              }
              {
                error &&
                <Error text={error}></Error>
              }
            </div>
          </div>
        </div>
      </header>

      <main className="pb-8 pb-md-11 mt-md-n6">
        <div className="container-md">
          {
            orgUnitList && person &&
            <div className="row">
              <div className="col-12">
                <div className="card card-bleed shadow-light-lg mb-6">
                  <div className="card-header">
                    <h4 className="mb-0 accent">Basic information</h4>
                  </div>
                  <div className="card-body">

                    <form onSubmit={handleSubmit}>
                      <div className="row mb-2">
                        <div className="col-12">
                          <p className="mt-0">* - required field</p>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Name*</label>
                            <input
                              className="form-control"
                              type="text"
                              required
                              value={person.personalNames}
                              onChange={(e) => setPerson({ ...person, personalNames: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Last name*</label>
                            <input
                              className="form-control"
                              type="text"
                              required
                              value={person.familyNames}
                              onChange={(e) => setPerson({ ...person, familyNames: e.target.value })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Title before name</label>
                            <input
                              className="form-control"
                              type="text"
                              value={person.titleBeforeName}
                              onChange={(e) => setPerson({ ...person, titleBeforeName: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Title after name</label>
                            <input
                              className="form-control"
                              type="text"
                              value={person.titleAfterName}
                              onChange={(e) => setPerson({ ...person, titleAfterName: e.target.value })}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Date of birth*</label>
                            <input
                              className="form-control"
                              type="date"
                              required
                              defaultValue={person.birthDate}
                              onChange={(e) => setPerson({ ...person, birthDate: e.target.value })}
                            />
                          </div>
                        </div>
                        <div className="col-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Gender*</label>
                            <select
                              className="form-select"
                              required
                              onChange={(e) => setPerson({ ...person, gender: e.target.value })}
                              value={person.gender !== null ? person.gender : ""}
                            >
                              <option value="" selected disabled hidden>---</option>
                              <option value="F">Female</option>
                              <option value="M">Male</option>
                              <option value="O">Other</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Email*</label>
                        <input
                          className="form-control"
                          type="email"
                          required
                          value={person.email}
                          onChange={(e) => setPerson({ ...person, email: e.target.value })}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Organization unit*</label>
                        <select
                          className="form-select form-select-lg"
                          required
                          onChange={(e) => orgUnitChange(e.target.value)}
                          value={person.orgUnitId > 0 ? person.orgUnitId : ""}
                        >
                          <option key="0" value="" selected disabled hidden>---</option>
                          {
                            orgUnitList.map((orgUnit) => {
                              return (
                                <option key={orgUnit.orgUnitId} value={orgUnit.orgUnitId}>{orgUnit.orgUnitName}</option>
                              )
                            })
                          }
                        </select>
                      </div>
                      <div className="form-group">
                        <label className="form-label">Role*</label>
                        <select
                          className="form-select form-select-lg"
                          required
                          onChange={e => {
                            setPerson({ ...person, roleId: e.target.value });
                            setRoleValue(e.target.value);
                            window.orcidWidget();
                          }}
                          value={parseInt(person.roleId) > 0 ? person.roleId : roleValue}
                          disabled={roleList === null ? true : false}
                        >
                          <option key="0" value="" selected disabled hidden>---</option>
                          {
                            roleList &&
                            roleList.map((role) => {
                              if (role.forRegistration === "Y") {
                                return (
                                  <option key={role.roleId} value={role.roleId}>{role.roleName}</option>
                                )
                              }
                              else {
                                return (<></>)
                              }
                            })
                          }
                        </select>
                      </div>
                      {
                        person.roleId === "8" &&
                        <div className="form-group mt-5">
                          <div id="orcidWidget" data-clientid="APP-GL1W3UWPAE6NK2EM" data-env="production" data-redirecturi={window.location.href}></div>
                        </div>
                      }
                      <div className="row">
                        <div className="col-12 col-md-auto">
                          <button disabled={!validate()} className="btn w-100 btn-primary" type="submit">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </main>
    </div>
  );
}

export default RegistrationForm;
