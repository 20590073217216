import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import FinishedCourses from "./FinishedCourses";
import CoursesStatus from "./CoursesStatus";
import CourseStatusLegend from '../../components/CourseStatusLegend';

const CourseHistory = () => {

  return (
    <div className="card card-bleed shadow-light-lg mb-6">
      <div className="card-header">
        <h4 className="accent"> Course history </h4>
      </div>
      <div className="card-body">
        <div className="mb-3">
          <CourseStatusLegend />
        </div>
        <Tabs
          defaultActiveKey="finishedCourses"
          id="courseHistory"
          className="mb-3"
        >
          <Tab eventKey="finishedCourses" title="Finished courses">
            <div className="row">
              <div className="col">
                <FinishedCourses />
              </div>
            </div>
          </Tab>
          <Tab eventKey="coursesStatus" title="Courses status">
            <div className="row">
              <div className="col">
                <CoursesStatus />
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  )
}

export default CourseHistory;