import axios from "axios";

// date format used in getDate function
export const dateOptions = Object.freeze({
  'day': '2-digit',
  'month': 'short',
  'year': 'numeric',
});

// timestamp format used in getTimestamp function
export const timestampOptions = Object.freeze({
  'day': '2-digit',
  'month': 'short',
  'year': 'numeric',
  'hour': 'numeric',
  'minute': 'numeric'
})

// constants from UNICUser class
export const UNICUserConsts = Object.freeze({
  roleStudent: 4,
  tempRoleAdmin: -1,
  tempRoleStudentAdmin: -4,
  roleResearcher: 8
});

// formats date like dd.mm.yyyy.
export const getDate = ( datum ) => {
  if ( datum ){
    return new Date(datum).toLocaleString('en-UK', dateOptions);
  } else {
    return "-";
  }
};

// formats timestamp
export const getTimestamp = ( datum) => {
  if(datum) {
    return new Date(datum).toLocaleString("en-UK", timestampOptions);
  } else {
    return "-";
  }
}

// formats name
export const getNameWithTitle = ( user ) => {
  
  if ( user.titleBeforeName && user.titleAfterName ){
    return user.titleBeforeName + " " + user.personalNames + " " + user.familyNames + ", " + user.titleAfterName;
  } else if ( user.titleBeforeName ) {
    return user.titleBeforeName + " " + user.personalNames + " " + user.familyNames;
  } else if ( user.titleAfterName ) {
    return user.personalNames + " " + user.familyNames + ", " + user.titleAfterName;
  } else {
    return user.personalNames + " " + user.familyNames;
  }
};

// configuration shared over all API calls
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.headers.get['Content-Type'] = 'application/json';


export const aapi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
  //baseURL: "https://webdev.unic.srce.hr/unicvc/api/"
});

// we could also do it like this and not create an instance
// axios.defaults.baseURL = 'https://webdev.unic.srce.hr/api/';

// styling for react-select component
export const searchSelectStyle = {
  control: (provided, state) => ({
      ...provided,
      border: state.isFocused ? '1px solid #cb636a' : '1px solid #d9e2ef',
      padding: 7,
      boxShadow: 'none',
      '&:hover': {
        border: state.isFocused ? '1px solid #cb636a' : '1px solid #d9e2ef'
      },
      backgroundColor: state.isDisabled ? "#f1f4f8" : "white"
  }),
  menu: provided => ({ ...provided, zIndex: 9999 }),
  multiValue: (provided, state) => {
    return {
      ...provided,
      backgroundColor: "#d9e2ef",
    };
  }
}

// get color for course status badge based on status id
export function getCourseStatusBg(status) {
    switch(status) {
        case 3:
            return "danger";
        case 4:
            return "success";
        case 5:
            return "danger";
        case 6:
            return "dark";
        default:
            return "info";
      }
}

export const keywordType = Object.freeze({
  thematicLines: "TL",
  cooperationKeywords: "CK",
  disciplines: "D",
  fieldsOfResearch: "FoR",
  scienceKeywords: "SK",
  teachingKeywords: "TK"
});

// session storage constants
export const RESEARCHER_CONNECT_DETAILS = "researcherConnectDetails";
export const RESEARCHER_CONNECT = "researcherConnect";

export const OUTGOING_MOBILITY_PENDING = "outgoingMobilityPending";
export const OUTGOING_MOBILITY_INFO = "outgoingMobilityInfo";
export const OUTGOING_MOBILITY_ACTIVE = "outgoingMobilityActive";
export const OUTGOING_MOBILITY_FINISHED = "outgoingMobilityFinished";
export const OUTGOING_MOBILITY_FINISHED_DATES = "outgoingMobilityFinishedDates";
export const OUTGOING_MOBILITY_HISTORY = "outgoingMobilityHistory";
export const OUTGOING_MOBILITY_HISTORY_DATES = "outgoingMobilityHistoryDates";
export const OUTGOING_MOBILITY_STUDENT_COURSES = "outgoingMobilityStudentCourses";

export const INCOMING_MOBILITY_PENDING = "incomingMobilityPending";
export const INCOMING_MOBILITY_INFO = "incomingMobilityInfo";
export const INCOMING_MOBILITY_ACTIVE = "incomingMobilityActive";
export const INCOMING_MOBILITY_FINISHED = "incomingMobilityFinished";
export const INCOMING_MOBILITY_FINISHED_DATES = "incomingMobilityFinishedDates";
export const INCOMING_MOBILITY_HISTORY = "incomingMobilityHistory";
export const INCOMING_MOBILITY_HISTORY_DATES = "incomingMobilityHistoryDates";
export const INCOMING_MOBILITY_STUDENTS = "incomingMobilityStudents";

export const COURSE_ID = "courseId";
export const LOCAL_ID = "localId";
export const COURSE_NAME = "courseName";
export const ADMIN_COURSES = "adminCourses";

export const ADMIN_COURSE_INSTANCES_ACTIVE = "adminCourseInstancesActive";
export const ADMIN_COURSE_INSTANCES_FINISHED = "adminCourseInstancesFinished";

export const PERSON_REGISTRATION_STAFF = "personRegistrationStaff";
export const PERSON_REGISTRATION_STUDENTS = "personRegistrationStudents";

export const USER_MANAGEMENT_STAFF = "userManagementStaff";
export const USER_MANAGEMENT_STUDENTS = "userManagementStudents";

export const ADMIN_EXTRA_MODULES = "adminExtraModules";

export const ADMIN_JOINT_PROGRAMME = "adminJointProgramme";
export const PARTNER_JOINT_PROGRAMME_ADMIN = "partnerJointProgrammeAdmin";

export const STUDENT_CARDS = "studentCards";
export const TEACHER_MANAGEMENT = "teacherManagement";
export const COURSES_PUBLIC = "coursesPublic";
export const EXTRA_MODULES_PUBLIC = "extraModulesPublic";
export const JOINT_PROGRAMMES_PUBLIC = "jointProgrammesPublic";
export const RESEARCH_OUTPUTS_PUBLIC = "researchOutputsPublic";

export const PERSON_REGISTRATION = "personRegistration";

export const ACTIVE_COURSES_STUDENTS = "activeCoursesStudents";
export const COURSE_STATUS_STUDENTS = "courseStatusStudents";
export const ENROL_COURSE_STUDENT = "enrolCourseStudent";
export const COURSE_HISTORY_STUDENTS = "courseHistoryStudents";
export const PENDING_ENROLMENT_STUDENTS = "pendingEnrolmentStudents";
export const STUDENT_EXTRA_MODULES = "studentExtraModules";

export const MODES_OF_COOPERATION = "modesOfCooperation";
export const TEACHING_KEYWORDS = "teachingKeywords";
export const THEMATIC_LINES = "thematicLines";
export const DISCIPLINES = "disciplines"
export const FIELDS_OF_RESEARCH = "fieldsOfResearch";
