import { Alert, Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import CourseStatusLegend from "../../../components/CourseStatusLegend";
import Error from "../../../components/Error";
import { CacheProvider } from "@emotion/react";
import { customTheme, muiCache, options } from "../../../assets/js/datatables";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { INCOMING_MOBILITY_STUDENTS, aapi, getCourseStatusBg, getTimestamp } from "../../../assets/js/shared";

const AdminIncomingActiveDetails = ({ orgUnitId, counter }) => {

    const getFromStorage = () => {
        return JSON.parse(sessionStorage.getItem(INCOMING_MOBILITY_STUDENTS));
    }

    // columns for students (modal)
    const columns = [
        {
            name: "orgUnitName",
            label: "Home university",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentNameFirst",
            label: "First name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentNameLast",
            label: "Last name",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "esi",
            label: "ESI",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "eMail",
            label: "eMail",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (eMail) => {
                    if (eMail !== null && eMail !== "") {
                        return <a href={`mailto:${eMail}`}> {eMail} </a>
                    } else {
                        return <span></span>
                    }
                }
            }
        },
        {
            name: "enrollmentTimestamp",
            label: "Enroled on",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (enrollmentTimestamp) => {
                    return (
                        <>{getTimestamp(enrollmentTimestamp)}</>
                    )
                }
            }
        },
        {
            name: "virtualOrPhysical",
            label: "Attendance",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (virtualOrPhysical) => {
                    if (virtualOrPhysical === 'V') return (<>Virtual</>)
                    else return (<>Physical</>)
                }
            }
        },
        {
            name: "courseStatusNameShort",
            label: "Status",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (courseStatusNameShort, tableMeta) => {
                    return (
                        <OverlayTrigger
                            placement="right"
                            overlay={
                                <Tooltip id='tooltip-right'>
                                    {tableMeta.rowData[11]}
                                </Tooltip>
                            }
                        >
                            <Badge bg={getCourseStatusBg(tableMeta.rowData[11])} className="badge-lg">{courseStatusNameShort}</Badge>
                        </OverlayTrigger>
                    )
                }
            }
        },
        {
            name: "studentId",
            label: "Grade",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (studentId) => {
                    return (
                        <select
                            id={"grade" + studentId}
                            className="form-select form-select-xs text-center"
                            defaultValue=""
                            required
                        >
                            <option key="0" value="" selected hidden disabled>Choose</option>
                            {
                                grades &&
                                grades.map(grade => {
                                    return (
                                        <option key={grade.gradeId} value={grade.gradeId}>{grade.shortGradeName}</option>
                                    )
                                })
                            }
                        </select>
                    )
                }
            }
        },
        {
            name: "studentId",
            label: "Grade date",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (studentId) => {
                    return (
                        <input
                            id={"gradeDate" + studentId}
                            type="date"
                            className="form-control form-control-xs"
                            required
                            min={minGradeDate()}
                        />
                    )
                }
            }
        },
        {
            name: "studentId",
            label: "Action",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (studentId) => {
                    return (
                        <div className="btn-group">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-approve">Register grade</Tooltip>
                                }
                            >
                                <i onClick={() => registerGrade(studentId)} className="btn text-success fe fe-check-circle"></i>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        },
        {
            name: "courseStatusId",
            label: "Status ID",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        }
    ];

    const navigate = useNavigate();

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);

    const getData = useCallback(() => {
        let storage = getFromStorage();
        aapi.get(`/getstudentsforcourseinstance/${storage.courseInstanceId}/A`)
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        if (sessionStorage.getItem(INCOMING_MOBILITY_STUDENTS) === null) {
            navigate("/administrator");
        } else {
            getData();
        }
    }, [getData, navigate]);

    const [grades, setGrades] = useState(null);

    // get grade list
    const getGrades = () => {
        aapi.get("/getgradelist")
            .then(res => {
                setGrades(res.data);
            })
            .catch(err => {
                setError(err);
            })
    }

    useEffect(() => {
        getGrades();
    }, []);

    // register grade for a student with given id (POST)
    const registerGrade = (id) => {
        resetMessages();

        let grade = document.getElementById("grade" + id);
        let gradeDate = document.getElementById("gradeDate" + id);

        let sendData = {
            gradeDate: gradeDate.value,
            gradeId: grade.value,
            receivingOrgUnitId: orgUnitId,
            personId: id,
            courseId: getFromStorage().courseId,
            courseInstanceId: getFromStorage().courseInstanceId
        }

        if (sendData.grade !== "" && sendData.gradeDate !== "") {
            setLoading(true);
            aapi.post("/registerfinalgrade", sendData, {
                transformRequest: [function (data, headers) {
                    headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                    return JSON.stringify(data);
                }]
            })
                .then(res => {
                    setSuccess("Grade successfully registered! More info can be found at incoming finished link.");
                    getData();
                    counter();
                    grade.value = "";
                    gradeDate.value = "";
                })
                .catch(err => {
                    setError(err);
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            if (grade.value === "") {
                grade.focus();
            } else {
                gradeDate.focus();
            }
        }
    }

    const minGradeDate = () => {
        let date = new Date(getFromStorage().startDate);
        return new Date(date.setDate(date.getDate() + 1)).toISOString().split("T")[0];
    }

    const resetMessages = () => {
        setSuccess(null);
        setError(null);
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <div className="row">
                    <div className="col-1">
                        <h4 className="accent">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-back">Go back</Tooltip>
                                }
                            >
                                <button className="btn accent p-0" onClick={() => navigate(-1)}><i className="fe fe-chevron-left fw-bold"></i></button>
                            </OverlayTrigger>
                        </h4>
                    </div>
                    <div className="col-11">
                        <h4 className="accent">
                            <h4 className="accent">{getFromStorage().source} ({getFromStorage().course})</h4>
                        </h4>
                        <h4><b>List of students</b></h4>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        {
                            loading &&
                            <div className="spinner-border text-primary mb-1" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        }
                        <CourseStatusLegend />
                        {
                            success &&
                            <Alert variant="success-soft" className="mt-3 p-3" onClose={() => setSuccess()} dismissible>{success}</Alert>
                        }
                        {
                            error &&
                            <Error className="mt-3" text={error}></Error>
                        }
                        {
                            data &&
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={data}
                                        columns={columns}
                                        options={options}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AdminIncomingActiveDetails;