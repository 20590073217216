import {
    Link,
    useMatch,
    useResolvedPath,
} from "react-router-dom";

const CustomLink = ({ children, to, className, ...props }) => {
    let resolved = useResolvedPath(to);
    let match = useMatch({ path: resolved.pathname, end: true });
    let classComp = className + (match? " active" : "");

    return (
        <Link
        to={to} 
        className={classComp}
        {...props}
        >
        {children}
        </Link>
    );
}

export default CustomLink;