import { useCallback, useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { PERSON_REGISTRATION_STAFF, PERSON_REGISTRATION_STUDENTS, aapi } from "../../assets/js/shared";
import Error from "../../components/Error";
import PersonRegistrationTable from "./components/PersonRegistrationTable";

const PersonRegistration = (props) => {

	const [staff, setStaff] = useState(null);
	const [tableDetailsStaff, setTableDetailsStaff] = useState(null);
	const [students, setStudents] = useState(null);
	const [tableDetailsStudents, setTableDetailsStudents] = useState(null);

	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(true);

	// get all users for admin's university and pass result to two UserTable components (separate students from staff)
	const getStaffData = useCallback((page, query) => {
		aapi.get(`/getallpersonregistrations/E/N/${page}${query}`)
		.then(res => {
			setStaff(res.data.data.filter(person => person.roleName !== "Student"));
			setTableDetailsStaff(res.data.paginationDetails);
		})
		.catch(err => {
			setError(err);
		})
		.finally( () =>{
			setIsLoading(false);
		})
	}, []);

	const getStudentData = useCallback((page, query) => {
		aapi.get(`/getallpersonregistrations/S/N/${page}${query}`)
		.then(res => {
			setStudents(res.data.data.filter(person => person.roleName === "Student"));
			setTableDetailsStudents(res.data.paginationDetails);
		})
		.catch(err => {
			setError(err);
		})
		.finally( () =>{
			setIsLoading(false);
		})
	}, []);

	useEffect(() => {
		sessionStorage.removeItem(PERSON_REGISTRATION_STAFF);
        sessionStorage.removeItem(PERSON_REGISTRATION_STUDENTS);
        getStaffData(1, "");
        getStudentData(1, "");
	}, [getStaffData, getStudentData]);

	return (
		<div className="card card-bleed shadow-light-lg mb-6">
			<div className="card-header">
				<h4 className="accent"> Pending registrations </h4>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col">
						{
							error &&
							<Error text={error}></Error>
						}
						{
							isLoading &&
							<div className="spinner-border text-primary mb-1" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						}
						{
							staff && students &&
							<Tabs defaultActiveKey="staff" id="staff" className="mb-3">
								<Tab eventKey="staff" title={`Staff (${tableDetailsStaff.count})`}>
									<div className="row">
										<div className="col">
											<PersonRegistrationTable staff={true} counter={props.counter} data={staff} getData={getStaffData} tableDetails={tableDetailsStaff} setIsLoading={setIsLoading} />
										</div>
									</div>
								</Tab>
								<Tab eventKey="students" title={`Students (${tableDetailsStudents.count})`}>
									<div className="row">
										<div className="col">
											<PersonRegistrationTable staff={false} counter={props.counter} data={students} getData={getStudentData} tableDetails={tableDetailsStudents} setIsLoading={setIsLoading} />
										</div>
									</div>
								</Tab>
							</Tabs>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PersonRegistration;