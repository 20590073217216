import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import packageJson from '../package.json';

import PublicNavbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './templates/public/Home';
import RegistrationForm from './templates/public/RegistrationForm';
import Courses from './templates/public/Courses';
import JointProgrammes from './templates/public/JointProgrammes';
import Student from './templates/student/Student';
import Administrator from './templates/administrator/Administrator';
import Verify from './templates/Verify';
import ErrorPage from './templates/ErrorPage';
import Error401Page from './templates/Error401Page';
import Error403Page from './templates/Error403Page';
import Error403PageSaml from './templates/Error403PageSaml';
import SuccessfulRegPage from './templates/SuccessfulRegPage';
import AccessibilityStatement from './templates/public/AccessibilityStatement';
import Publications from './templates/public/Publications';
import Researcher from './templates/researcher/Researcher';
import ExtraModules from './templates/public/ExtraModules';

class App extends React.Component {
	render() {
		return (
			<Router basename={packageJson.homepage}>
				<div className="App">
					<a className="skip-main" href="#main">Skoči na glavni sadržaj </a>
					<PublicNavbar />
					<section id="main">
						<Routes>
							<Route path="/" element={<Home />} />
							<Route path="registration/*" element={<RegistrationForm />} />
							<Route path="public/courses/*" element={<Courses />} />
							<Route path="public/jointprogrammes/*" element={<JointProgrammes />} />
							<Route path="public/researchoutputs" element={<Publications />} />
							<Route path="public/extramodules" element={<ExtraModules />} />
							<Route path="student/*" element={<Student />} />
							<Route path="administrator/*" element={<Administrator />} />
							<Route path="researcher/*" element={<Researcher />} />
							<Route path="public/verify/:cardNo" element={<Verify />} />
							<Route path="public/error401" element={<Error401Page />} />
							<Route path="public/error403" element={<Error403Page />} />
							<Route path="public/error403idp" element={<Error403PageSaml />} />
							<Route path="public/successfulregistration" element={<SuccessfulRegPage />} />
							<Route path="public/accessibility-statement" element={<AccessibilityStatement />} />
							<Route path="*" element={<ErrorPage />} />
						</Routes>
					</section>
					<Footer />
				</div>
			</Router>
		);
	}
}

export default App;
