import { useCallback, useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { USER_MANAGEMENT_STAFF, USER_MANAGEMENT_STUDENTS, aapi } from "../../assets/js/shared";
import Error from "../../components/Error";
import UserTable from "./components/UserTable";

const UserManagement = (props) => {

    const [staff, setStaff] = useState(null);
	const [tableDetailsStaff, setTableDetailsStaff] = useState(null);
	const [students, setStudents] = useState(null);
	const [tableDetailsStudents, setTableDetailsStudents] = useState(null);

	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

    // get all users for admin's university and pass result to two UserTable components (separate students from staff)
    const getStaffData = useCallback((page, query) => {
        aapi.get(`getunipersons/E/${page}${query}`)
        .then(res => {
            setStaff(res.data.data.filter(person => (person.roleName !== "Student") && (person.eMail !== props.email)));
            setTableDetailsStaff(res.data.paginationDetails);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, [props.email]);

    const getStudentData = useCallback((page, query) => {
        setIsLoading(true);
        aapi.get(`getunipersons/S/${page}${query}`)
        .then(res => {
            setStudents(res.data.data.filter(person => person.roleName === "Student"));
            setTableDetailsStudents(res.data.paginationDetails);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setIsLoading(false);
        })
    }, []);

    useEffect(() => {
        sessionStorage.removeItem(USER_MANAGEMENT_STAFF);
        sessionStorage.removeItem(USER_MANAGEMENT_STUDENTS);
        getStaffData(1, "");
        getStudentData(1, "");
    }, [getStaffData, getStudentData]);

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">User management</h4>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col">
                        {
							error &&
							<Error text={error}></Error>
						}
						{
							isLoading &&
							<div className="spinner-border text-primary mb-1" role="status">
								<span className="visually-hidden">Loading...</span>
							</div>
						}
                        {
                            staff && students &&
                            <Tabs defaultActiveKey="staff" id="students" className="mb-3">
                                <Tab eventKey="staff" title="Staff">
                                    <div className="row">
                                        <div className="col">
                                            <UserTable staff={true} data={staff} getData={getStaffData} tableDetails={tableDetailsStaff} setIsLoading={setIsLoading} />
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="students" title="Students">
                                    <div className="row">
                                        <div className="col">
                                            <UserTable staff={false} data={students} getData={getStudentData} tableDetails={tableDetailsStudents} setIsLoading={setIsLoading} />
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserManagement;