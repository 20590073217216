import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { muiCache, customTheme, getQuery, paginationOptions } from "../../assets/js/datatables";
import { ACTIVE_COURSES_STUDENTS, aapi, getDate } from "../../assets/js/shared";
import Error from "../../components/Error";
import { virtualOrPhysicalFO } from "../../assets/js/filterOptions";
import { useCallback, useEffect, useState } from "react";
import CourseStatusLegend from "../../components/CourseStatusLegend";

const ActiveCourses = () => {

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	// columns for course table
	const columns = [
		{
			name: "courseName",
			label: "Name",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (name, tableMeta) => {
					const url = tableMeta.rowData[7];

					if (url) {
						return (
							<a href={url} target="_blank" rel="noreferrer"> {name} <i className="fe fe-external-link"></i></a>
						)
					} else {
						return (
							<span> {name} </span>
						)
					}
				}
			}
		},
		{
			name: "ectsCredits",
			label: "ECTS Credits",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "academicYear",
			label: "Academic Year",
			options: {
				filter: false,
				sort: true,
				customBodyRender: (academicYear) => {
					return <>{academicYear}/{academicYear + 1}</>
				}
			}
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (startDate) => {
					return (
						<span> {getDate(startDate)} </span>
					)
				},
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setStartDate("");
						getData(tableDetails.currentPage, getQuery(ACTIVE_COURSES_STUDENTS));
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">Start date</label>
								<input
									type="date"
									className="date-filter-input"
									value={startDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setStartDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
			}
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (endDate) => {
					return (
						<span> {getDate(endDate)} </span>
					)
				},
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setEndDate("");
						getData(tableDetails.currentPage, getQuery(ACTIVE_COURSES_STUDENTS));
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">End date</label>
								<input
									type="date"
									className="date-filter-input"
									value={endDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setEndDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
			}
		},
		{
			name: "lectures",
			label: "Lecture hours",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "exercises",
			label: "Exercise hours",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "uri",
			label: "URI",
			options: {
				filter: false,
				sort: false,
				display: "excluded",
			}
		},
		{
			name: "virtualOrPhysical",
			label: "Mode of attendance",
			options: {
				filter: true,
				filterType: "dropdown",
				filterOptions: { names: virtualOrPhysicalFO },
				sort: true,
				customBodyRender: (mode) => {
					if (mode === "V") {
						return (
							<span> Virtual </span>
						)
					} else {
						return (
							<span> Physical </span>
						)
					}
				}
			}
		},
		{
			name: "orgUnitName",
			label: "Organization name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "superOrgUnitName",
			label: "University name",
			options: {
				filter: true,
				sort: true,
			}
		},
	];

	const [data, setData] = useState(null);
	const [tableDetails, setTableDetails] = useState(null);

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	const getData = useCallback((page, query) => {
		aapi.get(`/getstudentactivecourses/${page}${query}`)
			.then(res => {
				setData(res.data.data);
				setTableDetails(res.data.paginationDetails);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}, []);

	useEffect(() => {
		sessionStorage.removeItem(ACTIVE_COURSES_STUDENTS);
		getData(1, "");
	}, [getData]);

	return (
		<div className="card card-bleed shadow-light-lg mb-6">
			<div className="card-header">
				<h4 className="accent"> Active courses </h4>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col">
						<CacheProvider value={muiCache}>
							<ThemeProvider theme={customTheme}>
								{
									isLoading &&
									<div className="spinner-border text-primary mb-1" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								}
								{
									error &&
									<Error text={error}></Error>
								}
								<CourseStatusLegend />
								{
									data &&
									<MUIDataTable
										data={data}
										columns={columns}
										options={paginationOptions(tableDetails, getData, setIsLoading, ACTIVE_COURSES_STUDENTS)}
									/>
								}

							</ThemeProvider>
						</CacheProvider>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ActiveCourses;