import { useCallback, useEffect, useState } from "react";
import Error from "../../components/Error";
import { Modal } from "react-bootstrap";
import { aapi, keywordType, searchSelectStyle } from "../../assets/js/shared";
import KeywordsComponent from "./components/KeywordsComponent";
import BaseSelect from "react-select";
import FixRequiredSelect from "../../components/FixRequiredSelect";

const Select = props => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options}
    />
);

const ResearcherInterests = () => {

    const [data, setData] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const getData = useCallback(() => {
        aapi.get("/getpersonalinterests")
            .then(res => {
                setData(res.data);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, []);

    useEffect(() => {
        getData();
    }, [getData]);

    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState(null);
    const [modalData, setModalData] = useState(null);
    const [modalError, setModalError] = useState(null);
    const [type, setType] = useState(null);
    const [sendData, setSendData] = useState(null);

    const openModal = (param) => {
        let url = "";
        let label = "";
        let value = "";
        let category = "";

        if (param === keywordType.thematicLines) {
            url = "/getthematiclines";
            label = "thematicLineName";
            value = "thematicLineId";
            category = "thematicLines";
            setModalTitle("Add thematic lines");
            setType(keywordType.thematicLines);
        } else if (param === keywordType.cooperationKeywords) {
            url = "/getcooperationkeywords";
            label = "cooperationKeywordName";
            value = "cooperationKeywordId";
            category = "cooperationKeywords";
            setModalTitle("Add modes of cooperation");
            setType(keywordType.cooperationKeywords);
        } else if (param === keywordType.disciplines) {
            url = "/getdisciplines";
            label = "disciplineName";
            value = "disciplineId";
            category = "disciplines";
            setModalTitle("Add disciplines");
            setType(keywordType.disciplines);
        } else if (param === keywordType.fieldsOfResearch) {
            url = "/getfieldsofresearch";
            label = "fieldOfResearchName";
            value = "fieldOfResearchId";
            category = "fieldsOfResearch";
            setModalTitle("Add fields of research");
            setType(keywordType.fieldsOfResearch);
        } else if (param === keywordType.teachingKeywords) {
            url = "/getteachingkeywords";
            label = "teachingKeywordName";
            value = "teachingKeywordId";
            category = "teachingKeywords";
            setModalTitle("Add fields of teaching");
            setType(keywordType.teachingKeywords);
        }

        aapi.get(`${url}`)
            .then(res => {
                let result = [];
                let ids = [];

                data[category].forEach(item => {
                    ids.push(item.keywordId);
                })

                res.data.data.forEach(item => {
                    if (!ids.includes(item[value])) {
                        result.push({
                            label: item[label],
                            value: item[value]
                        })
                    }
                })

                setModalData(result);
            })
            .catch(err => {
                setModalError(err);
            })

        setShowModal(true);
    }

    const closeModal = () => {
        setModalData(null);
        setType(null);
        setSendData(null);
        setModalError(null);
        setShowModal(false);
    }

    const handlePost = (e) => {
        e.preventDefault();

        let url = "";
        if (type === keywordType.thematicLines) {
            url = "/addthematiclines";
        } else if (type === keywordType.cooperationKeywords) {
            url = "/addcooperationkeywords";
        } else if (type === keywordType.disciplines || type === keywordType.fieldsOfResearch) {
            url = "/addsciencekeywords";
        } else if (type === keywordType.teachingKeywords) {
            url = "/addteachingkeywords";
        }

        aapi.post(`${url}`, sendData, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

                return JSON.stringify(data);
            }]
        })
            .then(res => {
                closeModal();
                getData();
            })
            .catch(err => {
                setModalData(err);
            })
    }

    const handleChange = (e) => {
        let ids = [];
        e.forEach(item => {
            ids.push(item.value);
        })
        setSendData(ids);
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Research and teaching interests</h4>
            </div>
            <div className="card-body">
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                {
                    data &&
                    <div>
                        <div className="my-5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="fw-bolder">
                                        Thematic lines
                                        <button className="btn btn-xs btn-outline-success ms-3" onClick={() => openModal(keywordType.thematicLines)}>
                                            Add <i className="fe fe-plus-circle"></i>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <KeywordsComponent data={data.thematicLines} type={keywordType.thematicLines} getData={getData} setError={setError} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="my-5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="fw-bolder">
                                        Disciplines
                                        <button className="btn btn-xs btn-outline-success ms-3" onClick={() => openModal(keywordType.disciplines)}>
                                            Add <i className="fe fe-plus-circle"></i>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <KeywordsComponent data={data.disciplines} type={keywordType.scienceKeywords} getData={getData} setError={setError} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="my-5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="fw-bolder">
                                        Fields of research
                                        <button className="btn btn-xs btn-outline-success ms-3" onClick={() => openModal(keywordType.fieldsOfResearch)}>
                                            Add <i className="fe fe-plus-circle"></i>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <KeywordsComponent data={data.fieldsOfResearch} type={keywordType.scienceKeywords} getData={getData} setError={setError} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="my-5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="fw-bolder">
                                        Fields of teaching
                                        <button className="btn btn-xs btn-outline-success ms-3" onClick={() => openModal(keywordType.teachingKeywords)}>
                                            Add <i className="fe fe-plus-circle"></i>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <KeywordsComponent data={data.teachingKeywords} type={keywordType.teachingKeywords} getData={getData} setError={setError} />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div className="my-5">
                            <div className="row">
                                <div className="col-sm-12">
                                    <p className="fw-bolder">
                                        Modes of cooperation
                                        <button className="btn btn-xs btn-outline-success ms-3" onClick={() => openModal(keywordType.cooperationKeywords)}>
                                            Add <i className="fe fe-plus-circle"></i>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <KeywordsComponent data={data.cooperationKeywords} type={keywordType.cooperationKeywords} getData={getData} setError={setError} />
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <Modal
                    size="lg"
                    show={showModal}
                    onHide={closeModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        {
                            modalTitle &&
                            <Modal.Title>{modalTitle}</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={handlePost}>
                            <div className="row">
                                <div className="col-12">
                                    {
                                        modalError &&
                                        <Error text={modalError}></Error>
                                    }
                                    {
                                        !modalData &&
                                        <div className="spinner-border text-primary mb-1" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                    {
                                        modalData &&
                                        <Select
                                            styles={searchSelectStyle}
                                            isSearchable={true}
                                            options={modalData}
                                            placeholder="Choose"
                                            onChange={e => handleChange(e)}
                                            isMulti
                                            required
                                        />
                                    }
                                </div>
                            </div>
                            <div className="row mt-4">
                                <div className="col-12 col-md-auto">
                                    <button type="submit" className="btn w-100 btn-primary">Add</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default ResearcherInterests;