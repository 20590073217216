import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { useCallback, useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ADMIN_COURSES, COURSE_ID, COURSE_NAME, LOCAL_ID, aapi, searchSelectStyle } from "../../assets/js/shared";
import Error from "../../components/Error";
import MUIDataTable from "mui-datatables";
import { muiCache, customTheme, options, paginationOptions, getQuery } from "../../assets/js/datatables";
import { useNavigate } from "react-router-dom";
import BaseSelect from "react-select";
import FixRequiredSelect from "../../components/FixRequiredSelect";
import { courseActiveFO } from "../../assets/js/filterOptions";

const Select = props => (
    <FixRequiredSelect
        {...props}
        SelectComponent={BaseSelect}
        options={props.options || options}
    />
);

const AdminCourses = props => {

    // columns for course table
    const columns = [
        {
            name: "localCourseId",
            label: "Local course ID",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "courseName",
            label: "Name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (name, tableMeta ) => {
                    const url = tableMeta.rowData[4];
            
                    if(url){
                        return (
                            <a href={url} target="_blank" rel="noreferrer"> {name} <i className="fe fe-external-link"></i></a>
                        )
                    } else {
                        return (
                            <span> {name} </span>
                        )
                    }
                }
            }
        },
        {
            name: "descriptionCourse",
            label: "Description",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "ectsCredits",
            label: "ECTS Credits",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "uri",
            label: "URI",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "lectures",
            label: "Lecture hours",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "exercises",
            label: "Exercise hours",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "prerequisites",
            label: "Prerequisites",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "active",
            label: "Active",
            options: {
                sort: true,
                filter: true,
                filterType: "dropdown",
                filterOptions: { names: courseActiveFO },
                customBodyRender: (active) => {
                    if(active === "Y") return (<>Active</>)
                    else return (<>Inactive</>)
                }
            }
        },
        {
            name: "courseId",
            label: "Actions",
            options: {
                download: false,
                filter: false,
                sort: false,
                customBodyRender: (courseId, tableMeta) => {
                    return (
                        <>
                            <div className="btn-group">
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip id="tooltip-edit">Edit</Tooltip>
                                    }
                                >
                                    <button className="btn text-info" onClick={() => handleShowEditModal(courseId, tableMeta)}>
                                        <i className="fe fe-edit"></i>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </>
                    )
                }
            }
        },
        {
            name: "iscedFieldId",
            label: "ISCED code",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "courseId",
            label: "Course instances",
            options: {
                download: false,
                filter: false,
                sort: false,
                customBodyRender: (courseId, tableMeta) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip id="tooltip-info">Course instances</Tooltip>
                            }
                        >
                            <button 
                                className="btn" 
                                aria-label="More details"
                                onClick={() => courseInstanceLink(courseId, tableMeta.rowData[0], tableMeta.rowData[1])}
                                >
                                    <i className="fe fe-eye"></i>
                            </button>
                        </OverlayTrigger>
                    )
                }
            }
        }
    ];

    const navigate = useNavigate();

    // link to course instance page, save variables to session storage so they could be used on course instance page
    const courseInstanceLink = (id, localId, name) => {
        sessionStorage.setItem(COURSE_ID, id);
        sessionStorage.setItem(LOCAL_ID, localId);
        sessionStorage.setItem(COURSE_NAME, name);
        navigate("courseInstances");
    }

    const [courses, setCourses] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [isced, setIsced] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    // get initial data (courses and isced field)
    const getData = useCallback(async (page, query) => {
        aapi.get(`/getunicourses/${page}${query}`)
        .then(res => {
            setCourses(res.data.data);
            setTableDetails(res.data.paginationDetails);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    useEffect(() => {
        sessionStorage.removeItem(ADMIN_COURSES);
        getData(1, "");
    }, [getData]);

    const getIsced = useCallback(() => {
        aapi.get()
    }, []);

    useEffect(() => {
        aapi.get("/getiscedfield")
        .then(res => {
            let data = [];
            res.data.forEach(item => {
                data.push({
                    label: "[" + item.iscedFieldCode + "] " + item.iscedFieldName,
                    value: item.iscedFieldId
                })
            })
            setIsced(data);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, [getIsced]);

    // add new course modal hooks
    const [showAddModal, setShowAddModal] = useState(false);
    const [addModalError, setAddModalError] = useState(null);

    // add new course data (POST)
    const [sendNewData, setSendNewData] = useState({
        orgUnitId: props.orgUnitId,
        prerequisites: "There are no prerequisites for this class."
    });

    // add new course (POST)
    const handleSubmit = (e) => {
        e.preventDefault();

        aapi.post("/pushbasiccoursedata", sendNewData, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            closeAddModal();
            getData(tableDetails.currentPage, getQuery("adminCourses"));
        })
        .catch(err => {
            setAddModalError(err);
        })
    }

    // close add new course modal
    const closeAddModal = () => {
        setShowAddModal(false);
        setAddModalError(null);
        setSendNewData({
            orgUnitId: props.orgUnitId,
            prerequisites: "There are no prerequisites for this class."
        })
    }

    // edit course modal hooks
    const [showEditModal, setShowEditModal] = useState(false);
    const [editModalError, setEditModalError] = useState(null);
    const [currentIsced, setCurrentIsced] = useState(null);
    const [currentCourse, setCurrentCourse] = useState(null);
    const [currentLocalCourseId, setCurrentLocalCourseId] = useState(null);
    const [active, setActive] = useState(null);
    
    // edit course data (PUT)
    const [sendEditData, setSendEditData] = useState(null);

    // show edit course modal
    const handleShowEditModal = (id, tableMeta) => {

        setSendEditData({...sendEditData,
            courseId: tableMeta.rowData[9],
            localCourseId: tableMeta.rowData[0],
            courseName: tableMeta.rowData[1],
            descriptionCourse: tableMeta.rowData[2],
            ectsCredits: tableMeta.rowData[3],
            uri: tableMeta.rowData[4],
            prerequisites: tableMeta.rowData[7],
            iscedFieldId: tableMeta.rowData[10],
            active: tableMeta.rowData[8],
            lectures: tableMeta.rowData[5],
            exercises: tableMeta.rowData[6]
        });
        let filtered = isced.filter(item => item.value === tableMeta.rowData[10]);
        setCurrentIsced(filtered.length > 0 ? filtered[0] : "");
        setCurrentCourse(tableMeta.rowData[1]);
        setCurrentLocalCourseId(tableMeta.rowData[0]);
        setActive(tableMeta.rowData[8] === "Y" ? {label: "Active", value: "Y"} : {label: "Inactive", value: "N"});
        setShowEditModal(true);
    }

    // close edit course modal
    const closeEditModal = () => {
        setShowEditModal(false);
        setEditModalError(null);
        setCurrentIsced(null);
        setCurrentCourse(null);
        setCurrentLocalCourseId(null);
    }

    // edit course (PUT)
    const handleEdit = (e) => {
        e.preventDefault();

        if(sendEditData.active) {
            aapi.put("/editbasiccoursedata", sendEditData, {
                transformRequest: [function (data, headers) {
                    headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                    
                    return JSON.stringify(data);
                }]
            })
            .then(res => {
                closeEditModal();
                getData(tableDetails.currentPage, getQuery("adminCourses"));
            })
            .catch(err => {
                setEditModalError(err);
            })
        } else {
            setEditModalError({...editModalError, response: {data: "Active field cannot be empty!"}});
        }
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Courses</h4>
            </div>
            <div className="card-body">
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                {
                    courses &&
                    <>
                        <div className="row">
                            <div className="col-12 col-md-auto">
                                <button className="btn w-100 btn-primary" onClick={() => setShowAddModal(true)}>Add course</button>
                            </div>
                        </div>
                        <hr className="break"></hr>
                        <div className="row">
                            <div className="col">
                                <CacheProvider value={muiCache}>
                                    <ThemeProvider theme={customTheme}>
                                        <MUIDataTable
                                            data={courses}
                                            columns={columns}
                                            options={paginationOptions(tableDetails, getData, setLoading, ADMIN_COURSES)}
                                        />
                                    </ThemeProvider>
                                </CacheProvider>

                                <Modal
                                    size="lg"
                                    show={showAddModal}
                                    onHide={closeAddModal}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >   
                                    <Modal.Header closeButton>
                                        <Modal.Title>Add new course</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {
                                            addModalError &&
                                            <Error text={addModalError}></Error>
                                        }
                                        <form onSubmit={handleSubmit}>
                                            <div className="row mb-2">
                                                <div className="col-12">
                                                    <p className="mt-0">* - required field</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Course name*</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            required
                                                            onChange={e => setSendNewData({...sendNewData, courseName: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Course description</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            onChange={e => setSendNewData({...sendNewData, description: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Course prerequisites</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            defaultValue={sendNewData.prerequisites}
                                                            onChange={e => setSendNewData({...sendNewData, prerequisites: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Course URI</label>
                                                        <input
                                                            className="form-control"
                                                            type="url"
                                                            placeholder="https://example.com"
                                                            onChange={e => setSendNewData({...sendNewData, uri: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row d-flex">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label>ECTS credits</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            step="0.5"
                                                            min="0"
                                                            onChange={e => setSendNewData({...sendNewData, ectsCredits: parseInt(e.target.value)})}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label>Local course ID</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            onChange={e => setSendNewData({...sendNewData, localCourseId: e.target.value})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row d-flex">
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label>Lecture hours</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            min={"0"}
                                                            onChange={e => setSendNewData({...sendNewData, lectures: parseInt(e.target.value)})}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-6">
                                                    <div className="form-group">
                                                        <label>Exercise hours</label>
                                                        <input
                                                            className="form-control"
                                                            type="number"
                                                            min={"0"}
                                                            onChange={e => setSendNewData({...sendNewData, exercises: parseInt(e.target.value)})}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>Active*</label>
                                                        <Select
                                                                styles={searchSelectStyle}
                                                                defaultValue={""}
                                                                isSearchable={true}
                                                                options={[
                                                                    {
                                                                        label: "Active",
                                                                        value: "Y"
                                                                    },
                                                                    {
                                                                        label: "Inactive",
                                                                        value: "N"
                                                                    }
                                                                ]}
                                                                placeholder="Choose"
                                                                onChange={e => setSendNewData({...sendNewData, active: e !== null ? e.value : null})}
                                                                required
                                                            />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="form-group">
                                                        <label>ISCED code</label>
                                                        {
                                                            isced &&
                                                            <Select
                                                                styles={searchSelectStyle}
                                                                defaultValue={""}
                                                                isClearable={true}
                                                                isSearchable={true}
                                                                options={isced}
                                                                placeholder="Choose"
                                                                onChange={e => setSendNewData({...sendNewData, iscedFieldId: e !== null ? e.value : null})}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 col-md-auto d-flex">
                                                    <button className="btn w-100 btn-primary" type="submit">Save</button>
                                                </div>
                                            </div>
                                        </form>
                                    </Modal.Body>
                                </Modal>

                                <Modal
                                    size="lg"
                                    show={showEditModal}
                                    onHide={closeEditModal}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                >   <Modal.Header closeButton>
                                        {
                                            sendEditData &&
                                            <Modal.Title>
                                                Edit course - <span className="accent">{currentCourse} 
                                                    {
                                                        currentLocalCourseId &&
                                                        <> ({currentLocalCourseId})</>
                                                    }
                                                </span>
                                            </Modal.Title>
                                        }
                                    </Modal.Header>
                                    <Modal.Body>
                                        {
                                            editModalError &&
                                            <Error text={editModalError}></Error>
                                        }
                                        {
                                            sendEditData &&
                                            <form onSubmit={handleEdit}>
                                                <div className="row mb-2">
                                                    <div className="col-12">
                                                        <p className="mt-0">* - required field</p>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Course name*</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                required
                                                                defaultValue={sendEditData.courseName}
                                                                onChange={e => setSendEditData({...sendEditData, courseName: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Course description</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                defaultValue={sendEditData.descriptionCourse}
                                                                onChange={e => setSendEditData({...sendEditData, descriptionCourse: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Course prerequisites</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                defaultValue={sendEditData.prerequisites}
                                                                onChange={e => setSendEditData({...sendEditData, prerequisites: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Course URI</label>
                                                            <input
                                                                className="form-control"
                                                                type="url"
                                                                placeholder="https://example.com"
                                                                defaultValue={sendEditData.uri}
                                                                onChange={e => setSendEditData({...sendEditData, uri: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row d-flex">
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label>ECTS credits</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                step="0.5"
                                                                min="0"
                                                                defaultValue={sendEditData.ectsCredits}
                                                                onChange={e => setSendEditData({...sendEditData, ectsCredits: parseInt(e.target.value)})}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label>Local course ID</label>
                                                            <input
                                                                className="form-control"
                                                                type="text"
                                                                defaultValue={sendEditData.localCourseId}
                                                                onChange={e => setSendEditData({...sendEditData, localCourseId: e.target.value})}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row d-flex">
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label>Lecture hours</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                min={"0"}
                                                                defaultValue={sendEditData.lectures}
                                                                onChange={e => setSendEditData({...sendEditData, lectures: parseInt(e.target.value)})}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <div className="form-group">
                                                            <label>Exercise hours</label>
                                                            <input
                                                                className="form-control"
                                                                type="number"
                                                                min={"0"}
                                                                defaultValue={sendEditData.exercises}
                                                                onChange={e => setSendEditData({...sendEditData, exercises: parseInt(e.target.value)})}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>Active*</label>
                                                            <Select
                                                                styles={searchSelectStyle}
                                                                defaultValue={active}
                                                                isSearchable={true}
                                                                options={[
                                                                    {
                                                                        label: "Active",
                                                                        value: "Y"
                                                                    },
                                                                    {
                                                                        label: "Inactive",
                                                                        value: "N"
                                                                    }
                                                                ]}
                                                                placeholder="Choose"
                                                                onChange={e => setSendEditData({...sendEditData, active: e !== null ? e.value : null})}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <label>ISCED code</label>
                                                            {
                                                                currentIsced !== null &&
                                                                <Select
                                                                    styles={searchSelectStyle}
                                                                    defaultValue={currentIsced}
                                                                    isClearable={true}
                                                                    isSearchable={true}
                                                                    options={isced}
                                                                    placeholder="Choose"
                                                                    onChange={e => setSendEditData({...sendEditData, iscedFieldId: e !== null ? e.value : null})}
                                                                />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-12 col-md-auto d-flex">
                                                        <button className="btn w-100 btn-primary" type="submit">Save</button>
                                                    </div>
                                                </div>
                                            </form>
                                        }
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default AdminCourses;