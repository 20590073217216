import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import MUIDataTable from 'mui-datatables';
import useFetch from '../assets/js/useFetch';
import { customTheme, muiCache, options2 } from '../assets/js/datatables';

const JPCoursesTable = ({jointProgrammeId}) => {

	// columns for joint programme table
	const columns = [
		{
			name: "courseName",
			label: "Name",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (courseName, tableMeta) => {
					const url = tableMeta.rowData[1];

					if(url !== null && url !== "") {
						return <a href={url} target="_blank" rel="noreferrer">{courseName} <i className="fe fe-external-link"></i></a>
					} else {
						return <span>{courseName}</span>
					}
				}
			}
		},
		{
			name: "uri",
			label: "URL",
			options: {
				filter: false,
				sort: false,
				display: "excluded"
			}
		},
		{
			name: "descriptionCourse",
			label: "Description",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "ectsCredits",
			label: "ECTS",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "prerequisites",
			label: "Prerequisites",
			options: {
				filter: true,
				sort: true
			}
		}
	];

	const {data: jpCoursesList} = useFetch(`/public/getcoursesforjointprogramme/${jointProgrammeId}`);

	return (
		<div className="row">
				{
					jpCoursesList &&
					<CacheProvider value={muiCache}>
						<ThemeProvider theme={customTheme}>
							<MUIDataTable
								data={jpCoursesList.coursesList}
								columns={columns}
								options={options2}
							/>
						</ThemeProvider>
					</CacheProvider>
				}
		</div>
	);
}
 
export default JPCoursesTable;
