import { useState } from "react";

const CourseStatusLegend = () => {

    const [legend, setLegend] = useState(true);
    const show = () => {
        if(legend) {
            setLegend(false);
        } else {
            setLegend(true);
        }
    }

    return (
        <div id="legend-container">
            <div className="row">
                <div className="col-12"><button type="button" className="btn btn-secondary btn-sm px-3 py-1" onClick={() => show()}>Course status legend</button></div>
            </div>
            {
                legend &&
                <div className="row mt-3">
                    <div className="col-12 col-md-6 col-xl-3 text-secondary">
                        <span className="fw-bolder">1</span><span> - Student wants to enrol a course </span><br/>
                        <span className="fw-bolder">2</span><span> - Home UNI has checked prerequisites and granted enrolment </span><br/>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 text-secondary">
                        <span className="fw-bolder">3</span><span> - Home UNI has declined enrolment </span><br/>
                        <span className="fw-bolder">4</span><span> - Receiving UNI has granted enrolment </span><br/>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 text-secondary">
                        <span className="fw-bolder">5</span><span> - Receiving UNI has declined enrolment </span><br/>
                        <span className="fw-bolder">6</span><span> - Student gave up on the course </span><br/>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 text-secondary">
                        <span className="fw-bolder">7</span><span> - Student enroled, attending the course </span><br/>
                        <span className="fw-bolder">8</span><span> - Student has finished the course </span><br/>
                    </div>
                </div>
            }
        </div>
    )
}

export default CourseStatusLegend;