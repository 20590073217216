import CustomLink from "../../../components/CustomLink";

const StudentSidebar = () => {

  return (
  <div className="card-body">
    <ul className="card-list list text-gray-700 mb-6">
      <li className="list-item">
        <h6 className="fw-bold text-uppercase mb-3">
          <CustomLink className="list-link fs-6 text-gray-700" to="">
            Home
          </CustomLink>
        </h6>
      </li>
    </ul>


    <ul className="card-list list text-gray-700 mb-6">
      <li className="list-item">
        <h6 className="fw-bold text-uppercase mb-3">
          <CustomLink className="list-link fs-6 text-gray-700" to="studentcard">
            My student card
          </CustomLink>
        </h6>
      </li>
    </ul>

    <ul className="card-list list text-gray-700 mb-6">
      <li className="list-item">
        <h6 className="fw-bold text-uppercase mb-3">
          <CustomLink className="list-link fs-6 text-gray-700" to="extramodules">
            Extra modules
          </CustomLink>
        </h6>
      </li>
    </ul>

    {/* <h6 className="fw-bold text-uppercase mb-3">Student Card</h6>
    <ul className="card-list list text-gray-700 mb-6">
      <li className="list-item">
        <CustomLink className="list-link text-reset" to="cardstatus">
          View my card status
        </CustomLink>
      </li>
      <li className="list-item">
        <CustomLink className="list-link text-reset" to="generatecard">
          Generate a virtual card
        </CustomLink>
      </li>
    </ul> */}

    <h6 className="fw-bold text-uppercase mb-3">My courses</h6>
    <ul className="card-list list text-gray-700 mb-0">
      <li className="list-item">
        <CustomLink className="list-link text-reset" to="enrolcourses">
          Enrol in a course
        </CustomLink>
      </li>
      <li className="list-item">
        <CustomLink className="list-link text-reset" to="pendingenrolment">
          Pending enrolment
        </CustomLink>
      </li>
      <li className="list-item">
        <CustomLink className="list-link text-reset" to="activecourses">
          Active courses
        </CustomLink>
      </li>
      <li className="list-item">
        <CustomLink className="list-link text-reset" to="coursehistory">
          Course history
        </CustomLink>
      </li>
    </ul>
  </div>
  );
}
 
export default StudentSidebar;