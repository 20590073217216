import { CacheProvider } from "@emotion/react";
import Error from "../../components/Error";
import { customTheme, getQuery, muiCache, paginationOptions } from "../../assets/js/datatables";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import { ADMIN_EXTRA_MODULES, aapi } from "../../assets/js/shared";
import { moduleTypeFO } from "../../assets/js/filterOptions";

const AdminExtraModules = () => {

    const columns = [
        {
            name: "uri",
            label: "URI",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "extraModuleName",
            label: "Module name",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (extraModuleName, tableMeta) => {
                    const url = tableMeta.rowData[0];

                    if(url && url.startsWith("http")){
                        return (
                            <a href={url} target="_blank" rel="noreferrer"> {extraModuleName} <i className="fe fe-external-link"></i></a>
                        )
                    } else {
                        return (
                            <span> {extraModuleName} </span>
                        )
                    }
                }
            }
        },
        {
            name: "extraModuleDescription",
            label: "Description",
            options: {
                filter: true,
                sort: false
            }
        },
        {
            name: "moduleTypeName",
            label: "Module type",
            options: {
                filter: true,
                filterType: "dropdown",
                filterOptions: { names: moduleTypeFO },
                sort: true
            }
        },
        {
            name: "extraModuleId",
            label: "Actions",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (extraModuleId) => {
                    return (
                        <div className="btn-group">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-edit">Edit</Tooltip>
                                }
                            >
                                <button className="btn text-info" onClick={() => handleShowEditModal(extraModuleId)}>
                                    <i className="fe fe-edit"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const [moduleTypes, setModuleTypes] = useState(null);

    const getData = useCallback(async (page, query) => {
        aapi.get(`/getuniextramodules/${page}${query}`)
        .then(res => {
            setData(res.data.data);
            setTableDetails(res.data.paginationDetails);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        sessionStorage.removeItem(ADMIN_EXTRA_MODULES);
        getData(1, "");
    }, [getData]);

    const getModuleTypes = useCallback(async () => {
        aapi.get("/getmoduletypes")
        .then(res => {
            setModuleTypes(res.data);
        })
        .catch(err => {
            setError(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, [])

    useEffect(() => {
        getModuleTypes();
    }, [getModuleTypes]);

    // POST add new extra module
    const [showAddModal, setShowAddModal] = useState(false);
    const [addModalError, setAddModalError] = useState(null);
    const [sendNewData, setSendNewData] = useState(null);

    const handleShowAddModal = () => {
        setShowAddModal(true);
    }

    const handlePost = e => {
        e.preventDefault();

        aapi.post("/pushextramoduledata", sendNewData, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            getData(tableDetails.currentPage, getQuery(ADMIN_EXTRA_MODULES));
            closeAddModal();
        })
        .catch(err => {
            setAddModalError(err);
        })
    }

    const closeAddModal = () => {
        setSendNewData(null);
        setAddModalError(null);
        setShowAddModal(false);
    }

    // PUT edit extra module
    const [showEditModal, setShowEditModal] = useState(false);
    const [editModalError, setEditModalError] = useState(null);
    const [sendEditData, setSendEditData] = useState(null);
    const [currentName, setCurrentName] = useState(null);

    const handleShowEditModal = moduleId => {
        aapi.get(`/getbasicextramoduledata/${moduleId}`)
        .then(res => {
            setSendEditData(res.data);
            setCurrentName(res.data.extraModuleName);
        })
        .catch(err => {
            setEditModalError(err);
        })
        setShowEditModal(true);
    }

    const handlePut = e => {
        e.preventDefault();

        aapi.put(`/editextramoduledata`, sendEditData, {
            transformRequest: [function (data, headers) {
                headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');
                
                return JSON.stringify(data);
            }]
        })
        .then(res => {
            getData(tableDetails.currentPage, getQuery(ADMIN_EXTRA_MODULES));
            closeEditModal();
        })
        .catch(err => {
            setEditModalError(err);
        })
    }

    const closeEditModal = () => {
        setSendEditData(null);
        setEditModalError(null);
        setShowEditModal(false);
        setCurrentName(null);
    }

    return (
        <div className="card card-bleed shadow-light-lg mb-6">
            <div className="card-header">
                <h4 className="accent">Extra modules</h4>
            </div>
            <div className="card-body">
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                <div className="row mt-5">
                    <div className="col-12 col-md-auto">
                        <button className="btn w-100 btn-primary" onClick={() => handleShowAddModal()}>Add extra module</button>
                    </div>
                </div>
                <hr className="break"></hr>
                <div className="row">
                    <div className="col">
                        {
                            data &&
                            <CacheProvider value={muiCache}>
                                <ThemeProvider theme={customTheme}>
                                    <MUIDataTable
                                        data={data}
                                        columns={columns}
                                        options={paginationOptions(tableDetails, getData, setLoading, ADMIN_EXTRA_MODULES)}
                                    />
                                </ThemeProvider>
                            </CacheProvider>
                        }
                    </div>
                </div>

                <Modal
                    size="lg"
                    show={showAddModal}
                    onHide={closeAddModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >   
                    <Modal.Header closeButton>
                        <Modal.Title>Add new extra module</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {
                            addModalError &&
                            <Error text={addModalError}></Error>
                        }
                        <form onSubmit={handlePost}>
                            <div className="row mb-2">
                                <div className="col-12">
                                    <p className="mt-0">* - required field</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Module name*</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            required
                                            onChange={e => setSendNewData({...sendNewData, extraModuleName: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Description</label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            onChange={e => setSendNewData({...sendNewData, extraModuleDescription: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>URL*</label>
                                        <input
                                            className="form-control"
                                            type="url"
                                            required
                                            placeholder="https://example.com"
                                            onChange={e => setSendNewData({...sendNewData, uri: e.target.value})}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Module type*</label>
                                        <select
                                            className="form-select"
                                            defaultValue=""
                                            required
                                            onChange={e => setSendNewData({...sendNewData, moduleTypeId: e.target.value})}
                                        >
                                            <option key="0" value="" selected disabled hidden>Choose type</option>
                                            {
                                                moduleTypes &&
                                                moduleTypes.map((item) => {
                                                    return (
                                                        <option key={item.moduleTypeId} value={item.moduleTypeId}>{item.moduleTypeName}</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12 col-md-auto d-flex">
                                    <button className="btn w-100 btn-primary" type="submit">Save</button>
                                </div>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>

                <Modal
                    size="lg"
                    show={showEditModal}
                    onHide={closeEditModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        {
                            currentName &&
                            <Modal.Title>Edit joint programme - <span className="accent">{currentName}</span></Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                        {
                            editModalError &&
                            <Error text={editModalError}></Error>
                        }
                        {
                            sendEditData &&
                            <form onSubmit={handlePut}>
                                <div className="row mb-2">
                                    <div className="col-12">
                                        <p className="mt-0">* - required field</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Module name*</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                required
                                                defaultValue={sendEditData.extraModuleName}
                                                onChange={e => setSendEditData({...sendEditData, extraModuleName: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Description</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                defaultValue={sendEditData.extraModuleDescription}
                                                onChange={e => setSendEditData({...sendEditData, extraModuleDescription: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>URL*</label>
                                            <input
                                                className="form-control"
                                                type="url"
                                                required
                                                defaultValue={sendEditData.uri}
                                                placeholder="https://example.com"
                                                onChange={e => setSendEditData({...sendEditData, uri: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label>Module type*</label>
                                            <select
                                                className="form-select"
                                                required
                                                defaultValue={sendEditData.moduleTypeId}
                                                onChange={e => setSendEditData({...sendEditData, moduleTypeId: e.target.value})}
                                            >
                                                <option key="0" value="" selected disabled hidden>Choose type</option>
                                                {
                                                    moduleTypes &&
                                                    moduleTypes.map((item) => {
                                                        return (
                                                            <option key={item.moduleTypeId} value={item.moduleTypeId}>{item.moduleTypeName}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 col-md-auto d-flex">
                                        <button className="btn w-100 btn-primary" type="submit">Save</button>
                                    </div>
                                </div>
                            </form>
                        }
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default AdminExtraModules;