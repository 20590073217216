const MatchPercentageView = ({data}) => {

    return (
        <>
            {
                data < 25.0 &&
                <span className="badge badge-lg rounded-pill bg-secondary">{data} %</span>
            }
            {
                data >= 25.0 && data < 50.0 &&
                <span className="badge badge-lg rounded-pill bg-primary">{data} %</span>
            }
            {
                data >= 50.0 && data < 65.0 &&
                <span className="badge badge-lg rounded-pill bg-danger">{data} %</span>
            }
            {
                data >= 65.0 && data < 85.0 &&
                <span className="badge badge-lg rounded-pill bg-warning">{data} %</span>
            }
            {
                data >= 85.0 &&
                <span className="badge badge-lg rounded-pill bg-success">{data} %</span>
            }
        </>
    )
}

export default MatchPercentageView;