import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { muiCache, customTheme, paginationOptions, getQuery } from "../../assets/js/datatables";
import { useState, useEffect, useCallback } from "react";
import { ENROL_COURSE_STUDENT, aapi, getDate } from "../../assets/js/shared";
import Error from "../../components/Error";
import CourseStatusLegend from "../../components/CourseStatusLegend";

const EnrolCourses = () => {

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	// columns for course table
	const columns = [
		{
			name: "courseName",
			label: "Name",
			options: {
				filter: true,
				sort: true,
				customBodyRender: (name, tableMeta) => {
					const url = tableMeta.rowData[7];

					if (url) {
						return (
							<a href={url} target="_blank" rel="noreferrer"> {name} <i className="fe fe-external-link"></i></a>
						)
					} else {
						return (
							<span> {name} </span>
						)
					}
				}
			}
		},
		{
			name: "ectsCredits",
			label: "ECTS Credits",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "academicYear",
			label: "Academic Year",
			options: {
				filter: false,
				sort: false,
				display: "excluded",
			}
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (startDate) => {
					return (
						<span> {getDate(startDate)} </span>
					)
				},
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setStartDate("");
						getData(tableDetails.currentPage, getQuery(ENROL_COURSE_STUDENT));
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">Start date</label>
								<input
									type="date"
									className="date-filter-input"
									value={startDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setStartDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
			}
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				filter: true,
				filterType: "custom",
				sort: true,
				customBodyRender: (startDate) => {
					return (
						<span> {getDate(startDate)} </span>
					)
				},
				customFilterListOptions: {
					update: (filterList, filterPos, index) => {
						setEndDate("");
						getData(tableDetails.currentPage, getQuery(ENROL_COURSE_STUDENT));
						filterList[index].splice(filterPos, 1);
						return filterList;
					}
				},
				filterOptions: {
					display: (filterList, onChange, index, column) => {
						return (
							<>
								<label className="date-filter-label">End date</label>
								<input
									type="date"
									className="date-filter-input"
									value={endDate}
									required
									onChange={e => {
										filterList[index] = [e.target.value];
										onChange(filterList[index], index, column);
										setEndDate(e.target.value);
									}}
								/>
							</>
						);
					}
				}
			}
		},
		{
			name: "lectures",
			label: "Lecture hours",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "exercises",
			label: "Exercise hours",
			options: {
				filter: true,
				sort: true
			}
		},
		{
			name: "uri",
			label: "URI",
			options: {
				filter: false,
				sort: true,
				display: "excluded",
			}
		},
		{
			name: "prerequisites",
			label: "Prerequisites",
			options: {
				filter: true,
				sort: false
			}
		},
		{
			name: "teachers",
			label: "Teachers",
			options: {
				filter: false,
				sort: false,
				display: false,
				// customBodyRender: (teachers) => {
				// 	return (
				// 		teachers.map(teacher => {
				// 			if (teacher.titleAfterName) {
				// 				return (
				// 					<>{teacher.titleBeforeName} {teacher.personalNames} {teacher.familyNames} {teacher.titleAfterName}; </>
				// 				)
				// 			} else {
				// 				return (
				// 					<>{teacher.titleBeforeName} {teacher.personalNames} {teacher.familyNames}; </>
				// 				)
				// 			}
				// 		})
				// 	)
				// }
			}
		},
		{
			name: "courseInstanceId",
			label: "Mode of attendance",
			options: {
				filter: false,
				sort: false,
				customBodyRender: (courseInstanceId) => {
					let formName = "studentEnroll_" + courseInstanceId;

					return (
						<form id={formName} onSubmit={handleApply}>
							<select
								className="form-select form-select-xs ps-1"
								name="virtualOrPhysical"
								required
								defaultValue=""
							>
								<option value="" selected disabled hidden>Choose</option>
								<option value="V">Virtual</option>
								<option value="P">Physical</option>
							</select>
							<input name="courseInstanceId" hidden value={courseInstanceId} readOnly></input>
						</form>
					);
				}
			}
		},
		{
			name: "courseInstanceId",
			label: "Action",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (courseInstanceId) => {
					let formName = "studentEnroll_" + courseInstanceId;

					return (
						<button form={formName} className="btn btn-outline-success" type="submit">Apply</button>
					)
				}
			}
		},
		{
			name: "orgUnitName",
			label: "Organization name",
			options: {
				filter: true,
				sort: true,
			}
		},
		{
			name: "superOrgUnitName",
			label: "University name",
			options: {
				filter: true,
				sort: true,
			}
		}
	];

	const [data, setData] = useState(null);
	const [tableDetails, setTableDetails] = useState(null);

	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);

	// get initial data
	const getData = useCallback(async (page, query) => {
		await aapi.get(`/getcourseinstancesforstudentenrollment/${page}${query}`)
			.then(res => {
				setData(res.data.data);
				setTableDetails(res.data.paginationDetails);
			})
			.catch(err => {
				setError(err);
			})
			.finally(() => {
				setIsLoading(false);
			})
	}, []);

	useEffect(() => {
		sessionStorage.removeItem(ENROL_COURSE_STUDENT);
		getData(1, "");
	}, [getData])

	// apply for course enrolment (POST)
	const handleApply = async (e) => {
		e.preventDefault();

		aapi.post('/enrollcourse', {
			courseInstanceId: e.target.querySelector("input[name='courseInstanceId']").value,
			virtualOrPhysical: e.target.querySelector("select[name='virtualOrPhysical']").value
		},
			{
				transformRequest: [function (data, headers) {
					headers['X-CSRF-TOKEN'] = document.cookie.replace(/(?:(?:^|.*;\s*)XSRF-TOKEN\s*=\s*([^;]*).*$)|^.*$/, '$1');

					return JSON.stringify(data);
				}]
			}
		)
			.then(_res => {
				getData(tableDetails.currentPage, getQuery(ENROL_COURSE_STUDENT));
			})
			.catch(err => {
				setError(err);
			});
	}

	return (
		<div className="card card-bleed shadow-light-lg mb-6">
			<div className="card-header">
				<h4 className="accent"> Courses available for enrolment </h4>
			</div>
			<div className="card-body">
				<div className="row">
					<div className="col">
						<CacheProvider value={muiCache}>
							<ThemeProvider theme={customTheme}>
								{
									isLoading &&
									<div className="spinner-border text-primary mb-1" role="status">
										<span className="visually-hidden">Loading...</span>
									</div>
								}
								<CourseStatusLegend />
								{
									error &&
									<Error text={error}></Error>
								}
								{
									data &&
									<MUIDataTable
										data={data}
										columns={columns}
										options={paginationOptions(tableDetails, getData, setIsLoading, ENROL_COURSE_STUDENT)}
									/>
								}

							</ThemeProvider>
						</CacheProvider>
					</div>
				</div>
			</div>
		</div>
	)
}

export default EnrolCourses;