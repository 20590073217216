const ResearcherHeader = props => {
    return (
        <header className="bg-research pt-7 pb-7 d-none d-md-block">
            <div className="container-fluid">
                <div className="row align-items-center" id="main">
                    <div className="col">
                        <h1 className="fw-bold text-white mb-2">Virtual Campus - Researcher</h1>
                    </div>
                    <div className="col-auto">
                        <div className="user text-white">
                            <div className="user__name fw-bold">
                                <i className="fe fe-user me-1"></i>
                                <span className="text-uppercase">{props.userName}</span>
                            </div>
                            <div className="user__email">
                                {props.userOrgUnit}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default ResearcherHeader;