import { Link } from "react-router-dom";

const Home = () => {
	return (
		<div
			className="pt-8 pb-11 pt-md-9 pb-md-12 py-lg-14 bg-gray-200 bg-between"
		>
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-10 col-lg-7 text-center" data-aos="fade-up">

						<h1 className="display-2 fw-bold">
							Virtual Campus
						</h1>

						<p className="fs-lg text-muted mb-6 mb-md-8">
							This portal gives an overview of all courses, joint programmes and extra modules that are available through YUFE universities’ study programs.
							Besides that, it gives access to meta-repository containing research outputs which are produced within YUFE alliance.<br/><br/>
							For more information about YUFE alliance visit <a href="https://yufe.eu/" target="_blank" rel="noreferrer">yufe.eu</a>.<br/>
						</p>

						<Link to="/public/courses" className="btn btn-primary-soft me-1 mb-1 lift">
							Courses <span className="fe fe-book ms-1"></span>
						</Link>
						<Link to="/public/jointprogrammes" className="btn btn-primary-soft me-1 mb-1 lift">
							Joint programmes <span className="fe fe-minimize-2 ms-1"></span>
						</Link>
						<Link to="/public/extramodules" className="btn btn-primary-soft me-1 mb-1 lift">
							Extra modules <span className="fe fe-file-plus ms-1"></span>
						</Link>
						<Link to="/public/researchoutputs" className="btn btn-primary-soft me-1 mb-1 lift">
							Research outputs <span className="fe fe-archive ms-1"></span>
						</Link>
					</div>

				</div>
			</div>
		</div>
	);
}

export default Home;
