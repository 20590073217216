import { useCallback, useEffect, useState } from "react";
import Error from "../../../components/Error";
import { CacheProvider } from "@emotion/react";
import { customTheme, muiCache, paginationOptions } from "../../../assets/js/datatables";
import { ThemeProvider } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { INCOMING_MOBILITY_INFO, INCOMING_MOBILITY_PENDING, INCOMING_MOBILITY_STUDENTS, aapi, getDate } from "../../../assets/js/shared";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AdminIncomingPendingTable = ({ counter, pending }) => {

    const columns = [
        {
            name: "courseInstanceId",
            label: "Course instance ID",
            options: {
                filter: false,
                sort: false,
                display: "excluded"
            }
        },
        {
            name: "localCourseId",
            label: "Local course ID",
            options: {
                filter: true,
                sort: true,
            }
        },
        {
            name: "courseName",
            label: "Course",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "academicYear",
            label: "Academic year",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (academicYear) => {
                    return <>{academicYear}/{academicYear + 1}</>
                }
            }
        },
        {
            name: "startDate",
            label: "Start date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (startDate) => {
                    return (getDate(startDate))
                }
            }
        },
        {
            name: "endDate",
            label: "End date",
            options: {
                filter: true,
                sort: true,
                customBodyRender: (endDate) => {
                    return (getDate(endDate))
                }
            }
        },
        {
            name: "ectsCredits",
            label: "ECTS",
            options: {
                filter: true,
                sort: true
            }
        },
        {
            name: "studentsEnrolledCourses",
            label: "Students",
            options: {
                filter: false,
                sort: false,
                customBodyRender: (students, tableMeta) => {
                    return (
                        <div className="btn-group">
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id="tooltip-info">Inspect</Tooltip>
                                }
                            >
                                <button
                                    className="btn"
                                    aria-label="More details"
                                    onClick={() => viewStudentCourses(tableMeta)}
                                >
                                    <i className="fe fe-eye"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    )
                }
            }
        }
    ];

    const [data, setData] = useState(null);
    const [tableDetails, setTableDetails] = useState(null);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    // get initial data and separate it by status (pending = 2, approved = 3-6)
    const getData = useCallback((page, query) => {
        let param = pending ? "Y" : "N";
        aapi.get(`/incomingmobilitypending/${param}/${page}${query}`)
            .then(res => {
                setData(res.data.data);
                setTableDetails(res.data.paginationDetails);
            })
            .catch(err => {
                setError(err);
            })
            .finally(() => {
                setLoading(false);
            })
    }, [pending])

    useEffect(() => {
        sessionStorage.removeItem(pending ? INCOMING_MOBILITY_PENDING : INCOMING_MOBILITY_INFO);
        getData(1, "");
        if (pending) {
            counter();
        }
    }, [getData, pending, counter]);

    const navigate = useNavigate();

    const viewStudentCourses = (tableMeta) => {
        let object = {
            courseInstanceId: tableMeta.rowData[0],
            course: tableMeta.rowData[2],
            source: pending ? "Incoming pending" : "Incoming info",
            pending: pending ? true : false,
            info: pending ? false : true,
            history: false
        }
        sessionStorage.setItem(INCOMING_MOBILITY_STUDENTS, JSON.stringify(object));
        navigate("coursestudents");
    }

    return (
        <div className="row">
            <div className="col">
                {
                    loading &&
                    <div className="spinner-border text-primary mb-1" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                }
                {
                    error &&
                    <Error text={error}></Error>
                }
                {
                    data &&
                    <CacheProvider value={muiCache}>
                        <ThemeProvider theme={customTheme}>
                            <MUIDataTable
                                data={data}
                                columns={columns}
                                options={paginationOptions(tableDetails, getData, setLoading, pending ? INCOMING_MOBILITY_PENDING : INCOMING_MOBILITY_INFO)}
                            />
                        </ThemeProvider>
                    </CacheProvider>
                }
            </div>
        </div>
    )
}

export default AdminIncomingPendingTable;